import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import {AppConfigService} from "../../../../services/app.config.service";
import {AppAsyncTimeout} from "../../../../helpers/app.asyncTimeout";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'view-field-selector',
  templateUrl: 'shared.view-field-selector.component.html',
  styleUrls: ['shared.view-field-selector.component.scss']
})
export class SharedViewFieldSelectorComponent implements OnInit {
  public static known_fields = [
    'aktionen_button',
    'logo1',
    'logo2',
    'bezeichnung',
    'minablauf',
    'darreichungsform',
    'hersteller',
    'artikelnummer',
    'pzn',
    'einkaufspreis',
    'verkaufspreis',
    'mwst',
    'rezeptzeichen',
    'Zulassungsnummer',
    'istarzneimittel',
    'status',
  ];

  public static fields_order_table = [
    'aktionen_button',
    'logo1',
    'logo2',
    'bezeichnung',
    'minablauf',
    'darreichungsform',
    'hersteller',
    'artikelnummer',
    'pzn',
    'einkaufspreis',
    'verkaufspreis',
    'mwst',
    'Zulassungsnummer',
    'rezeptzeichen',
    'istarzneimittel',
    'status',
  ];

  public static fields_order_box = [
    'logo1',
    'logo2',
    'bezeichnung',
    'minablauf',
    'darreichungsform',
    'hersteller',
    'artikelnummer',
    'pzn',
    'einkaufspreis',
    'verkaufspreis',
    'mwst',
    'Zulassungsnummer',
    'rezeptzeichen',
    'istarzneimittel',
    'status',
    'aktionen_button'
  ];

  my_fields_order = SharedViewFieldSelectorComponent.fields_order_table;

  @Input() hidden_fields: string[];

  disallowedFields = [];

  @Input() setDefaultsIfEmptyTable = false;
  @Input() setDefaultsIfEmptyBox = false;


  @Input() disableFilterOnPermissionsAndFeatures: boolean = false;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() fields: string[];
  @Output() fieldsChange = new EventEmitter<string[]>();

  @Output() change = new EventEmitter();

  constructor(private cd: ChangeDetectorRef, private cfg: AppConfigService) {
  }

  private detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnInit(): void {
    if (!this.fields || this.fields.length == 0) {
      if (this.setDefaultsIfEmptyBox) {
        this.fields = [
          "logo1",
          "logo2",
          "bezeichnung",
          "minablauf",
          "darreichungsform",
          "hersteller",
          "pzn",
          "verkaufspreis",
          "einkaufspreis",
          "rezeptzeichen",
          "istarzneimittel",
          "status",
          "aktionen_button"
        ];
        AppAsyncTimeout.setTimeout(() => {
          this._change();
        }, 50);

      } else if (this.setDefaultsIfEmptyTable) {
        this.fields = [
          "aktionen_button",
          "logo1",
          "bezeichnung",
          "minablauf",
          "darreichungsform",
          "hersteller",
          "pzn",
          "verkaufspreis",
          "einkaufspreis",
          "rezeptzeichen",
          "istarzneimittel",
          "status"
        ];
        AppAsyncTimeout.setTimeout(() => {
          this._change();
        }, 50);
      }
    }

    if (this.hidden_fields && this.fields) {
      let needs_update = false;
      this.hidden_fields.forEach(f => {
        if (this.fields.includes(f)) {
          this.fields.splice(this.fields.indexOf(f), 1);
          needs_update = true;
        }
      })

      if (needs_update) {
        this._change();
      }
    }

    let needs_update = false;
    if (!this.cfg.featuresEnabledAll(['ArtikelinfosBestand', 'ArtikelinfosMinAblauf']) && !this.disableFilterOnPermissionsAndFeatures) {
      if (this.fields && this.fields.includes('minablauf')) {
        this.fields.splice(this.fields.indexOf('minablauf'), 1);
        needs_update = true;
      }
      this.disallowedFields.push('minablauf');

      if (needs_update) {
        this._change();
      }
    }
  }

  _change() {
    this.detectChanges();
    this.fieldsChange.emit(this.fields);
    this.change.emit();
  }
}
