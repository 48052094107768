import {Injectable} from "@angular/core";
import {SharedSectionTitleComponent} from "../modules/shared/components/section-title/shared.section-title.component";
import {Subject, Subscription} from "rxjs";

@Injectable({providedIn: "root"})
export class AppService {
  sectionTitle: SharedSectionTitleComponent;

  sectionTitleChange = new Subject();

  registerSectionTitleComponent(sectionTitle) {
    this.sectionTitle = sectionTitle
    this.sectionTitleChange.next(this.sectionTitle);
  }
}
