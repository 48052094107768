import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../models/user';
import {AppUserService} from '../../services/app.user.service';
import {AppLieferkundeService} from '../../services/app.lieferkunde.service';
import {Lieferkunde} from '../../models/lieferkunde';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {AppDialogsService} from '../../services/app.dialogs.service';
import {Router} from '@angular/router';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {AppMobileService} from '../../services/app.mobile.service';
import {DomainConfig} from '../../models/domain-config';
import {AppConfigService} from '../../services/app.config.service';
import {takeUntil} from "rxjs/operators";

declare let location: any;

@Component({
  selector: 'app-user-menu',
  templateUrl: 'user-menu.component.html',
  styleUrls: ['user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {
  static id = 'UserMenuComponent';

  usub: Subscription;
  lksub: Subscription;
  difflksub: Subscription;

  domaincfg: DomainConfig;

  user: User;
  lieferkunde: Lieferkunde;
  differential_lieferkunde: Lieferkunde;
  private current_section: string;

  isMobile = new BehaviorSubject<boolean>(false);

  _onDestroy = new Subject();

  constructor(private usvc: AppUserService, private lksvc: AppLieferkundeService, private router: Router,
              private dialog: AppDialogsService, private loader: AppPageloaderService, private msvc: AppMobileService,
              private cfg: AppConfigService) {
    this.domaincfg = cfg.domainconfig;
  }

  restart() {
    this.dialog.confirm('neustart', 'Neustarten?', 'Wollen Sie die Applikation neustarten?', () => {
      this.loader.startFullscreen('Wird neu gestartet...');
      setTimeout(() => {
        this.router.navigateByUrl('/');
        setTimeout(() => {
          location.reload();
        }, 500);
      }, 1000);
    });
  }

  public isAdmin() {
    return this.current_section == 'admin';
  }

  public isDefault() {
    return this.current_section != 'admin';
  }

  depersonate() {
    this.usvc.depersonate();
  }

  ngOnInit() {
    this.usvc.user$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(u => this.user = u);
    this.lksvc.current_lieferkunde$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(lk => {
      this.lieferkunde = lk;
    });

    this.lksvc.current_differential_lieferkunde$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(lk => {
      this.differential_lieferkunde = lk;
    });

    this.msvc.isMobile().subscribe(m => this.isMobile.next(m));
  }

  resetDiffLk() {
    this.dialog.confirm('frage', 'Im Auftrag von.. verlassen?',
      'Verlassen?',
      () => {
        this.lksvc.resetDifferentialLieferkunde().subscribe(s => {
          this.router.navigateByUrl('/');
        });
    });
  }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
}

