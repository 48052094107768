import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Artikel} from "../../../../models/artikel";
import {AppCustomerSpecialPriceService} from "../../../../services/app.customer-special-price.service";
import {BehaviorSubject} from "rxjs";
import {CustomerSpecialPriceResponse} from "../../../../models/customer-special-price-response";
import {CustomerSpecialPrice} from "../../../../models/customer-special-price";
import {AppDialogsService} from "../../../../services/app.dialogs.service";
import {AppMessageService} from "../../../../services/app.message.service";
import * as moment from "moment";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'shop.dialogs.customer-special-price-list.component.html',
  styleUrls: ['shop.dialogs.customer-special-price-list.component.scss']
})
export class ShopDialogsCustomerSpecialPriceListComponent implements OnInit {
  data = new BehaviorSubject<CustomerSpecialPriceResponse>(null);

  history = false;

  newEntry: CustomerSpecialPrice;
  newEntryPrice: number;
  newHasGueltigBis: boolean;

  hasChange = false;

  constructor(public dialogRef: MatDialogRef<ShopDialogsCustomerSpecialPriceListComponent>,
              @Inject(MAT_DIALOG_DATA) public article: Artikel, private svc: AppCustomerSpecialPriceService,
              private dsvc: AppDialogsService, private msg: AppMessageService) {

  }

  updateGueltigBisIfNeeded() {
    if (this.newHasGueltigBis) {
      this.newEntry.GueltigBis = moment(this.newEntry.GueltigVon);
    }
  }

  startNew() {
    this.newHasGueltigBis = false;
    this.newEntryPrice = this.article.Kundeneinkaufspreis;
    this.newEntry = new CustomerSpecialPrice();
    this.newEntry.GueltigVon = moment();

  }

  close() {
    this.dialogRef.close(this.hasChange);
  }

  cancelNew() {
    this.newEntry = undefined;
  }

  saveNew() {
    this.hasChange = true;
    let newEntry = JSON.parse(JSON.stringify(this.newEntry));

    newEntry[this.data.value.VerrechnungsPreiscodeKunde] = this.newEntryPrice;
    newEntry.GueltigVon = moment(newEntry.GueltigVon).format('DD.MM.YYYY');
    if (!this.newHasGueltigBis) {
      newEntry.GueltigBis = undefined;
    } else {
      newEntry.GueltigBis = moment(newEntry.GueltigBis).format('DD.MM.YYYY');
    }
    this.svc.saveSpecialPriceForArticle(this.article, newEntry).subscribe({
      next: resp => {
        if (resp.SaveSonderpreisKundeStatus == 'nichtGespeichert') {
          this.msg.error("Dieser Sonderpreis kann nicht gespeichert werden! Auf Überschneidungen prüfen!", 3000);

        } else if (resp.SaveSonderpreisKundeStatus == 'gespeichertAltEnde') {
          this.msg.info("Gespeichert! Vorheriger Sonderpreis wird beendet!", 3000);
          this.cancelNew();
          this.load();

        } else if (resp.SaveSonderpreisKundeStatus == 'gespeichert') {
          this.msg.info("Gespeichert!");
          this.cancelNew();
          this.load();

        } else {
          this.msg.error("Unbekannter Status!");
        }

      }, error: e => {
        this.msg.error('Unbekannter Fehler!');
      }
    })
  }

  ngOnInit(): void {
    this.load();
  }

  delete(sp: CustomerSpecialPrice) {
    this.dsvc.confirm('frage', 'Sonderpreis löschen', 'Wirklich löschen?', () => {
      this.hasChange = true;
      this.svc.deleteSpecialPriceForArticle(this.article, sp.SonderpreisKundeId).subscribe(sp => {
        this.msg.info('Gelöscht!');
        this.load();
      })
    })
  }

  load() {
    this.data.next(null);
    this.svc.getSpecialPriceForArticle(this.article, this.history).subscribe(sp => {
      this.data.next(sp);
    })
  }
}
