import {Component} from '@angular/core';
import {AppPageloaderService} from '../../services/app.pageloader.service';

@Component({
  selector: 'app-loader',
  templateUrl: 'app.layouts.loader.component.html',
  styleUrls: ['app.layouts.loader.component.scss']
})
export class AppLayoutsLoaderComponent {
  static id = 'AppLayoutsLoaderComponent';

  constructor(public loader: AppPageloaderService) {
  }
}

