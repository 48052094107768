import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {KundeBonuspunkt} from '../models/kunde-bonuspunkt';
import {Bonuspunktprogramm} from '../models/bonuspunktprogramm';
import {map} from 'rxjs/operators';
import {BonuspunktprogrammStufe} from '../models/bonuspunktprogramm-stufe';

@Injectable({providedIn: 'root'})
export class AppBonusPunkteService {
  constructor(private http: HttpClient) {
  }

  getProgramme(): Observable<Bonuspunktprogramm[]> {
    return this.http.get<Bonuspunktprogramm[]>(
      '/bonuspunkte/programme'
    );
  }

  getMeine(): Observable<KundeBonuspunkt[]> {
    return this.http.get<KundeBonuspunkt[]>(
      '/bonuspunkte/meine'
    ).pipe(map(ps => {
      ps.forEach(p => {
        Object.setPrototypeOf(p, KundeBonuspunkt.prototype);

        if (p.Bonuspunkteprogramm) {
          Object.setPrototypeOf(p.Bonuspunkteprogramm, Bonuspunktprogramm.prototype);

          if (p.Bonuspunkteprogramm.BonuspunktprogrammStufen) {
            p.Bonuspunkteprogramm.BonuspunktprogrammStufen.forEach(s => {
              Object.setPrototypeOf(s, BonuspunktprogrammStufe.prototype);
            });
          }
        }
      });
      return ps;
    }));
  }
}
