import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {AppMobileappService} from './app.mobileapp.service';

@Injectable({providedIn: 'root'})
export class AppTutorialService {

  constructor(private http: HttpClient, private cfg: AppConfigService, private asvc: AppMobileappService) {
  }

  load(): Observable<string[]> {
    return this.http.post<string[]>(
      this.cfg.buildUrl('/tutorials'),
      {
        isApp: this.asvc.isApp()
      }
    );
  }

  setSeen(id: string): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/tutorials/' + id + '/seen'),
      {
        isApp: this.asvc.isApp()
      }
    );
  }

  resetAll(): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/tutorials/reset'),
      {
        isApp: this.asvc.isApp()
      }
    );
  }
}
