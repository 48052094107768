import {DomSanitizer} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';
import {AppConfigService} from '../../../services/app.config.service';

@Pipe({name: 'RemoveLeadingZero', pure: false})
export class SharedPipeRemoveLeadingZeroPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private cfg: AppConfigService) {
  }

  transform(value: any): any {
    if (value) {
      return value.replace(/^0+/, '');
    } else {
      return value;
    }
  }


}
