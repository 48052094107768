import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppUpdateService} from "../../services/app.update.service";

@Component({
  selector: 'update-icon',
  templateUrl: 'app.update-icon.component.html',
  styleUrls: ['app.update-icon.component.scss']
})
export class AppUpdateIconComponent implements OnInit, OnDestroy {

  updateReady = new BehaviorSubject(false);

  _destroy = new Subject();

  constructor(private svc: AppUpdateService) {
  }

  install() {
    this.svc.installUpdate();
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  ngOnInit(): void {
    this.svc.updateAvailable.pipe(takeUntil(this._destroy)).subscribe(s => {
      this.updateReady.next(s);
    });
  }
}
