import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy, OnInit, Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {SharedSvgIconService} from '../../services/shared.svg-icon.service';
import {Subscription} from 'rxjs';
import {AppAsyncTimeout} from "../../../../helpers/app.asyncTimeout";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'svg-icon',
  templateUrl: 'shared.svg-icon.component.html',
  styleUrls: ['shared.svg-icon.component.scss']
})
export class SharedSvgIconComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked, OnChanges {
  init = false;
  icon_content: string;

  icon_name_search: string;

  error: string;

  @Input() color = 'currentColor';
  @Input() size = 24;
  @Input() verticalAlign = 'middle';

  @ViewChild('iconnamecontainer') iconnamecontainer: ElementRef;

  isub: Subscription;

  constructor(private ref: ElementRef, private cd: ChangeDetectorRef, private svc: SharedSvgIconService) {

  }

  ngOnInit(): void {
    this.ref.nativeElement.style.height = this.size+'px';
    this.ref.nativeElement.style.width = this.size+'px';
    this.ref.nativeElement.style["vertical-align"] = this.verticalAlign;
    this.ref.nativeElement.classList.add('svg-icon');
    this.detectChanges();
  }

  private detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._init();
  }

  ngOnDestroy(): void {
    if (this.isub instanceof Subscription) {
      this.isub.unsubscribe();
    }
  }

  private _init() {
    this.init = true;


    if (this.iconnamecontainer && this.iconnamecontainer.nativeElement) {
      let name = this.iconnamecontainer.nativeElement.innerText;
      name = name.trim();

      this.icon_name_search = name;

      this.ref.nativeElement.classList.add(this.icon_name_search);
    }

    if (this.icon_name_search !== undefined) {
      this.isub = this.svc.iconlist.subscribe(list => {
        if (list.length > 0) {
          if (list.includes(this.icon_name_search)) {
            this.svc.getIcon(this.icon_name_search, this.color).subscribe(ct => {
              this.icon_content = ct;
              this.error = undefined;
              this.detectChanges();
            });
          } else {
            this.svc.registerNotFound(this.icon_name_search);
            this.error = this.icon_name_search;
            this.detectChanges();
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    AppAsyncTimeout.setTimeout(() => {
      this._init();
    }, 20);
  }

  ngAfterContentChecked(): void {
    if (this.iconnamecontainer && this.iconnamecontainer.nativeElement) {
      let name = this.iconnamecontainer.nativeElement.innerText;
      name = name.trim();
      if (this.icon_name_search != name) {
        this._init();
      }
    }
  }
}
