import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {NewsEntry} from '../../../../models/news_entry';
import {AppNewsService} from '../../../../services/app.news.service';
import {AppEventService} from '../../../../services/app.event.service';
import {NewsEntryTag} from '../../../../models/news-entry-tag';
import {PagedResult} from '../../../../models/pagedresult';
import {AppKeyValueStorageService} from "../../../../services/app.key-value-storage.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dashboard-widgets-news',
  templateUrl: 'dashboard.widgets.news.component.html',
  styleUrls: ['dashboard.widgets.news.component.scss'],
})
export class DashboardWidgetsNewsComponent implements OnInit, OnDestroy {
  static id = 'DashboardWidgetsNewsComponent';
  static cols = 4;
  static rows = 15;

  static label = 'News';
  static description = 'Zeigt die aktuellen News an.';

  loading1 = new BehaviorSubject<boolean>(false);
  loading2 =  new BehaviorSubject<boolean>(false);

  limit = undefined;
  limits: number[] = [5, 10, 15, 20, 25, 30, 50];

  data = new BehaviorSubject<PagedResult<NewsEntry>>(null);
  tag_colors: {} = {};

  tags= new BehaviorSubject([]);

  selected_tags: {} = {};

  base_tag_colors = [
    '#5a5a27',
    '#227171',
    '#FF4500',
    '#800000',
    '#D2691E',
    '#D2B48C',
  ];

  esub: Subscription;

  loadTimeout: any;

  constructor(private svc: AppNewsService, private esvc: AppEventService,
              private kvstorage: AppKeyValueStorageService) {
  }

  getTags() {
    const tags = [];

    this.tags.value.forEach(tag => {
      if (this.selected_tags[tag.id]) {
        tags.push(tag.id);
      }
    });

    return tags;
  }

  toggleTag(tag: NewsEntryTag) {
    if (this.selected_tags[tag.id] == true) {
      this.selected_tags[tag.id] = false;

    } else {
      this.selected_tags[tag.id] = true;
    }

    this.load();
  }

  changeLimit() {
    if (this.limit) {
      this.kvstorage.save4User('dashboard_widget_news_limit', this.limit).subscribe();
      this.load();
    }
  }

  load() {
    if (this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }
    this.loadTimeout = setTimeout(() => {
      if (this.limit) {
        this.loading1.next(true);
        this.svc.get(this.limit, this.getTags()).subscribe(d => {
          this.data.next(d);
          this.loading1.next(false);
        });
      }
    }, 50);
  }

  ngOnInit(): void {
    this.kvstorage.load4User('dashboard_widget_news_limit').subscribe(limit => {
      if (limit) {
        this.limit = parseInt(limit, 10);
      } else {
        this.limit = 5;
      }
      this.load();
    });

    this.esub = this.esvc.getQueue().subscribe(e => {
      if (
        e.name == 'App\\Events\\NewsEngine\\NewNewsEntryEvent' ||
        e.name == 'App\\Events\\NewsEngine\\NewsEntryUpdatedEvent'
      ) {
        this.init();
      }
    });

    this.init();
  }

  init() {
    this.loading2.next(true);
    this.svc.getTags().subscribe(t => {
      this.tags.next(t);
      this.selected_tags = {};
      this.tag_colors = {};
      this.tags.value.forEach((t2, i) => {
        this.selected_tags[t2.id] = false;
        this.tag_colors[t2.id] = this.base_tag_colors[i];
      });
      this.loading2.next(false);
      this.load();
    });
  }

  ngOnDestroy(): void {
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }
  }
}
