import {Injectable} from "@angular/core";
import {GS1Reader} from 'gs1js';
import {GS1DataMatrix} from "../models/datamatrix/gs1";
import {IFADataMatrix} from '../models/datamatrix/ifa';

@Injectable({providedIn: "root"})
export class BarcodeDecoderService {

  decodeGS1(string: string) {
    const r = new GS1Reader(string);

    const gs = new GS1DataMatrix();

    const result = r.getApplicationIdentifiers();
    result.forEach(i => {
      gs[i.identifier] = i.value;
    });

    gs.render();

    return gs;
  }

  decodeIFA(string: string) {
    return new IFADataMatrix(string);
  }

  decode(string: string) {
    if (string.match(/\x5B\x29\x3E.*/)) {
      return this.decodeIFA(string);
    } else {
      return this.decodeGS1(string);
    }

  }
}
