import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConditionsService} from '../../services/app.conditions.service';
import {Condition} from '../../modules/admin/model/condition';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {AppGuardsCondition} from '../../guards/app.guards.condition';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'customer-condition-checker',
  templateUrl: 'app.customer-condition-checker.component.html',
  styleUrls: ['app.customer-condition-checker.component.scss']
})
export class AppCustomerConditionCheckerComponent implements OnInit, OnDestroy {
  condition: Condition;

  ocsub: Subscription;

  constructor(private svc: AppConditionsService, private loader: AppPageloaderService, private guard: AppGuardsCondition,
              private router: Router) {
  }

  ngOnInit(): void {
    this.ocsub = this.svc.open_conditions.subscribe(cs => {
      if (cs) {
        if (cs.length > 0) {
          this.condition = cs[0];
        } else {
          const url = this.guard.url_before_redirect;
          if (url != '') {
            this.router.navigateByUrl(url);
          } else {
            this.router.navigateByUrl('/');
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.ocsub instanceof Subscription) {
      this.ocsub.unsubscribe();
    }
  }

  load() {
    this.svc.load();
  }


  accept() {
    const l = this.loader.start();
    this.svc.acceptConditions(this.condition).subscribe(s => {
      this.load();
      l.stop();
    });
  }

  decline() {
    const l = this.loader.start();
    this.svc.declineConditions(this.condition).subscribe(s => {
      this.load();
      l.stop();
    });

  }


}
