import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AppLogService} from './app.log.service';
import {PageloaderObject} from '../models/pageloader-object';

@Injectable({providedIn: 'root'})
export class AppPageloaderService {
  loading_percentage = new BehaviorSubject<number>(null);
  loading_percentage$ = this.loading_percentage.asObservable();

  loading = new BehaviorSubject<boolean>(false);
  loading$ = this.loading.asObservable();

  has_text = new BehaviorSubject<boolean>(false);
  has_text$ = this.has_text.asObservable();

  loading_text = new BehaviorSubject<string>('');
  loading_text$ = this.loading_text.asObservable();

  loading_title = new BehaviorSubject<string>('');
  loading_title$ = this.loading_title.asObservable();

  all_done = new Subject<boolean>();

  has_manual_fullscreen = false;

  states = [];
  state_titles = {};
  state_texts = {};

  start_stop_timeout: any;

  constructor(private logsvc: AppLogService) {
    this.loading$.subscribe(s => {
      let title = '';
      if (s) {
        for (const name of this.states) {
          if (this.state_titles[name]) {
            if (title != '') {
              title += ', ';
            }
            title += this.state_titles[name];
          }
        }
      }

      let txt = '';
      if (s) {
        for (const name of this.states) {
          if (this.state_texts[name]) {
            if (txt != '') {
              txt += ', ';
            }
            txt += this.state_texts[name];
          }
        }
      }

      if (!this.has_manual_fullscreen) {
        if (txt != '' || title != '') {
          this.loading_text.next(txt);
          this.loading_title.next(title);
          this.has_text.next(true);
        } else {
          this.has_text.next(false);
        }
      }
    });
  }

  startPercentage(percentage) {
    this.loading_percentage.next(percentage);
  }

  stopPercentage() {
    this.loading_percentage.next(null);
  }

  isLoading(): Observable<boolean> {
    return this.loading$;
  }

  start(name: string = '', title: string = '', text: string = '') {
    if (name === '') {
      name = this.makeRandomID();
    }

    this.logdebug('START ' + name);

    if (this.start_stop_timeout) {
      clearTimeout(this.start_stop_timeout);
    }
    if (!this.states.includes(name)) {
      this.states.push(name);
    }
    this.start_stop_timeout = setTimeout(() => {
      this.execStartStopTimeout();
    }, 200);
    if (title != '') {
      this.state_titles[name] = title;
    }
    if (text != '') {
      this.state_texts[name] = text;
    }

    return new PageloaderObject(this, name);
  }

  startFullscreen(title: string, text: string = '') {
    this.has_text.next(true);
    this.loading_title.next(title);
    this.loading_text.next(text);
    this.has_manual_fullscreen = true;
  }

  stopFullscreen() {
    this.has_text.next(false);
    this.has_manual_fullscreen = false;
  }

  stopAll() {
    this.states = [];
    this.loading.next(false);
    this.state_texts = {};
    this.state_titles = {};
    this.all_done.next(true);
  }

  execStartStopTimeout() {
    if (this.states.length == 0) {
      this.loading.next(false);
      this.all_done.next(true);
    } else {
      this.loading.next(true);
    }
  }

  stop(name: string) {
    this.logdebug('STOP ' + name);

    this.states = this.states.filter(label => label !== name);

    if (this.start_stop_timeout) {
      clearTimeout(this.start_stop_timeout);
    }
    this.start_stop_timeout = setTimeout(() => {
      this.execStartStopTimeout();
    }, 200);


    if (this.state_texts[name]) {
      this.state_texts[name] = undefined;
    }
  }

  private log(message, ...optionalParams: any[]) {
    this.logsvc.consoleLog(this, message, ...optionalParams);
  }

  private logdebug(message, ...optionalParams: any[]) {
    this.logsvc.consoleDebug(this, message, ...optionalParams);
  }

  private makeRandomID() {
    const lengthOfCode = 40;
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;\'[]\=-)(*&^%$#@!~`';
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
