<span matTooltip="{{not_available_de_no_pzn?'Verfügbarkeitsanfrage nicht verfügbar':''}}">
  <button (click)="checkVerfuerbarkeit()" *ngIf="(vfchecknotloading$ | async)" (contextmenu)="$event.preventDefault()"
          [disabled]="disabled || not_available_de_no_pzn || (entry.freimenge == 0 && entry.menge == 0 && entry.aktionsrabattmenge == 0)"
          mat-button matTooltip="Verfügbarkeitsabfrage">
    <svg-icon
      color="{{(disabled || not_available_de_no_pzn || (entry.freimenge == 0 && entry.menge == 0 && entry.aktionsrabattmenge == 0)) ? 'grey' : '#00acd6'}}">
      verfuegbarkeitsabfrage
    </svg-icon>
  </button>


  <button *ngIf="(vfcheckloading$ | async)" [disabled]="true" mat-button style="top: 10px; left: 10px; width: 64px;">
    <div style="display: inline-block; position: relative; left: -10px; top: -5px">
      <mat-spinner [diameter]="24"></mat-spinner>
    </div>
  </button>
</span>
