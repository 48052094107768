import {Artikel} from './artikel';

export class ViewResult {
  data: Artikel[];
  page: number;
  page_size: number;
  search: string;
  count: number;
  total_count: any;

  getPages(): number {
    return Math.ceil(this.total_count / this.page_size);
  }
}
