import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {Observable} from 'rxjs';
import {Setting} from '../models/setting';

@Injectable({providedIn: 'root'})
export class AppSettingsService {
  constructor(private http: HttpClient, private cfg: AppConfigService) {
  }

  getDomainSettings(domain_id: number): Observable<Setting[]> {
    return this.http.get<Setting[]>(
      this.cfg.buildUrl('/settings/domain/' + domain_id.toString())
    );
  }

  getCurrentDomainSettingsValue(id: string): Observable<Setting[]> {
    return this.http.get<Setting[]>(
      this.cfg.buildUrl('/settings/domain/current?id='+id)
    );
  }

  clickDomainSetting(id: string): Observable<any> {
    return this.http.get<any>(
      this.cfg.buildUrl('/settings/domain/click?id=' + id)
    );
  }

  testDomainSettings(domain_id: number, id, value): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/settings/domain/' + domain_id.toString() + '/test'),
      {
        id: id,
        value: value
      }
    );
  }

  setDomainSettings(domain_id: number, settings: Setting[]): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/settings/domain/' + domain_id.toString()),
      {
        settings: settings
      }
    );
  }

  getSystemSettings(): Observable<Setting[]> {
    return this.http.get<Setting[]>(
      this.cfg.buildUrl('/settings/system')
    );
  }

  testSystemSettings(id, value): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/settings/system/test'),
      {
        id: id,
        value: value
      }
    );
  }

  setSystemSettings(settings: Setting[]): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/settings/system'),
      {
        settings: settings
      }
    );
  }

  getUserSettings(): Observable<Setting[]> {
    return this.http.get<Setting[]>(
      this.cfg.buildUrl('/settings/user')
    );
  }

  getUserSettingValue(id: string): Observable<any> {
    return this.http.get<any>(
      this.cfg.buildUrl('/settings/user?id=' + id)
    );
  }

  setUserSettingValue(id: string, value): Observable<any> {
    return this.http.post<any>(
      this.cfg.buildUrl('/settings/user?id=' + id),
      {
        value: value
      }
    );
  }

  clearUserSettingValue(id: string): Observable<any> {
    return this.http.post<any>(
      this.cfg.buildUrl('/settings/user?id=' + id),
      {
        value: '__DELETE__'
      }
    );
  }

  clickUserSetting(id: string): Observable<any> {
    return this.http.get<any>(
      this.cfg.buildUrl('/settings/user/click?id=' + id)
    );
  }

  testUserSettings(id, value): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/settings/user/test'),
      {
        id: id,
        value: value
      }
    );
  }

  setUserSettings(settings: Setting[]): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/settings/user'),
      {
        settings: settings
      }
    );
  }

  getCustomerSettings(): Observable<Setting[]> {
    return this.http.get<Setting[]>(
      this.cfg.buildUrl('/settings/customer')
    );
  }

  getCustomerSettingValue(id: string): Observable<any> {
    return this.http.get<any>(
      this.cfg.buildUrl('/settings/customer?id=' + id)
    );
  }

  clickCustomerSetting(id: string): Observable<any> {
    return this.http.get<any>(
      this.cfg.buildUrl('/settings/customer/click?id=' + id)
    );
  }

  testCustomerSettings(id, value): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/settings/customer/test'),
      {
        id: id,
        value: value
      }
    );
  }

  setCustomerSettings(settings: Setting[]): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl('/settings/customer'),
      {
        settings: settings
      }
    );
  }
}
