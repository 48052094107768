import {Component, ElementRef, Input, OnDestroy, OnInit, ViewRef} from "@angular/core";
import {AppUserService} from "../../../../services/app.user.service";
import {Subscription} from "rxjs";
import {User} from "../../../../models/user";

@Component({
  selector: '[hasPermission]',
  template: '<ng-content></ng-content>'
})
export class SharedPermissionTableElementComponent implements OnInit, OnDestroy {
  @Input() oneOfRoles: string[] = [];
  @Input() allOfRoles: string[] = [];
  @Input() notOneOfRoles: string[] = [];

  usub: Subscription;

  constructor(private elref: ElementRef, private usvc: AppUserService) {
  }

  ngOnDestroy(): void {
    if (this.usub instanceof Subscription) {
      this.usub.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.oneOfRoles.length > 0 || this.notOneOfRoles.length > 0 || this.allOfRoles.length > 0) {
      this.usub = this.usvc.user$.subscribe(u => {
        let state = false;
        if (this.oneOfRoles.length == 0 && this.allOfRoles.length == 0) {
          state = true;
        }

        let oneOfRolesMatch = false;
        let hasOneOfRoles = false;
        let allOfRolesMatch = false;
        let hasAllOfRolesMatch = false;
        let notOneOfRolesMatch = false;
        let hasNotOneOfRolesMatch = false;

        if (u instanceof User) {
          if (this.oneOfRoles && this.oneOfRoles.length > 0) {
            hasOneOfRoles = true;
            oneOfRolesMatch = u.hasRoles(this.oneOfRoles);
          }

          if (this.allOfRoles && this.allOfRoles.length > 0) {
            hasAllOfRolesMatch = true;
            allOfRolesMatch = u.hasAllRoles(this.allOfRoles);
          }

          if (this.notOneOfRoles && this.notOneOfRoles.length > 0) {
            hasNotOneOfRolesMatch = true;
            notOneOfRolesMatch = u.hasRoles(this.notOneOfRoles);

          }

          if (oneOfRolesMatch) {
            state = true;
          }
          if (state && hasOneOfRoles && hasAllOfRolesMatch && !allOfRolesMatch) {
            state = false;
          } else if (allOfRolesMatch) {
            state = true;
          }

          if (state && hasNotOneOfRolesMatch && notOneOfRolesMatch) {
            state = false;
          }
        }

        if (!state) {
          this.elref.nativeElement.remove();
        }
      });
    } else {
      this.elref.nativeElement.remove();
    }
  }
}
