import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUserService} from '../../services/app.user.service';
import {AppMessageService} from '../../services/app.message.service';
import {AppDialogsService} from '../../services/app.dialogs.service';
import {AppConfigService} from '../../services/app.config.service';
import {AppPageTitleService} from '../../services/app.page-title.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DomainConfig} from '../../models/domain-config';
import {UntypedFormBuilder} from '@angular/forms';

@Component({
  // moduleId: module.id,
  selector: 'app-validate-ip',
  templateUrl: 'app.validate-ip.component.html',
  styleUrls: ['app.validate-ip.component.scss']
})
export class AppValidateIpComponent implements OnInit, OnDestroy {
  static id = 'AppValidateIpComponent';
  ip: string|boolean;
  token: string;

  loading = new BehaviorSubject<boolean>(false);


  domaincfg: DomainConfig;

  rsub: Subscription;

  send = false;

  error = new BehaviorSubject<string>(null);
  message = new BehaviorSubject<string>(null);

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private usvc: AppUserService,
              private msg: AppMessageService, private dialog: AppDialogsService, private cfg: AppConfigService,
              private tsvc: AppPageTitleService, private loader: AppPageloaderService) {
    this.domaincfg = cfg.domainconfig;
  }

  validate() {
    this.send = true;
    const l = this.loader.start();

    this.usvc.validateIP(this.token).subscribe({
      next: (s) => {
        if (s) {
          this.message.next('Erfolgreich freigeschalten! Bitte melden Sie sich nun an!');
          this.error.next(null);
        } else {
          this.error.next('Fehlgeschlagen!');
          this.message.next(null);
        }

        this.send = false;

        l.stop();
      }, error: () => {
        l.stop();
        this.send = false;
      }
    });
  }

  load() {
    const l = this.loader.start();
    this.usvc.getIPvalidateByToken(this.token).subscribe({
      next: (ip) => {
        if (ip) {
          this.ip = ip;
        } else {
          this.error.next('Nicht gefunden!');
        }
        l.stop();
      }, error: (e) => {
        this.error.next('Nicht gefunden!');
        l.stop();
      }
    });
  }

  ngOnDestroy(): void {
    this.tsvc.resetTitle();
  }

  ngOnInit(): void {
    this.tsvc.setTitle('Neue IP freischalten');

    this.rsub = this.route.params.subscribe(p => {
      if (p['token']) {
        this.token = p['token'];
        this.load();
      }
    });
  }


}
