import {Injectable} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AppMobileService {
  constructor(private observer: BreakpointObserver) {
  }

  isMobile(): Observable<boolean> {
    return this.observer.observe([
      Breakpoints.TabletPortrait,
      Breakpoints.Handset
    ]).pipe(map(result => {
      return result.matches;
    }));
  }

  isMobile2(): Observable<boolean> {
    return this.observer.observe([
      Breakpoints.TabletPortrait,
      Breakpoints.Handset
    ]).pipe(map(result => {
      return result.matches;
    }));
  }
}

