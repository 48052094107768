import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {FileBrowserEntry} from "../../../../models/file-browser-entry";
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {AppDialogsService} from "../../../../services/app.dialogs.service";
import {FileBrowserUploadData} from "../../../../models/file-browser-upload-data";
import {Subject} from "rxjs";

@Component({
  selector: 'file-browser',
  templateUrl: 'shared.file-browser.component.html',
  styleUrls: ['shared.file-browser.component.scss']
})
export class SharedFileBrowserComponent implements OnInit, OnChanges {
  @Input() entries: FileBrowserEntry[];
  @Input() ask_on_select = false;

  @Input() focus_on_id: number;
  @Output() new = new EventEmitter<FileBrowserEntry>();
  @Output() edit = new EventEmitter<FileBrowserEntry>();
  @Output() delete = new EventEmitter<FileBrowserEntry>();
  @Output() select = new EventEmitter<FileBrowserEntry>();
  @Output() upload = new EventEmitter<FileBrowserUploadData>();
  @Output() download = new EventEmitter<FileBrowserEntry>();

  prettyFileIcons: any;
  treeControl = new NestedTreeControl<FileBrowserEntry>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FileBrowserEntry>();

  activeEntry: FileBrowserEntry;

  expanded_ids = {};

  constructor(private dsvc: AppDialogsService) {
  }

  hasChild = (_: number, node: FileBrowserEntry) => !!node.children && node.children.length > 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entries) {
      this.dataSource.data = this.entries;
      this.workEntriesOnChange(this.dataSource.data);
    }
  }

  handleFileInput(files: FileList) {
    let fdata = new FileBrowserUploadData();
    fdata.parent = Object.assign({}, this.activeEntry );
    fdata.parent.parent = undefined;
    fdata.parent.children = undefined;

    let i = 0;
    while(i < files.length) {
      const f = files.item(i);
      fdata.files.push(f);
      i++;
    }

    this.upload.next(fdata);
  }

  start_download() {
    this.download.emit(this.activeEntry);
  }

  do_select() {
    this.select.emit(this.activeEntry);
  }

  private workEntriesOnChange(entries) {
    for (const entry of entries) {
      if (this.expanded_ids[entry.id] && this.expanded_ids[entry.id] == true) {
        this.treeControl.expand(entry);
      }
      if (this.focus_on_id && this.focus_on_id == entry.id) {
        this.activeEntry = entry;
      }

      if (entry.children) {
        this.workEntriesOnChange(entry.children);
      }
    }
  }

  setActiveEntry(entry: FileBrowserEntry) {
    this.activeEntry = entry;
    this.expanded_ids[entry.id] = this.treeControl.isExpanded(entry);
  }

  ngOnInit(): void {
    this.prettyFileIcons = require('pretty-file-icons');
    this.dataSource.data = this.entries;
  }

  newFolder() {
    this.dsvc.form()
      .setTitle('Neuer Ordner')
      .addField('label', 'Name', false, null, null, 'ok')
      .addButton('ok', 'OK')
      .addButton('cancel', 'Abbrechen')
      .show((r) => {
        if (r.clicked_id == 'ok') {
          let label = r.data.label;

          let e = new FileBrowserEntry();
          e.type == 'dir';
          e.parent = this.activeEntry;
          e.label = label;

          this.new.emit(e);
        }
      });
  }

  editFolder() {
    this.dsvc.form()
      .setTitle('Ordner umbenennen')
      .addField('label', 'Name', false, null, this.activeEntry.label, 'ok')
      .addButton('ok', 'OK')
      .addButton('cancel', 'Abbrechen')
      .show((r) => {
        if (r.clicked_id == 'ok') {
          this.activeEntry.label = r.data.label;

          this.edit.emit(this.activeEntry);
        }
      });
  }

  @HostListener('window:keydown.delete', ['$event'])
  deleteElement() {
    if (this.activeEntry) {
      if (this.activeEntry.type == 'dir') {
        this.deleteFolder();
      } else {
        this.deleteFile();
      }
    }
  }

  deleteFolder() {
    if (this.activeEntry) {
      this.dsvc.confirm('frage', 'Ordner löschen?', 'Es wird auch der gesamte Inhalt gelöscht. Sicher?', () => {
        this.delete.emit(this.activeEntry);
        this.activeEntry = undefined;
      });
    }
  }

  deleteFile() {
    this.dsvc.confirm('frage', 'Datei löschen?', 'Sicher?', () => {
      this.delete.emit(this.activeEntry);
      this.activeEntry = undefined;
    });
  }


}
