import {Component, OnInit} from '@angular/core';
import {Notification} from '../../models/notification';
import {User} from '../../models/user';
import {AppNotificationComponent} from "./app.notification.component";

@Component({
  templateUrl: 'app.notification.list.component.html',
  styleUrls: ['app.notification.list.component.scss']
})
export class AppNotificationListComponent extends AppNotificationComponent implements OnInit {
  static id = 'AppNotificationListComponent';
  user: User;

  ngOnInit(): void {
    this.userService.user$.subscribe((user: User) => {
      this.user = user;
    });

    this.fetch();
  }

  fetch() {
    this.loader.start('NotificationlistComponent_1');
    this.notificationService.getAll().subscribe(n => {
      this.notifications.next(n);
      this.loader.stop('NotificationlistComponent_1');
    });

  }


  isCurrentUser(testuser: User) {
    let state = false;

    if (testuser.id && this.user instanceof User && testuser.id == this.user.id) {
      state = true;
    }

    return state;
  }


  setSeen(event: Event, notification: Notification): void {
    event.stopImmediatePropagation();
    this.notificationService.setSeen(notification.id).subscribe(state => {
      this.fetch();
    });
  }

  navigateSeen(notification: Notification, url = '') {
    this.notificationService.setSeen(notification.id).subscribe(state => {
      if (url != '') {
        this.notificationService.addNotificationData(url, notification);

        this.router.navigateByUrl(url);
      } else {
        this.fetch();
      }
    });
  }
}
