import {Injectable} from '@angular/core';

declare let isAnApp: any;

@Injectable({providedIn: 'root'})
export class AppMobileappService {

  isApp(): boolean {
    if (typeof isAnApp != 'undefined') {
      return (isAnApp === true);
    } else {
      return false;
    }
  }
}
