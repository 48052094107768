import {DomSanitizer} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';
import {AppConfigService} from '../../../services/app.config.service';

@Pipe({name: 'FixUmlaute'})
export class SharedPipeFixumlautePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private cfg: AppConfigService) {
  }

  transform(value: any): any {
    value = value.replace(/ae/g, 'ä');
    value = value.replace(/oe/g, 'ö');
    value = value.replace(/ue/g, 'ü');
    value = value.replace(/AE/g, 'Ä');
    value = value.replace(/OE/g, 'Ö');
    value = value.replace(/UE/g, 'Ü');
    return value;
  }
}
