<div class="menuboxparentbox tut_mainmenu">
  <section-title icon="menue" label="Hauptmenü" [buttons_right]="section_title_buttons_right"></section-title>

  <div class="menuboxouter">
    <div class="menubox">
      <div class="menuboxinner" *ngFor="let b of buttons|async" [class.mobile]="isMobile|async">
        <menu-button
          [name]="b.id"
          [config]="b"
          [class]="b.class_fkt ? b.class_fkt(cfg) : b.class"
        ></menu-button>
      </div>
    </div>
  </div>
</div>
