import {Component} from '@angular/core';
import {AppEventService} from '../../../../services/app.event.service';
import {AppMerklisteService} from '../../../../services/app.merkliste.service';

@Component({
  selector: 'app-dashboard-widgets-merklisten',
  templateUrl: 'dashboard.widgets.merklisten.component.html',
  styleUrls: ['dashboard.widgets.merklisten.component.scss'],
})
export class DashboardWidgetsMerklistenComponent {
  static id = 'DashboardWidgetsMerklistenComponent';
  static cols = 3;
  static rows = 5;
  static label = 'Ihre Merklisten';
  static description = 'Zeigt eine Liste ihrer Merklisten an.';

  constructor(public svc: AppMerklisteService, private esvc: AppEventService) {
  }

}
