import {Component, Input} from '@angular/core';
import {Artikel} from '../../../../models/artikel';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Warenkorb} from '../../../../models/warenkorb';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {DomainVertreterConfig} from '../../../../models/domain-vertreter-config';
import {AppShopService} from '../../../../services/app.shop.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ShopWarenkorbMenuComponent} from '../warenkorb-menu/shop.warenkorb-menu.component';
import {AppUserService} from '../../../../services/app.user.service';
import {MerklistEntry} from "../../../../models/merklist-entry";

@Component({
  selector: 'warenkorb-button',
  templateUrl: 'shop.warenkorb-button.component.html',
  styleUrls: ['shop.warenkorb-button.component.scss']
})
export class ShopWarenkorbButtonComponent {
  @Input() disabled = false;
  @Input() artikel: Artikel;
  @Input() view_slug: string;
  @Input() entry: WarenkorbEntry | MerklistEntry;
  @Input() vertreterconfig: BehaviorSubject<DomainVertreterConfig>;

  esub: Subscription;

  wksub: Subscription;
  add_mode = false;

  newwk: Warenkorb;

  constructor(public wksvc: AppWarenkorbService, public ssvc: AppShopService, private bottomsheet: MatBottomSheet,
              private usvc: AppUserService) {
  }

  addToPrimaryWarenkorb() {
    if (this.usvc.user.value.hasRoles(['Vertreter'])) {
      this.ssvc.addToWarenkorbDialog(this.entry);
    } else {
      if (this.entry.menge && this.entry.menge > 0) {
        this.ssvc.addToPrimaryWarenkorb(this.entry);
      }
    }
  }

  showMenu(event, as_vertreter = false) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.bottomsheet.open(ShopWarenkorbMenuComponent, {
      data: {
        artikel: this.artikel,
        entry: this.entry,
      }
    });
  }


}
