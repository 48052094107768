<div class="widgetbox mat-elevation-z1" [class.mobile]="(msvc.isMobile()|async)">
  <div class="toolbar">
    <table>
      <tr>
        <td class="col1" style="white-space: nowrap">
          <div style="display: inline-block; vertical-align: middle">
            <ng-content select="[icon]"></ng-content>
          </div>
          <div style="display: inline-block; padding-left: 5px; overflow: hidden; vertical-align: middle">
            <ng-content select="[header1]"></ng-content>
          </div>
        </td>
        <td class="col2">
          <div style="display: inline-block">
            <ng-content select="[header2]"></ng-content>
          </div>
        </td>
        <td  class="col3">
          <div style="display: inline-block">
            <ng-content select="[header3]"></ng-content>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="widgetcontent" [class.with_footer]="footer_enabled">
    <ngx-simplebar style="height: 100%; width: 100%">
      <ng-content select="[content]"></ng-content>
    </ngx-simplebar>
  </div>
  <div class="widgetfooter" *ngIf="footer_enabled">
    <div class="footer-content">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>
