import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AppScannerService} from "../../../../services/app.scanner.service";
import {BehaviorSubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AppAsyncTimeout} from "../../../../helpers/app.asyncTimeout";

@Component({
  selector: 'search-box',
  templateUrl: 'shared.search-box.component.html',
  styleUrls: ['shared.search-box.component.scss']
})
export class SharedSearchBoxComponent implements OnInit, OnChanges, OnDestroy {
  name = 'searchbox';
  @Output() search = new EventEmitter();

  @Input() value = '';
  @Output() valueChange = new EventEmitter();

  @Input() disabled = false;

  @Input() buttonClass = '';
  @Input() formFieldClass = '';

  @Input() reset_triggers_search = true;

  @Input() infoText = null;
  @Input() infoTextInNewline = false;

  @Input() scannerEnabled = false;

  hasScan = new BehaviorSubject(false);

  has_search = false;

  lastSearch = '';

  searchReady = false;

  @ViewChild('searchinput') searchinput: ElementRef;

  _onDestroy = new Subject();

  constructor(private dialog: MatDialog, private scansvc: AppScannerService) {
  }

  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  startScan() {
    this.scansvc.startScan((ret) => {
      if (ret) {
        if (ret.GTIN) {
          this.value = ret.GTIN;

        } else {
          this.value = ret;
        }
        this.change();
        this.do_search();
      }
    });
  }

  _searchReady() {
    this.searchReady = (this.value != this.lastSearch);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      if (changes.value.currentValue != '') {
        this.has_search = true;
      } else {
        this.has_search = false;
      }
    }
  }

  ngOnInit(): void {
    this.scansvc.hasCam.pipe(takeUntil(this._onDestroy)).subscribe(st => {
      this.hasScan.next(st);
    })
    if (this.value != "") {
      this.has_search = true;
    }
  }

  reset() {
    this.value = "";
    this.change();
    this.searchReady = true;

    if (this.reset_triggers_search) {
      this.do_search();
      this._searchReady();
    }

    if (this.searchinput && this.searchinput.nativeElement) {
      this.searchinput.nativeElement.focus();
    }
  }

  keyDown(event) {
    if (event.key == 'Enter' && event.ctrlKey && !event.shiftKey && !event.altKey) {
      this.do_search(true);
    }
  }

  do_search(force = false) {
    if (this.searchReady || force) {
      this.has_search = true;
      this.search.emit();
      this.lastSearch = this.value;
      AppAsyncTimeout.setTimeout(() => {
        this._searchReady();
      }, 100);
    }
  }

  change() {
    this.valueChange.emit(this.value);
    this._searchReady();
  }

}
