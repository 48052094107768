<dashboard-widget>
  <div icon>
    <svg-icon>warenkorb</svg-icon>
  </div>
  <div header1 matTooltip="Warenkörbe ({{(svc.warenkoerbe$|async)?.length}})">
    <a routerLink="/shop/warenkorb">Warenkörbe ({{(svc.warenkoerbe$|async)?.length}})</a>
  </div>
  <div header2>

  </div>
  <div header3>

  </div>
  <div content>
    <div *ngFor="let wk of svc.warenkoerbe$|async">
      <button mat-button routerLink="/shop/warenkorb/{{wk.id}}">
        {{wk.label}} ({{wk.artikel_count}} Artikel)
      </button>
    </div>
  </div>
</dashboard-widget>
