import {Observable, throwError} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {AppMessageService} from '../services/app.message.service';
import {AppConfigService} from '../services/app.config.service';
import {Router} from '@angular/router';
import {AppLogService} from '../services/app.log.service';
import {catchError, map} from 'rxjs/operators';
import {AppUserService} from '../services/app.user.service';
import {AppPageloaderService} from '../services/app.pageloader.service';
import {AppEventService} from '../services/app.event.service';
import {AppAsyncTimeout} from "./app.asyncTimeout";

@Injectable()
export class AppHelpersHttprequestmanipulator implements HttpInterceptor {


  constructor(private msg: AppMessageService, private cfg: AppConfigService, private router: Router,
              private lsvc: AppLogService, private injector: Injector, private esvc: AppEventService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = request.url;

    if (url.match(/^\/.*$/)) {
      url = this.cfg.buildUrl(url);
    }

    const headers = {
      'X-WindowID': this.getWindowId(),
      'X-WindowPath': this.cfg.active_route,
      'X-WindowComponent': this.cfg.active_component,

    };

    if (url.match(/.*\/api\/.*/)) {
      headers['ngsw-bypass'] = 'true';
    }

    const httpheaders = new HttpHeaders(headers);

    request = request.clone({
      withCredentials: true,
      headers: httpheaders,
      url: url
    });


    this.lsvc.consoleDebug(this, 'Query ' + request.url);

    return this.handleRequest(request, next);
  }

  private handleRequest(request, next) {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),

      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error && err.error.silent == true) {
            return throwError(err);
          } else if (err.url && err.url.includes('verfuegbarkeit')) {
            return throwError(err);

          } else if (err.error && err.error.code && err.error.code == 10018) {
            this.esvc.addGuiEvent('api_offline');
            return throwError(err);

          } else if (err.error && err.error.code && err.error.code == 'passcode_required') {
            return throwError(err);
          } else if (err.error && err.error.code && err.error.code == 'token.notExists') {
            const usvc = this.injector.get(AppUserService);
            usvc.logout().subscribe();

          } else if (err.error && err.error.error != 'Invalid credentials.') {
            if (err.error.app == 'Access Denied.' || err.error == 'error_user_must_change_password') {
              return throwError(err);

            } else {
              if (err.status != 0) {
                if (err && err.url && err.url.match(/\/login/)) {
                } else if (err && err.url && err.url.match(/\/assets\/appicons/)) {
                } else if (
                  (err.status == 401 && err.error && err.error == 'Authentication Required') ||
                  (err.status == 500 && err.error.code == 10001)
                ) {
                  const router = this.injector.get(Router);
                  const usvc = this.injector.get(AppUserService);
                  const loader = this.injector.get(AppPageloaderService);
                  loader.startFullscreen('Session abgelaufen!', 'Sie werden abgemeldet!');
                  usvc.logout().subscribe(s => {
                    AppAsyncTimeout.setTimeout(() => {
                      localStorage.setItem('target_before_login_redirect', window.location.pathname);
                      router.navigateByUrl('/login');
                      loader.stopFullscreen();
                    }, 2000);
                  });

                } else {
                  if (err.error.message) {
                    this.msg.error(err.error.message);
                  }

                  let msg = '';
                  if (err.error.message) {
                    msg = err.error.message;

                  } else if (err.error.detail) {
                    msg = err.error.detail;
                  }

                  console.error('Backend Error: ', msg + ' in ' + err.error.file + ':' + err.error.line, ' - Trace:', err.error.trace, err);
                }
              }
            }
            return throwError(err);

          } else {
            return throwError(err);
          }
        } else {
          return throwError(err);
        }
      })
    );
  }

  private getWindowId() {
    if (typeof window !== 'undefined') {
      if (window.name == '') {
        const now = new Date();
        window.name = 'Window::' + Math.floor((Math.random() * 100) + 1) + ':' + now.getTime();
      }

      return window.name;

    } else {
      return 'dummy';
    }
  }
}
