<div *ngIf="warenkorb" [class.warenkorbbox]="!ajax_mode">

  <div class="paddedcontent">
    <div>
      <div class="boxleft">
        <mat-checkbox (change)="change()" [(ngModel)]="group_val" class="tut_warenkorb_group">Gruppieren</mat-checkbox>
        <mat-checkbox (change)="saveAutoImp()" [(ngModel)]="warenkorb.autosend_imp" class="tut_warenkorb_impsend"
                      style="margin-left: 10px" *ngIf="cfg.domainconfig.import_enabled">Importservice
          automatisch absenden
        </mat-checkbox>
      </div>
      <div class="boxright" *ngIf="!ajax_mode">
        <view-field-selector label="Anzuzeigende Felder" [(fields)]="active_fields" [hidden_fields]="[
          'verkaufspreis', 'einkaufspreis','status','hersteller']"
                             (change)="updateFields()"></view-field-selector>
      </div>
      <div style="clear: both"></div>
    </div>

    <ngx-simplebar #wkscroll [class.wkscroll]="!widget_mode && !ajax_mode" [class.wkscroll_ajax]="ajax_mode">
      <div>
        <div style="display: inline-block">
          <mat-form-field class="tut_warenkorb_note">
            <textarea (keydown)="note_edited = true" [(ngModel)]="warenkorb.note" matInput
                      placeholder="Notiz"></textarea>
            <mat-hint>Wird nicht übertragen!</mat-hint>
          </mat-form-field>
        </div>

        <permission-block [oneOfRoles]="['Vertreter']">
          <div *ngIf="(vsvc.$vertreterconfig|async)?.DomainVertreterShowReTage"
               style="display: inline-block; margin-left: 10px">
            <mat-form-field class="tut_warenkorb_reTage">
              <mat-label>Rechnungstage:</mat-label>
              <input matInput type="number" [(ngModel)]="warenkorb.reTage" step="1" min="0" (keydown)="reTageSelected()" (change)="reTageSelected()">
              <mat-hint>Leer lassen = Standard</mat-hint>
            </mat-form-field>
          </div>

          <div *ngIf="(vsvc.$vertreterconfig|async)?.DomainVertreterShowValutaDatum"
               style="display: inline-block; margin-left: 10px">
            <mat-form-field class="tut_warenkorb_note">
              <mat-label>Valuta Datum:</mat-label>
              <input matInput [matDatepicker]="valutaPicker" [ngModel]="valutaDate" (dateChange)="valutaSelected($event)">
              <span matSuffix>
              <mat-datepicker-toggle [for]="valutaPicker"></mat-datepicker-toggle>
            </span>
              <mat-datepicker #valutaPicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div *ngIf="(vsvc.$vertreterconfig|async)?.DomainVertreterShowLiefernAbDatum"
               style="display: inline-block; margin-left: 10px">
            <mat-form-field class="tut_warenkorb_note">
              <mat-label>Liefern ab:</mat-label>
              <input matInput [matDatepicker]="liefernAbPicker" [ngModel]="liefernAbDate" (dateChange)="liefernAbSelected($event)">
              <span matSuffix>
              <mat-datepicker-toggle [for]="liefernAbPicker"></mat-datepicker-toggle>
            </span>
              <mat-datepicker #liefernAbPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </permission-block>

        <button (click)="saveWarenkorb()" *ngIf="valuta_edited || reTage_edited || note_edited || liefernAb_edited" mat-icon-button>
          <svg-icon>speichern</svg-icon>
        </button>
      </div>

      <div>
        <table *ngIf="!(group|async)" class="table table-hover2" [class.table-striped]="!ajax_mode">
          <tbody [parent]="this" [positionen]="positions$|async" [summe]="getSumme()" [warenkorb]="warenkorb"
                 [ajax_mode]="ajax_mode" [show_category]="!group_val"
                 (validChange)="validChange($event)" [active_fields]="active_fields"
                 warenkorb-table>

          </tbody>
        </table>

        <table *ngIf="group|async" class="table table-hover2" [class.table-striped]="!ajax_mode">
          <tbody *ngFor="let kat of kategorien|async" [parent]="this" [positionen]="kat.positionen"
                 [show_category]="!group_val" [title]="kat.label"
                 [warenkorb]="warenkorb" [summe]="getKatSumme(kat)" [ajax_mode]="ajax_mode"
                 (validChange)="validChange($event)" [active_fields]="active_fields"
                 warenkorb-table>

          </tbody>
          <tbody *ngIf="(kategorien|async)?.length == 0">
          <tr>
            <td>
              <div
                style="vertical-align: middle; width: 100%; background-color: white; text-align: center; padding-top: 40px">
                <svg-icon style="width: 100px; height: 100px; font-size: 100px">traurig</svg-icon>
                <br>
                <div style="height: 100px; font-size: 16px; font-weight: bold;">
                  Der Warenkorb ist leer.
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ngx-simplebar>
  </div>

  <div [class.sendbuttonbox]="!ajax_mode" style="text-align: center" [style.bottom]="send_position_from_bottom"
       [class.posfixed]="send_button_pos_fixed">
    <button [disabled]="(kategorien|async)?.length == 0 || !(valid|async)" class="sendbutton tut_warenkorb_send"
            mat-raised-button
            (click)="sendWk($event)">
      <svg-icon>senden</svg-icon>
      Warenkorb absenden
    </button>
  </div>
</div>

