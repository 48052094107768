import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'secondshumanreadable'})
export class SharedSecondshumanreadablePipe implements PipeTransform {
  transform(value: number, show_seconds_always = true, with_words = false): string {
    const mom1 = moment().startOf('day');
    const mom2 = moment().startOf('day')
      .seconds(value);

    const duration = moment.duration(mom2.diff(mom1));

    const w = duration.weeks();
    const d = duration.days();
    const h = duration.hours();
    const m = duration.minutes();
    const s = duration.seconds();

    let name_w = 'w';
    let name_d = 'd';
    let name_h = 'h';
    let name_m = 'm';
    let name_s = 's';

    if (with_words) {
      name_w = ' Wochen';
      name_d = ' Tage';
      name_h = ' Stunden';
      name_m = ' Minuten';
      name_s = ' Sekunden';
    }

    let str = '';

    if (w > 0) {
      if (str != '') {
        str += ' ';
      }
      str += w + name_w;
    }

    if (d > 0) {
      if (str != '') {
        str += ' ';
      }
      str += d + name_d;
    }

    if (h > 0) {
      if (str != '') {
        str += ' ';
      }
      str += h + name_h;
    }

    // tslint:disable-next-line:radix
    if (m > 0) {
      if (str != '') {
        str += ' ';
      }
      str += m + name_m;
    }

    if (show_seconds_always || (!show_seconds_always && s > 0)) {
      if (str != '') {
        str += ' ';
      }
      str += s + name_s;
    }

    return str;
  }
}
