<span style="white-space: nowrap" matTooltip="{{tooltip|async}}" [style.cursor]="(tooltip|async) ? 'help' : null">
  <ng-container [ngSwitch]="fieldname">
    <ng-container *ngSwitchCase="'logo1'">Produktbild</ng-container>
    <ng-container *ngSwitchCase="'logo2'">Produktbild(2)</ng-container>
    <ng-container *ngSwitchCase="'aktionen_button'">Aktionen</ng-container>
    <ng-container *ngSwitchCase="'view'">Kategorie</ng-container>
    <ng-container *ngSwitchCase="'minablauf'">Minimaler Ablauf</ng-container>
    <ng-container *ngSwitchDefault>{{label|async}}</ng-container>
  </ng-container>
  <ng-container *ngIf="mode == 'box'">:</ng-container>
</span>
