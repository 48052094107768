export class PrivacySettings {
  sentry = false;
  sentry_userid = false;
  sentry_performance = false;
  matomo_pageview = false;
  matomo_userid = false;

  getLabel(key) {
    switch (key) {
      case 'sentry':
        return 'Absturzmeldungen';
      case 'sentry_userid':
        return 'Absturzmeldungen personalisieren';
      case 'sentry_performance':
        return 'Performance Daten';
      case 'matomo_pageview':
        return 'Ihr Nutzungsverhalten (anonymisiert)';
      case 'matomo_userid':
        return 'Ihr Nutzungsverhalten personalisieren';
    }

    return 'Unbekannt';
  }

  getInfo(key) {
    switch (key) {
      case 'sentry':
        return 'Hierdurch erfahren wir, wenn bei Ihnen ein Fehler auftritt.';
      case 'sentry_userid':
        return 'Hierdurch erfahren wir, bei wem der Fehler aufgetreten ist und können aktiv für Sie darauf reagieren!';
      case 'sentry_performance':
        return 'Performance Daten um die Anwendung zu optimieren';
      case 'matomo_pageview':
        return 'Wir zeichnen die Nutzung div. Funktionen auf um die Anwendung künftig nach Ihren Bedürfnissen anzupassen!';
      case 'matomo_userid':
        return 'Wir personalisieren die Nutzungsdaten zusätzlich.';
    }

    return 'Unbekannt';
  }
}
