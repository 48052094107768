import {Injectable} from '@angular/core';
import {DashboardWidgetsNewsComponent} from '../widgets/news/dashboard.widgets.news.component';
import {DashboardWidgetsWarenkoerbeComponent} from '../widgets/warenkoerbe/dashboard.widgets.warenkoerbe.component';
import {DashboardWidgetsImpWarenkorbTickerComponent} from '../widgets/imp-warenkorb-ticker/dashboard.widgets.imp-warenkorb-ticker.component';
import {DashboardWidgetsMerklistenComponent} from '../widgets/merklisten/dashboard.widgets.merklisten.component';
import {AppConfigService} from '../../../services/app.config.service';
import {DashboardWidgetsShopViewsComponent} from "../widgets/shop-views/dashboard.widgets.shop-views.component";
import {DashboardWidgetsFavouriteMenuButtonsComponent} from "../widgets/favourite-menu-buttons/dashboard.widgets.favourite-menu-buttons.component";
import {AppUserService} from "../../../services/app.user.service";
import {User} from "@sentry/angular-ivy";
import {Subscription} from "rxjs";
import {DashboardWidgetsCurrentWarenkorbComponent} from "../widgets/current-warenkorb/dashboard.widgets.current-warenkorb.component";

@Injectable({providedIn: 'root'})
export class DashboardWidgetService {
  user: User;

  usub: Subscription;

  constructor(private cfg: AppConfigService, private usvc: AppUserService) {
    this.usub = this.usvc.user$.subscribe(u => this.user = u);
  }

  getAvailableWidgets() {
    const widgets = [];
    widgets.push(DashboardWidgetsNewsComponent);
    if (this.user.hasRoles(['Auftrag'])) {
      widgets.push(DashboardWidgetsWarenkoerbeComponent);
      widgets.push(DashboardWidgetsCurrentWarenkorbComponent);
      widgets.push(DashboardWidgetsMerklistenComponent);
      widgets.push(DashboardWidgetsShopViewsComponent);
    }
    if (this.cfg.domainconfig.import_enabled && this.user.hasRoles(['Auftrag'])) {
      widgets.push(DashboardWidgetsImpWarenkorbTickerComponent);
    }

    widgets.push(DashboardWidgetsFavouriteMenuButtonsComponent);

    return widgets;
  }
}
