<div *ngIf="updateReady|async" matTooltip="Update bereit! Update sollte installiert werden! Klicken um zu installieren"
     class="updaterButton mat-elevation-z20" (click)="install()">
  <table>
    <tr>
      <td rowspan="2">
        <mat-icon style="font-size: 50px; margin-right: 10px; height: 50px; width: 50px">system_update_alt</mat-icon>
      </td>
      <td>
        Update verfügbar!
      </td>
    </tr>
    <tr>
      <td>
        <small>Klicken um zu installieren</small>
      </td>
    </tr>
  </table>


</div>
