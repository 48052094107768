import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {ShopWarenkorbTableInterface} from '../../interfaces/shop.warenkorb-table-interface';
import {AppViewService} from '../../../../services/app.view.service';
import {Warenkorb} from '../../../../models/warenkorb';
import {AppMobileService} from '../../../../services/app.mobile.service';
import {ViewSettings} from '../../../../models/view-settings';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AppAsyncTimeout} from "../../../../helpers/app.asyncTimeout";
import {Artikel} from "../../../../models/artikel";
import {ArtikelAktion} from "../../../../models/artikel-aktion";
import {ArtikelWithAktionen} from "../../../../models/artikel-with-aktionen";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: '[warenkorb-table]',
  templateUrl: 'shop.warenkorb-table.component.html',
  styleUrls: ['shop.warenkorb-table.component.scss']
})
export class ShopWarenkorbTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() title: string;
  @Input() positionen: WarenkorbEntry[];
  @Input() parent: ShopWarenkorbTableInterface;
  @Input() show_category = true;
  @Input() actions = true;
  @Input() warenkorb: Warenkorb;
  @Input() summe: any;
  @Input() ajax_mode = false;

  @Input() active_fields: string[] = [];

  @Input() valid = true;
  @Output() validChange = new EventEmitter();

  visible_artikel_aktionen = new BehaviorSubject<ArtikelWithAktionen>(null);

  view_settings: ViewSettings[] | any;

  vsub: Subscription;

  posTimeouts = {};

  constructor(public vsvc: AppViewService, public msvc: AppMobileService, private elref: ElementRef,
              private cd: ChangeDetectorRef) {
  }

  showAktion(artikel: Artikel, aktionen: ArtikelAktion[]) {
    const a = new ArtikelWithAktionen();
    a.artikel = artikel;
    a.aktionen = aktionen;
    this.visible_artikel_aktionen.next(a);
  }

  hideAktionen() {
    this.visible_artikel_aktionen.next(null);
  }

  private detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.detectChanges();
  }

  validate() {
    let valid = true;
    this.positionen.forEach(e => {
      if (!e.valid) {
        valid = false;
      }
    });
    this.valid = valid;
    this.validChange.emit(this.valid);
  }

  updatePosition(idx, entry: WarenkorbEntry) {
    this.positionen[idx].dirty = entry.dirty;
    if (this.posTimeouts[entry.id]) {
      clearTimeout(this.posTimeouts[entry.id]);
    }
    this.valid = false;
    this.validChange.emit(this.valid);

    this.posTimeouts[entry.id] = setTimeout(() => {
      this.positionen[idx] = entry;
      this.validate();
      if (entry.dirty) {
        this.parent.updatePosition(entry);
      }
    }, 1000);
  }

  trackByValue(a, b) {
    return b;
  }
  trackById(a, b) {
    return a;
  }

  addNoteLine(entry: WarenkorbEntry) {
    if (entry.note.length < 20) {
      this.parent.addNoteLine(entry);

      AppAsyncTimeout.setTimeout(
        () => {
          const lines = this.elref.nativeElement.querySelectorAll('.nodeline_' + entry.id);
          if (lines.item(lines.length - 1)) {
            lines.item(lines.length - 1).focus();
          }
        },
        250
      );
    }
  }

  ngOnDestroy(): void {
    if (this.vsub instanceof Subscription) {
      this.vsub.unsubscribe();
    }
  }


  ngOnInit(): void {
    this.vsub = this.vsvc.menu_views$.subscribe((vs) => {
      this.view_settings = {};
      if (vs) {
        vs.forEach(v => {
          this.view_settings[v.slug] = v.settings;
        });
      }
    });

    AppAsyncTimeout.setTimeout(() => {
      this.validate();
    }, 200);
  }


}
