import {FileBrowserEntry} from "../../../models/file-browser-entry";

export class FileBrowserSelectedFile {
  file_browser_data = true;
  id: number;
  label: string;
  path: string;

  fromFileEntry(entry: FileBrowserEntry) {
    this.path = entry.renderPath();
    this.label = entry.label;
    this.id = entry.id;
  }
}
