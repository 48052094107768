<h1 mat-dialog-title>
  <svg-icon>plus</svg-icon> Widget hinzufügen
</h1>
<div mat-dialog-content class="paddedcontent" style="padding: 25px">
  <p>Bitte wählen Sie ein hinzuzufügendes Widget aus!</p>
  <div style="padding: 5px">
    <div *ngFor="let w of widgets" (click)="send(w)" style="padding: 10px; margin: 5px" class="clickelem">
      <table style="width: 100%">
        <tr>
          <td>
            <span style="font-weight: bold">
              {{w.label}}
            </span>
          </td>
          <td style="text-align: right">
            {{w.cols}} x {{w.rows}}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div *ngIf="w.description" style="padding-left: 10px">
              {{w.description}}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div style="text-align: center" mat-dialog-actions>
  <button (click)="cancel()" class="mat-button" mat-button>
    Abbrechen
  </button>
</div>
