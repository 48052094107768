import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {AppEventService} from '../services/app.event.service';
import {AppViewService} from "../services/app.view.service";
import {AppLieferkundeService} from "../services/app.lieferkunde.service";
import {AppMemoryService} from "../services/app.memory.service";
import {takeWhile} from "rxjs/operators";

@Injectable()
export class AppDifferntialViewGuard  {

  constructor(private router: Router, private vsvc: AppViewService, private lksvc: AppLieferkundeService,
              private esvc: AppEventService, private memsvc: AppMemoryService) {
  }

  canActivate(route: ActivatedRouteSnapshot, rtsnap: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      let done = false;
      if (route.params['slug']) {
        const sub = this.vsvc.loaded.pipe(takeWhile((value) => {
          return done !== true;

        })).subscribe({
          next: (val) => {

            if (val) {
              let view = this.vsvc.getViewBySlug(route.params['slug'])
              if (view.settings && view.settings.different_lieferkunde_mode && !this.lksvc.current_differential_lieferkunde.value) {
                this.memsvc.set('lieferkunde-selector_redirurl', '/shop/'+route.params['slug']);
                this.router.navigateByUrl('/lieferkunde');
              }

              observer.next(true);
              observer.complete();
              done = true;
            }
          }
        });
      }
    });
  }
}
