import {User} from "./user";
import {File} from "./file";

export class FileBrowserEntry {
  id: number;
  label: string;
  type: string;
  file: File;
  parent: FileBrowserEntry;
  children: FileBrowserEntry[];
  ro: boolean;
  ts_insert: string;
  insert_by: User;
  ts_update: string;
  update_by: User;
  is_root: boolean;

  renderPathElement(e: FileBrowserEntry) {

    let path = '';
    if (e.parent && !e.parent.is_root) {
      path += this.renderPathElement(e.parent);
    }

    path += '/' + e.label;

    return path;
  }

  renderPath() {
    return this.renderPathElement(this);
  }
}
