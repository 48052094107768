import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Formbutton} from './formbutton';
import {Formfield} from './formfield';


@Component({
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss']
})
export class FormComponentDialog {
  text: string;
  title: string;

  fields: Formfield[];
  buttons: Formbutton[];

  constructor(public dialogRef: MatDialogRef<FormComponentDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.text = this.data.text;
    this.title = this.data.title;
    this.fields = this.data.fields;
    this.buttons = this.data.buttons;
  }

  fieldEnterKeyDown(field: Formfield) {
    if (field.keydown_enter_button_press_id) {
      const b = new Formbutton();
      b.id = field.keydown_enter_button_press_id;
      this.click(b);
    }
  }

  click(button: Formbutton) {
    const data = {};
    for (const f of this.fields) {
      data[f.id] = f.value;
    }

    this.dialogRef.close({
      clicked_id: button.id,
      data: data
    });
  }
}
