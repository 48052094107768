<h1 mat-dialog-title>
  <table>
    <tr>
      <td style="vertical-align: top">
        <svg-icon *ngIf="icon">{{icon}}</svg-icon>
      </td>
      <td style="vertical-align: top">
        {{title}}
      </td>
    </tr>
  </table>

</h1>
<div mat-dialog-content style="border-top: 1px solid lightgrey; border-bottom: 1px solid lightgrey; padding: 10px 26px; min-width: 200px; min-height: 100px">
<div [innerHTML]="text|raw"></div>
</div>
<div mat-dialog-actions style="justify-content: center">
  <button (click)="click(button)" *ngFor="let button of buttons" class="mat-raised-button {{button.class}}"
          mat-raised-button>
    {{button.label}}
  </button>
</div>
