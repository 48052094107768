import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ContentChild, ContentChildren,
  Input,
  OnChanges,
  OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren
} from '@angular/core';
import {Artikel} from '../../../../models/artikel';
import {BehaviorSubject} from 'rxjs';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';
import {AppConfigService} from '../../../../services/app.config.service';
import {View} from '../../../../models/view';
import {ShopViewComponent} from '../view/shop.view.component';
import {Warenkorb} from '../../../../models/warenkorb';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'view-field-value-render',
  templateUrl: 'shop.view-field-value-renderer.component.html',
  styleUrls: ['shop.view-field-value-renderer.component.scss']
})
export class ShopViewFieldValueRendererComponent implements OnInit {
  @Input() fieldname: string;
  @Input() mode = 'table';
  @Input() artikel: Artikel;
  @Input() view: View;
  @Input() viewcomp: any;
  @Input() nowrap = true;
  @Input() current_warenkorb: Warenkorb;
  @Input() append_if_not_empty: string;

  baseUrl: string;

  show_uvp = new BehaviorSubject<boolean>(false);

  constructor(public wksvc: AppWarenkorbService, private cfg: AppConfigService) {
    this.baseUrl = cfg.buildUrl('/');
  }

  isInOtherWarenkorb() {
    if (this.current_warenkorb) {
      return false;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    if (this.artikel && !this.view && this.artikel.view) {
      this.view = this.artikel.view;
    }
    if (this.fieldname == 'einkaufspreis') {
      if (this.cfg.domainconfig.features.includes('UVP') && this.view && this.view && this.view.settings && this.view.settings.show_uvp) {
        this.show_uvp.next(true);
      } else {
        this.show_uvp.next(false);
      }
    }
  }
}
