import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AppPrintService {
  printing = new BehaviorSubject<boolean>(false);
  printing$ = this.printing.asObservable();

  start() {
    this.printing.next(true);
  }

  finish() {
    this.printing.next(false);
  }
}
