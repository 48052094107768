import {ViewSettings} from './view-settings';
import {ViewFilter} from './view-filter';

export class View {
  knoten_id: number;
  artikelstamm: number;
  label: string;
  filters: ViewFilter[];
  slug: string;
  suche: string;
  page_number: number;
  page_size: number;
  settings: ViewSettings;
  last_active_filters: ViewFilter[];
  last_suche: string;
  last_slug_used: string;


  allowed: boolean;

  getActiveFilterKnotenIds(): string[] {
    const data = [];

    this.filters.forEach(f => {
      f.getActiveFilterKnotenIds().forEach(c => {
        data.push(c);
      });
    });

    return data;
  }

  setActiveFilterKnotenIds(ids: string[]) {
    this.filters.forEach(f => {
      f.setActiveFilterKnotenIds(ids);
    });
  }

  getBGColor() {
    if (this.settings && this.settings.icon) {
      return this.settings.bg_color;
    } else {
      return '';
    }
  }

  getFontColor() {
    if (this.settings && this.settings.icon) {
      return this.settings.font_color;
    } else {
      return '';
    }
  }

  getIcon() {
    if (this.settings && this.settings.icon) {
      return this.settings.icon;
    } else {
      return 'shop';
    }
  }

  getLabel() {
    if (this.settings && this.settings.label) {
      return this.settings.label;
    } else {
      return this.label;
    }
  }
}
