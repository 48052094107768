import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';
import {Warenkorb} from '../../../../models/warenkorb';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {WarenkorbKategorie} from '../../../../models/warenkorb-kategorie';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ajax-warenkorb',
  templateUrl: 'shop.ajax-warenkorb.component.html',
  styleUrls: ['shop.ajax-warenkorb.component.scss'],
})
export class ShopAjaxWarenkorbComponent implements OnInit, OnDestroy {
  static id = 'ShopAjaxWarenkorbComponent';
  @Output() drawer_show = new EventEmitter();
  @Output() drawer_close = new EventEmitter();

  wkid: number;
  warenkorb = new BehaviorSubject<Warenkorb>(null);
  positions = new BehaviorSubject<WarenkorbEntry[]>([]);
  kategorien = new BehaviorSubject<WarenkorbKategorie[]>([]);

  loading = new BehaviorSubject<boolean>(true);


  label_map = {};

  wksub: Subscription;

  update_timeout: any;
  note_autosave_timeout: any;
  poscount: number;

  wkkref: any;

  constructor(public svc: AppWarenkorbService) {
  }

  load(silent = false) {
    if (!silent) {
      this.loading.next(true);
    }
    this.svc.getWarenkorbById(this.wkid).subscribe({
      next: (wk) => {
        this.warenkorb.next(wk);
        if (!silent) {
          this.loading.next(false);
        }
        this.drawer_show.emit();
      },
      error: (e) => {
        if (e.error && e.error.code == 10005) {
          this.svc.load();
        }
        this.loading.next(false);
      }
    });
  }


  ngOnDestroy(): void {
    if (this.wksub instanceof Subscription) {
      this.wksub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.wksub = this.svc.primary_warenkorb$.subscribe(w => {
      if (w) {
        this.wkid = w.id;
        this.load();
      }
    });
  }

}
