import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUserService} from '../../services/app.user.service';
import {AppMessageService} from '../../services/app.message.service';
import {AppDialogsService} from '../../services/app.dialogs.service';
import {AppConfigService} from '../../services/app.config.service';
import {AppPageTitleService} from '../../services/app.page-title.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DomainConfig} from '../../models/domain-config';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../validators/custom-validators';

@Component({
  // moduleId: module.id,
  selector: 'app-reset-password',
  templateUrl: 'app.reset-password.component.html',
  styleUrls: ['app.reset-password.component.scss']
})
export class AppResetPasswordComponent implements OnInit, OnDestroy {
  static id = 'AppResetPasswordComponent';
  email: string;
  token: string;

  password: string;
  confirmPassword: string;

  loading = new BehaviorSubject<boolean>(false);

  domaincfg: DomainConfig;

  rsub: Subscription;

  send = false;

  error = new BehaviorSubject<string>(null);
  message = new BehaviorSubject<string>(null);

  public frmgrp2: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private usvc: AppUserService,
              private msg: AppMessageService, private dialog: AppDialogsService, private cfg: AppConfigService,
              private tsvc: AppPageTitleService, private loader: AppPageloaderService) {
    this.domaincfg = cfg.domainconfig;
  }

  createForm2() {
    return this.fb.group(
      {
        // email is required and must be a valid email email
        password: [
          null,
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {hasNumber: true}),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(/[@$!%*#?&.:_\-(){}\[\]]/, {hasSpecialCharacters: true}),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
            Validators.maxLength(30),
          ])],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  send2() {
    this.send = true;
    const l = this.loader.start();

    this.usvc.resetPassword2(this.token, this.frmgrp2.get('password').value).subscribe({
      next: (s) => {
        if (s) {
          this.message.next('Erfolgreich geändert!');
          this.error.next(null);
        } else {
          this.error.next('Fehlgeschlagen!');
          this.message.next(null);
        }

        this.send = false;

        l.stop();
      }, error: () => {
        l.stop();
        this.send = false;
      }
    });
  }

  send1() {
    this.send = true;
    const l = this.loader.start();
    this.usvc.testUserExists(this.email).subscribe({
      next: (r) => {
        if (r.BenutzerExistiert == false) {
          this.error.next('Ungültige E-Mail Adresse!');
          this.message.next(null);
        } else {
          l.stop();
          const l2 = this.loader.start();
          this.usvc.resetPassword1(this.email).subscribe({next: (s) => {
            if (s) {
              this.message.next('Sie erhalten in Kürze eine E-Mail mit weiteren Anweisungen!');
              this.error.next(null);
            } else {
              this.error.next('Fehlgeschlagen!');
              this.message.next(null);
            }
            l2.stop();
          }, error: () => {
            l2.stop();
            this.send = false;
          }
          });
        }
      }, error: () => {
        this.send = false;
        l.stop();
      }
    });
  }

  ngOnDestroy(): void {
    this.tsvc.resetTitle();
  }

  ngOnInit(): void {
    this.tsvc.setTitle('Passwort zurücksetzen');

    this.rsub = this.route.params.subscribe(p => {
      if (p['token']) {
        this.token = p['token'];
        this.frmgrp2 = this.createForm2();
      }
    });
  }


}
