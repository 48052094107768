<table (click)="navigateSeen($event)" class="clickable notentry">
  <tr>
    <td class="icon" rowspan="2">
      <svg-icon>{{getIcon()}}</svg-icon>
    </td>
    <td (click)="navigateSeen($event)" class="title">
      {{getLabel()}}
      <div style="text-align: right; font-size: 11px; opacity: 0.6;">
        <span *ngIf="show_seen">Gesendet: </span>{{notification.dat_insert}}
      </div>
      <div style="text-align: right; font-size: 11px;" *ngIf="show_seen && notification.seen">
        <svg-icon color="green">check</svg-icon> Gesehen: {{notification.dat_seen}}
      </div>
    </td>
    <td class="action" rowspan="2">
      <button (click)="setSeen($event);" *ngIf="show_dismissable && notification.dismissable" mat-icon-button>
        <svg-icon>error-bk</svg-icon>
      </button>
    </td>
  </tr>
  <tr>
    <td [innerHtml]="getText() | nl2br | raw" class="payload"></td>
  </tr>
</table>
