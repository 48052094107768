import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {Artikel} from '../../../../models/artikel';
import {AppPageloaderService} from '../../../../services/app.pageloader.service';
import {BehaviorSubject} from 'rxjs';
import {AppEventService} from '../../../../services/app.event.service';
import {Merkliste} from '../../../../models/merkliste';
import {AppMerklisteService} from '../../../../services/app.merkliste.service';
import {MerklistActionInfo} from '../../../../models/merklist-action-info';
import {ShopMerklisteActionInfoComponent} from '../merkliste-action-info/shop.merkliste-action-info.component';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ShopMerklisteMenuComponent} from '../merkliste-menu/shop.merkliste-menu.component';
import {AppSnackbarService} from "../../../../services/app.snackbar.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'merkliste-button',
  templateUrl: 'shop.merklisten-button.component.html',
  styleUrls: ['shop.merklisten-button.component.scss']
})
export class ShopMerklistenButtonComponent {
  @Input() disabled = false;
  @Input() artikel: Artikel;
  @Input() view_slug: string;
  @Input() entry: WarenkorbEntry;


  merklisten: Merkliste[];

  primary_merkliste = new BehaviorSubject<Merkliste>(null);

  add_mode = false;

  newml: Merkliste;

  constructor(private loader: AppPageloaderService, public mlsvc: AppMerklisteService, private snackBarSvc: AppSnackbarService,
              private esvc: AppEventService, private bottomsheet: MatBottomSheet, private cd: ChangeDetectorRef) {
  }

  showMenu(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.bottomsheet.open(ShopMerklisteMenuComponent, {
      data: {
        artikel: this.artikel,
        entry: this.entry,
        view_slug: this.view_slug
      }
    });
  }

  addToPrimaryMerkliste() {
    return this.addToMerkliste(this.mlsvc.primary_merkliste.value);
  }

  addToMerkliste(ml: Merkliste) {
    this.loader.start('ShopViewActionComponent_' + this.artikel.ArtikelNummer + '_1');

    this.mlsvc.addToMerklisteById(
      ml.id,
      this.view_slug,
      this.artikel.ArtikelNummer,
      this.entry.menge
    ).subscribe(m => {
      this.loader.stop('ShopViewActionComponent_' + this.artikel.ArtikelNummer + '_1');

      this.entry.menge = 1;

      const i = new MerklistActionInfo();
      i.action = 'add';
      i.info = m;


      this.snackBarSvc.open(ShopMerklisteActionInfoComponent, i, 2500);
    });
  }
}
