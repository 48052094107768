<div (keydown)="keydown($event)" *ngIf="data && data.status.Verfuegbarkeitsinformation" class="infobox">
  <ng-container *ngIf="data.status.Nachfolgeartikel">
    <h1>Nachfolge Artikel vorhanden!</h1>
    <hr>
    <h3>
        {{data.status.Nachfolgeartikel.ArtikelName}}
    </h3>
    <h5>Nachfolger für:
      <ng-container *ngIf="data.artikel.ArtikelBezeichnung">
        {{data.artikel.ArtikelBezeichnung}}
      </ng-container>
      <ng-container *ngIf="!data.artikel.ArtikelBezeichnung">
        {{data.artikel.ArtikelName}}
      </ng-container>
    </h5>

    <div style="text-align: center">
      <table style="margin: auto">
        <tr>
          <th>EP:</th>
          <td>{{data.status.Nachfolgeartikel.Kundeneinkaufspreis}} &euro;</td>
          <td></td>
          <th>VP:</th>
          <td>{{data.status.Nachfolgeartikel.Kundenverkaufspreis}} &euro;</td>
        </tr>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="!data.status.Nachfolgeartikel">
    <h3>
      <ng-container *ngIf="data.artikel.ArtikelBezeichnung">
        {{data.artikel.ArtikelBezeichnung}}
      </ng-container>
      <ng-container *ngIf="!data.artikel.ArtikelBezeichnung">
        {{data.artikel.ArtikelName}}
      </ng-container>
    </h3>
  </ng-container>

  <div *ngFor="let i of data.status.Verfuegbarkeitsinformation">
    <div style="text-align: center; padding: 10px">
      <svg-icon *ngIf="i.color=='green'" size="100" class="infoicon infoicon-green">success-bk</svg-icon>
      <svg-icon *ngIf="i.color=='orange'" size="100" class="infoicon infoicon-orange">rueckstaende</svg-icon>
      <svg-icon *ngIf="i.color=='orange2'" size="100" class="infoicon infoicon-orange2">success-bk</svg-icon>
      <svg-icon *ngIf="i.color=='red'" size="100" class="infoicon infoicon-red">abbrechen</svg-icon>
    </div>
    <div style="text-align: center; font-size: 40px">
      {{i.Menge}} ST - {{i.InfoText}}
    </div>
    <div *ngIf="i.Zusatzinformation && i.Zusatzinformation.length > 0">
      <hr>
      <table>
        <tr>
          <td class="label label2">Details:</td>
          <td class="info info2" colspan="2">
            <div *ngFor="let txt of i.Zusatzinformation">
              {{txt.text}}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
