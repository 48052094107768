<div *ngIf="punkte && steps">
  <button class="close" mat-button mat-dialog-close>X</button>
  <h1 mat-dialog-title>
    {{punkte.Bonuspunkteprogramm.Bezeichnung}}
  </h1>
  <div mat-dialog-content>
    <div class="levelbarbox">
      <div class="levelbar">
        <div class="fillbar" [style.height]="calcPercentage(punkte.Bonuspunkte)+'%'">
          <div class="levelinfobar">
            <div class="levelinfo">
              {{punkte.level}} - {{punkte.Bonuspunkte}} {{getPointsLabel()}}
            </div>
          </div>
        </div>

        <div *ngFor="let s of steps" [style.height]="calcPercentage(step_heights[s.level])+'%'" class="level">
          <div class="levelinfo">
            {{s.level}} - {{s.AbBonuspunktSaldo}} {{getPointsLabel()}} - {{s.GutschriftProzent}} %
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
