import {Injectable} from "@angular/core";
import {AppUserService} from "./app.user.service";
import {AppLieferkundeService} from "./app.lieferkunde.service";
import {BehaviorSubject} from "rxjs";
import {AppWarenkorbService} from "./app.warenkorb.service";

@Injectable({providedIn: "root"})
export class AppReadyStateService {
  readyLevel1 = new BehaviorSubject(false);
  readyLevel2 = new BehaviorSubject(false);
  constructor(private usvc: AppUserService, private lksvc: AppLieferkundeService, private wksvc: AppWarenkorbService) {
    usvc.user$.subscribe(u => {
      this.checkStates();
    })
    usvc.lkWarmupDone.subscribe(u => {
      this.checkStates();
    })
    lksvc.current_lieferkunde$.subscribe(lk => {
      this.checkStates();
    })
    wksvc.booted.subscribe(s => {
      this.checkStates();
    })
  }

  checkStates() {
    let readyLevel1New = false;
    let readyLevel2New = false;

    if (this.usvc.user.value && this.lksvc.current_lieferkunde.value && this.usvc.lkWarmupDone.value) {
      readyLevel1New = true;

      if (this.wksvc.booted.value) {
        readyLevel2New = true;
      }
    }

    if (this.readyLevel1.value !== readyLevel1New) {
      this.readyLevel1.next(readyLevel1New);
    }

    if (this.readyLevel2.value !== readyLevel2New) {
      this.readyLevel2.next(readyLevel2New);
    }
  }
}
