import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUserService} from '../../services/app.user.service';

@Component({
  template: ''
})
export class AppUserLogoutComponent implements OnInit, AfterViewInit {
  static id = 'AppUserLogoutComponent';
  model: any = {};

  logout_started = false;

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AppUserService) {
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
  }

  ngAfterViewInit() {
    this.logout();
  }

  logout() {
    if (!this.logout_started) {
      this.logout_started = true;
      this.authenticationService.logout()
        .subscribe(
          {
            next: (data) => {
              this.authenticationService.isLoggedin$.subscribe(state => {
                this.router.navigate(['/login']);
              });

            },
            error: (e) => {

            }
          });
    }
  }
}
