import {Erzeuger} from './erzeuger';
import {ArtikelInfo} from './artikel-info';
import {ArtikelZubehoer} from './artikel-zubehoer';
import {ArtikelNote} from './artikel-note';
import {ArtikelGruppe} from './artikel-gruppe';
import {View} from './view';
import {IAlbum} from "ngx-lightbox";
import {Bestand} from "./bestand";

export class Artikel {
  Erzeuger: Erzeuger;
  Artikelgruppe: ArtikelGruppe[];
  ArtikelNummer: number;
  ArtikelName: string;
  ArtikelBezeichnung: string;
  Artikelstamm: string;
  PZN: string;
  Kundeneinkaufspreis: number;
  Kundenverkaufspreis: number;
  ArtikelHatAktion: boolean;
  Packungsgroesse: number;
  Packungseinheit: string;
  Darreichungsform: string;
  VerfuegbarkeitsstatusCode: string;
  VerfuegbarkeitsstatusText: string;
  LieferOderNachlieferbar: boolean;
  PreisLtPreiscodeMitPotentiellAufschlag: number;
  AbgabebeschraenkungCode: string;
  AbgabebeschraenkungText: string;
  Kurzbeschreibung: string;
  Produktfamilie: string;
  Muendung: string;
  BildDateinameKlein: string;
  RezeptZeichen: string;
  IstArzneimittel: boolean;
  ArtikelZulassungsnummer: string;
  // HatBezugBerechtigung: true;
  GrundBezugAblehnung: string;
  Mehrwertsteuersatz: number;
  logo1: IAlbum;
  logo2: IAlbum;
  infos: ArtikelInfo[];
  zubehoer: ArtikelZubehoer[];
  note: ArtikelNote;
  Laenge: number;
  Breite: number;
  Hoehe: number;
  Gewicht: number;
  label: string;

  // optional
  view: View;


  UVPDiffProzent: number;
  bestandSum: any;

  bestand: Bestand[];

  MinAblauf: string;

  isVFStatusPruefbar() {
    if (this.Artikelstamm == 'DE' && !this.PZN) {
      return false;
    } else {
      return true;
    }
  }
}
