<button (click)="addAll()" matTooltip="Alles in den Warenkorb legen" [disabled]="(artCount|async) == 0"
        mat-button class="buttonstyle2 tut_shopview_wkbestellblockmodus" matBadge="{{artCount|async}}">
  <svg-icon [color]="(artCount|async) > 0 ? 'orange' : 'grey'">in-warenkorb</svg-icon>
</button>

<button (click)="addAllAndSend()" matTooltip="Alles in den Warenkorb legen und absenden" [disabled]="(artCount|async) == 0"
        mat-button class="buttonstyle2 tut_shopview_wkbestellblockmodus" matBadge="{{artCount|async}}">
  <svg-icon [color]="(artCount|async) > 0 ? 'orange' : 'grey'">in-warenkorb</svg-icon>
  <svg-icon [color]="(artCount|async) > 0 ? 'orange' : 'grey'">senden</svg-icon>
</button>
