import {AfterViewChecked, AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {Tutorialstep} from '../../models/tutorialstep';
import {AppTutorialService} from '../../services/app.tutorial.service';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {AppUserService} from '../../services/app.user.service';
import {User} from '../../models/user';
import {APPTUTORIALS} from './app.tutorials.definition';
import {AppSettingsService} from '../../services/app.app_settings.service';
import {AppEventService} from '../../services/app.event.service';
import {AppDialogsService} from '../../services/app.dialogs.service';
import {AppMessageService} from '../../services/app.message.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';

@Component({
  selector: 'app-tutorials',
  templateUrl: 'app.tutorials.component.html'
})
export class AppTutorialsComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  static id = 'AppTutorialsComponent';

  current_step: Tutorialstep = new Tutorialstep('', '');

  seen: string[] = null;
  user: User;
  ulistener: Subscription;

  start_timeout: any;

  tutorials = APPTUTORIALS;

  started = false;
  rlistener: Subscription;
  llistener: Subscription;
  loggedin = false;

  stopped = false;


  running = false;
  enabled = false;

  lsub: Subscription;

  ctimeout: any;

  recheck_after_done = false;
  esub: Subscription;

  loading = false;

  constructor(@Inject(DOCUMENT) private document, private svc: AppTutorialService, private router: Router,
              private usvc: AppUserService, private settings: AppSettingsService, private esvc: AppEventService,
              private dialog: AppDialogsService, private msg: AppMessageService, private loader: AppPageloaderService) {
  }


  ngOnInit(): void {
    if (this.loggedin) {
      this.checkEnabled();
    }
    this.lsub = this.loader.loading$.subscribe(l => {
      this.loading = l;
    });
    this.loader.all_done.subscribe(s => {
      if (s) {
        if (this.loggedin && !this.running) {
          if (this.ctimeout) {
            clearTimeout(this.ctimeout);
          }
          this.ctimeout = setTimeout(() => {
            this.nextcheck();
          }, 1000);
        }
      } else {
        this.recheck_after_done = true;
      }
    });
    this.esub = this.esvc.getQueue().subscribe(e => {
      if (e.name == 'gui.app_body_click') {
        if (!this.running && this.loggedin) {
          setTimeout(() => {
            this.nextcheck();
          }, 500);
        }
      }
    });
  }

  nextcheck() {
    if (!this.loading) {
      setTimeout(() => {
        this.next();
      }, 200);

    } else {
      setTimeout(() => {
        this.nextcheck();
      }, 200);
    }
  }

  checkEnabled() {
    if (!this.stopped) {
      this.recheck_after_done = false;
      this.settings.getUserSettingValue('TutorialEnabled').subscribe(v => {
        this.enabled = v;
        this.load(true);
      });
    }
  }

  ngAfterViewInit(): void {
    this.rlistener = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd && this.loggedin) {
        this.load(true);
      }
    });

    this.llistener = this.usvc.isLoggedin$.subscribe(s => {
      this.loggedin = s;
      if (this.loggedin) {
        this.checkEnabled();
      }
    });

    this.ulistener = this.usvc.user$.subscribe(u => {
      this.user = u;
    });

    this.esvc.queue$.subscribe(e => {
      if (e.name === 'settings_changed' &&
        (e.data.id === 'App\\Model\\Settings\\Definition\\User\\TutorialEnabled' ||
          e.data.id == 'App\\Model\\Settings\\Definition\\User\\TutorialReset')
      ) {
        if (this.loggedin) {
          this.checkEnabled();
        }
      }
    });
  }

  disableForNow() {
    this.stopped = true;
    this.enabled = false;
    this.current_step = new Tutorialstep('', '');
    this.tutorials = [];
  }

  disable() {
    this.dialog.confirm(
      'frage',
      'Tutorials deaktivieren',
      'Wirklich deaktivieren? Diese können in den Einstellungen wieder aktiviert werden!',
      () => {
        this.settings.getUserSettings().subscribe(sett => {
          for (const s of sett) {
            if (s.id == 'App\\Model\\Settings\\Definition\\User\\TutorialEnabled') {
              s.value = false;
            }
          }
          this.settings.setUserSettings(sett).subscribe(s => {
            this.msg.info('Tutorials deaktiviert!');
            this.disableForNow();
          });
        });
      }, () => {

      }
    );
  }

  ngOnDestroy(): void {
    if (this.rlistener instanceof Subscription) {
      this.rlistener.unsubscribe();
    }
    if (this.llistener instanceof Subscription) {
      this.llistener.unsubscribe();
    }
    if (this.ulistener instanceof Subscription) {
      this.ulistener.unsubscribe();
    }
    if (this.lsub instanceof Subscription) {
      this.lsub.unsubscribe();
    }
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }
  }

  load(next_after_load = false) {
    if (this.loggedin && this.enabled) {
      this.svc.load().subscribe(s => {
        this.seen = s;
        if (next_after_load) {
          this.current_step = new Tutorialstep('', '');
          setTimeout(() => {
            this.nextcheck();
          }, 300);
        }
      });
    } else {
      this.seen = null;
      this.current_step = new Tutorialstep('', '');
      if (this.recheck_after_done) {
        this.checkEnabled();
      }
    }
  }

  next() {
    this.running = true;
    let tnext = null;

    if (this.seen !== null) {
      for (const t of this.tutorials) {
        if (tnext === null && !this.seen.includes(t.focus_on)) {
          let allowed = false;

          if (t.roles.length <= 0) {
            allowed = true;
          } else {
            allowed = this.user.hasRoles(t.roles);
          }

          const activetuts = this.document.querySelectorAll('.tutorialactive');
          [].forEach.call(activetuts, (e) => {
            e.classList.remove('tutorialactive');
          });

          const elems = this.document.querySelectorAll(t.focus_on);

          [].forEach.call(elems, (elem) => {
            if (tnext === null) {
              if (allowed && elem !== null && this.check_isVisible(elem)) {
                elem.classList.add('tutorialactive');
                tnext = t;
                return;
              }
            }
          });
        }
      }
    }

    if (tnext instanceof Tutorialstep) {
      if (tnext.icon === null || tnext.icon == '') {
        tnext.icon = 'single_tap';
      }
      this.current_step = tnext;
    }
  }

  setSeen() {
    this.svc.setSeen(this.current_step.focus_on).subscribe(s => {
      this.load(true);
    });
  }

  testStart() {
    if (this.seen === null) {
      setTimeout(() => {
        this.testStart();
      }, 200);
    } else {
      setTimeout(() => {
        this.nextcheck();
      }, 200);
    }
  }

  start() {
    if (this.loggedin == true && this.current_step.focus_on == '' && !this.started) {
      if (this.start_timeout) {
        clearTimeout(this.start_timeout);
      }
      this.start_timeout = setTimeout(() => {
        this.testStart();
      }, 200);
    }
  }

  ngAfterViewChecked(): void {
    this.start();
  }

  private check_isVisible(elem) {
    return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );
  }
}
