import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AppNotificationComponent} from './app.notification.component';
import {Notification} from '../../models/notification';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-notification-entry',
  templateUrl: 'app.notification.entry.component.html',
  styleUrls: ['app.notification.entry.component.scss']
})
export class AppNotificationEntryComponent implements OnInit {
  static id = 'AppNotificationEntryComponent';
  @Input() parent: AppNotificationComponent;
  @Input() notification: Notification;
  @Input() show_dismissable = true;
  @Input() show_seen = false;

  show = true;

  setSeen(event: Event) {
    this.parent.setSeen(event, this.notification);
  }

  ngOnInit(): void {
  }

  getText() {
    return this.notification.getText(this.parent);
  }

  getLabel() {
    return this.notification.getLabel(this.parent);
  }

  getIcon() {
    return this.notification.getIcon(this.parent);
  }

  navigateSeen(event: Event) {
    event.stopImmediatePropagation();

    const url = this.notification.getUrl(this.parent.notificationService);

    this.parent.navigateSeen(this.notification, url);
  }


}
