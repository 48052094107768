<section-title [hasBack]="true" label="Alle Benachrichtigungen" icon="benachrichtigungen"></section-title>

<div *ngIf="notifications|async" style="text-align: center">
  <div *ngIf="(notifications|async)?.length == 0" align="center"  class="notbox mat-elevation-z1">
    <h3>Keine Benachrichtigungen vorhanden!</h3>
  </div>
  <div *ngFor="let notification of notifications|async; let last=last;" class="notbox mat-elevation-z1">
    <app-notification-entry [notification]="notification" [parent]="this"
                            [show_dismissable]="false" [show_seen]="true"></app-notification-entry>
  </div>
</div>
