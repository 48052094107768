import {AppNotificationComponent} from '../components/notification/app.notification.component';
import {AppNotificationService} from '../services/app.notification.service';

export class RenderedNotification {
  url: string;
  icon: string;
  label: string;
  text: string;
}

export class Notification {
  id: number;
  uid: string;
  section: string;
  action: string;
  valid_to: string;
  payload: any;
  rendered: RenderedNotification;
  dat_insert: string;
  dat_insert_nice: string;
  dat_seen: string;
  dismissable: boolean;
  seen: boolean;
  is_popup: boolean;

  getUrl(svc: AppNotificationService) {
    let url = '';

    if (this.rendered) {
      url = this.rendered.url;
    }

    return url;
  }

  getIcon(notcomp: AppNotificationComponent) {
    let icon = 'info';

    if (this.rendered) {
      icon = this.rendered.icon;
    }

    return icon;
  }

  getLabel(notcomp: AppNotificationComponent) {
    let label = '';

    if (this.rendered) {
      label = this.rendered.label;

    }

    return label;
  }

  getText(notcomp: AppNotificationComponent) {
    let text = '';

    if (this.rendered) {
      text = this.rendered.text;
    }

    return text;
  }
}
