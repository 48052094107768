import {Artikel} from './artikel';

export class MerklistEntry {
  id: number;
  artikel: Artikel;
  note: string[];
  view_slug: string;
  menge: number = 1;
  freimenge = 0;
  aktionsrabattmenge = 0;
  naturalrabattmenge = 0;
  aktionsrabattprozent = 0;
  basisverrechnungspreis: number;

  dirty = false;
  valid = true;

  defect: boolean;
}
