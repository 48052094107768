import {AntwortText} from './antwort-text';

export class Verfuegbarkeitsinformation {
  Zusatzinformation: AntwortText[];
  Menge: number;
  LieferungMoeglich: boolean;
  InfoCode: string;
  InfoText: string;
  MoeglichesZustelldatum: string;

  color = 'green';
}
