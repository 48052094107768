import {Component, Input} from '@angular/core';
import {Artikel} from '../../../../models/artikel';
import {MatDialog} from "@angular/material/dialog";
import {
  ShopDialogsCustomerSpecialPriceListComponent
} from "../../dialogs/customer-special-price-list/shop.dialogs.customer-special-price-list.component";
import {AppEventService} from "../../../../services/app.event.service";

@Component({
  selector: 'customer-special-price-edit-button',
  templateUrl: 'shop.customer-special-price-edit-button.component.html',
  styleUrls: ['shop.customer-special-price-edit-button.component.scss']
})
export class ShopCustomerSpecialPriceEditButtonComponent {
  @Input() artikel: Artikel;
  @Input() disabled = false;

  constructor(private dialog: MatDialog, private esvc: AppEventService) {
  }

  click() {
    let ref = this.dialog.open(ShopDialogsCustomerSpecialPriceListComponent, {
      data: this.artikel,
      disableClose: true,
    })

    ref.afterClosed().subscribe(res => {
      if (res === true) {
        this.esvc.addGuiEvent('ShopCustomerSpecialPriceChanged');
      }
    })
  }
}
