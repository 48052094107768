import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppSettingsService} from '../../services/app.app_settings.service';
import {SettingGroup} from '../../models/setting-group';
import {AppNavbarService} from '../../services/app.navbar.service';
import {Setting} from '../../models/setting';
import {FormControl} from '@angular/forms';
import {AppMessageService} from '../../services/app.message.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {AppPageTitleService} from '../../services/app.page-title.service';
import {SettingsBoxInterface} from "../../interfaces/settings-box-interface";
import {Observable} from "rxjs";

@Component({
  templateUrl: 'app.customer-settings.component.html',
  styleUrls: ['app.customer-settings.component.scss']
})
export class AppCustomerSettingsComponent implements OnInit, OnDestroy, SettingsBoxInterface {
  static id = 'AppCustomerSettingsComponent';
  settings: Setting[];


  loadReady = false;
  saveReady = false;

  value: any;

  constructor(private svc: AppSettingsService, private nbsvc: AppNavbarService, private msg: AppMessageService,
              private loader: AppPageloaderService, private tsvc: AppPageTitleService) {
  }

  ngOnDestroy(): void {
    this.nbsvc.clear();
    this.tsvc.resetTitle();
  }

  ngOnInit(): void {
    this.nbsvc.setLabel('Kunden-Einstellungen');
    this.tsvc.setTitle('Kunden-Einstellungen');
    this.load();
  }

  onClick(id) {
    this.svc.clickUserSetting(id).subscribe(s => {
      if (s) {
        this.msg.info(s);
      } else {
        this.msg.error(s);
      }
    });
  }

  onTest(id, val): Observable<boolean> {
    return new Observable(obs => {
      this.svc.testCustomerSettings(id, val).subscribe(s => {
        obs.next(s);
        obs.complete();
      });
    });
  }

  load() {
    this.svc.getCustomerSettings().subscribe(s => {
      this.settings = s;
    });
  }

  save() {
    this.saveReady = false;

    this.loader.start('SETTINGS_1');

    this.svc.setCustomerSettings(this.value).subscribe(s => {
      if (s) {
        this.msg.info('Gespeichert!');
      } else {
        this.msg.info('Fehler!');
      }

      this.loader.stop('SETTINGS_1');
    }, () => {
      this.saveReady = true;
      this.loader.stop('SETTINGS_1');
    });
  }
}
