<div (keydown)="keydown($event)" *ngIf="data" class="infobox">
  <table>
    <tr>
      <td>
        <span *ngIf="data.action == 'add'">
          <svg-icon size="80" [class.infoicon-green]="data.info.state == 'success'" class="infoicon">success</svg-icon>
        </span>
        <span *ngIf="data.action == 'delete'">
          <svg-icon size="80" [class.infoicon-green]="data.info.state == 'success'"
                    class="infoicon">loeschen</svg-icon>
        </span>
        <span *ngIf="data.action == 'wkdelete'">
          <svg-icon size="80" [class.infoicon-green]="data.info.state == 'success'" class="infoicon">loeschen</svg-icon>
        </span>
        <span *ngIf="data.action == 'wkempty'">
          <svg-icon size="80" [class.infoicon-green]="data.info.state == 'success'" class="infoicon">error-clean</svg-icon>
        </span>
      </td>
      <td>
        {{data.info.message}}
      </td>
    </tr>
  </table>
</div>
