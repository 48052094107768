import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {View} from '../../../../models/view';
import {ShopViewComponent} from '../view/shop.view.component';
import {Artikel} from '../../../../models/artikel';
import {ArtikelWithAktionen} from '../../../../models/artikel-with-aktionen';
import {AppSettingsService} from '../../../../services/app.app_settings.service';
import {AppViewService} from '../../../../services/app.view.service';
import {
  SharedViewFieldSelectorComponent
} from "../../../shared/components/view-field-selector/shared.view-field-selector.component";
import {AppMobileService} from "../../../../services/app.mobile.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'shop-view-result-table',
  templateUrl: 'shop.view-result-table.compontent.html',
  styleUrls: ['shop.view-result-table.compontent.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ShopViewResultTableCompontent implements OnInit, OnDestroy {
  @Input() viewcomp: ShopViewComponent;
  @Input() view: View;
  @Input() entries: BehaviorSubject<Artikel[]>;
  @Input() visible_artikel_aktionen: BehaviorSubject<ArtikelWithAktionen>;
  @Input() infoText: any;
  active_fields: string[];
  column_order = SharedViewFieldSelectorComponent.fields_order_table;

  private view_fields = new BehaviorSubject<string[]>([]);
  view_fields$ = this.view_fields.asObservable();

  has_bez_field = new BehaviorSubject<boolean>(true);

  constructor(public vsvc: AppViewService, public sett: AppSettingsService, public msvc: AppMobileService) {
  }

  loadFields() {
    let ucfg = this.vsvc.cfg_gui_table_columns.value;

    let tmp_fields = [];

    if (ucfg && ucfg[this.view.knoten_id] && typeof ucfg[this.view.knoten_id] !== 'undefined') {
      tmp_fields = ucfg[this.view.knoten_id];

    } else {
      tmp_fields = this.view.settings.table_default_fields;
      if (!tmp_fields || tmp_fields.length == 0) {
        tmp_fields = [
          "aktionen_button",
          "logo1",
          "bezeichnung",
          "minablauf",
          "darreichungsform",
          "hersteller",
          "pzn",
          "einkaufspreis",
          "verkaufspreis",
          "rezeptzeichen",
          "istarzneimittel",
          "status"
        ];
      }
    }

    let active_fields = [];
    this.column_order.forEach(c => {
      if (tmp_fields.includes(c)) {
        active_fields.push(c);
      }
    })
    this.active_fields = active_fields;

    this.updateView(false);
  }

  saveFields() {
    let tmp = this.vsvc.cfg_gui_box_columns.value;
    tmp[this.view.knoten_id] = this.active_fields;
    this.sett.setUserSettingValue('GUI_ShopViewTableColumns', tmp).subscribe();
  }

  trackByArtNr(a, b: Artikel) {
    return b.ArtikelNummer;
  }

  trackByValue(a, b) {
    return b;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.loadFields();
  }

  hideField(fname) {

  }

  updateView(save = true) {
    if (save) {
      this.saveFields();
    }

    this.has_bez_field.next(this.active_fields.includes('bezeichnung'));
    this.view_fields.next(this.active_fields);
  }
}
