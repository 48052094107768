<ng-container *ngIf="filter.children && filter.children.length > 0">
  <button (menuClosed)="this.open = false" (menuOpened)="this.open = true" [matMenuTriggerFor]="filterMenu"
          class="shop-view-filter-menu"
          mat-button>
    <table>
      <td style="width: 1%">
        <mat-checkbox [class.bordered]="filter.active_children && filter.active_children > 0" (change)="doChangeAll()" (click)="$event.stopPropagation()"
                      [(ngModel)]="filter.active"></mat-checkbox>
      </td>
      <td>
        {{ filter.label }}
      </td>
      <td>
        <svg-icon *ngIf="open">mehr</svg-icon>
        <svg-icon *ngIf="!open">weniger</svg-icon>
      </td>
    </table>
  </button>

  <mat-menu #filterMenu="matMenu">
    <button mat-menu-item *ngFor="let c of filter.children"
            [matMenuTriggerFor]="c.children.length > 0 ? childMenuLV1 : null" [matMenuTriggerData]="{filter: c}">
      <table>
        <td style="width: 1%">
          <div class="checkboxbox" (click)="markFilter($event, elem)">
            <mat-checkbox #elem [class.bordered]="c.active_children && c.active_children > 0" (change)="doChange(c)" (click)="$event.stopPropagation()"
                          [(ngModel)]="c.active"></mat-checkbox>
          </div>
        </td>
        <td>
          {{ c.label }}
        </td>
      </table>
    </button>
  </mat-menu>

  <mat-menu #childMenuLV1="matMenu">
    <ng-template matMenuContent let-filter="filter">
      <button mat-menu-item *ngFor="let c of filter.children" class="mat-menu-item"
              [matMenuTriggerFor]="c.children.length > 0 ? childMenuLV2 : null" [matMenuTriggerData]="{filter: c}">
        <table>
          <td style="width: 1%">
            <div class="checkboxbox" (click)="markFilter($event, elem)">
              <mat-checkbox #elem  [class.bordered]="c.active_children && c.active_children > 0" (change)="doChange(c)" (click)="$event.stopPropagation()"
                            [(ngModel)]="c.active"></mat-checkbox>
            </div>
          </td>
          <td>
            {{ c.label }}
          </td>
        </table>
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #childMenuLV2="matMenu">
    <ng-template matMenuContent let-filter="filter">
      <button mat-menu-item *ngFor="let c of filter.children" class="mat-menu-item"
              [matMenuTriggerFor]="c.children.length > 0 ? childMenuLV3 : null" [matMenuTriggerData]="{filter: c}">
        <table>
          <td style="width: 1%">
            <div class="checkboxbox" (click)="markFilter($event, elem)">
              <mat-checkbox #elem  [class.bordered]="c.active_children && c.active_children > 0" (change)="doChange(c)" (click)="$event.stopPropagation()"
                            [(ngModel)]="c.active"></mat-checkbox>
            </div>
          </td>
          <td>
            {{ c.label }}
          </td>
        </table>
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #childMenuLV3="matMenu">
    <ng-template matMenuContent let-filter="filter">
      <button mat-menu-item *ngFor="let c of filter.children" class="mat-menu-item"
              [matMenuTriggerFor]="c.children.length > 0 ? childMenuLV4 : null" [matMenuTriggerData]="{filter: c}">
        <table>
          <td style="width: 1%">
            <div class="checkboxbox" (click)="markFilter($event, elem)">
              <mat-checkbox #elem  [class.bordered]="c.active_children && c.active_children > 0" (change)="doChange(c)" (click)="$event.stopPropagation()"
                            [(ngModel)]="c.active"></mat-checkbox>
            </div>
          </td>
          <td>
            {{ c.label }}
          </td>
        </table>
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #childMenuLV4="matMenu">
    <ng-template matMenuContent let-filter="filter">
      <button mat-menu-item *ngFor="let c of filter.children" class="mat-menu-item">
        <table>
          <td style="width: 1%">
            <div class="checkboxbox" (click)="markFilter($event, elem)">
              <mat-checkbox #elem  [class.bordered]="c.active_children && c.active_children > 0" (change)="doChange(c)" (click)="$event.stopPropagation()"
                            [(ngModel)]="c.active"></mat-checkbox>
            </div>
          </td>
          <td>
            {{ c.label }}
          </td>
        </table>
      </button>
    </ng-template>
  </mat-menu>
</ng-container>

<ng-container *ngIf="!filter.children || (filter.children && filter.children.length == 0)">
  <button class="shop-view-filter-menu"
          mat-button (click)="toggle()">
    <table>
      <td style="width: 1%">
        <mat-checkbox (change)="doChangeAll()" (click)="$event.stopPropagation()"
                      [(ngModel)]="filter.active"></mat-checkbox>
      </td>
      <td>
        {{ filter.label }}
      </td>
    </table>
  </button>
</ng-container>
