import {Component, Inject, OnChanges, SimpleChanges} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppDialogsCustomdialogConfirmbutton} from './app.dialogs.customdialog.confirmbutton';


@Component({
  templateUrl: 'app.dialogs.customdialog.component.html',
  styleUrls: ['app.dialogs.customdialog.component.scss']
})
export class AppDialogsCustomdialogComponent implements OnChanges {
  static id = 'AppDialogsConfirmDialog';
  text: string;
  title: string;
  icon: string;
  buttons: AppDialogsCustomdialogConfirmbutton[];

  constructor(public dialogRef: MatDialogRef<AppDialogsCustomdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.init();
  }

  init() {
    this.text = this.data.text;
    this.title = this.data.title;
    this.buttons = this.data.buttons;
    if (this.data.icon) {
      this.icon = this.data.icon;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }


  click(button: AppDialogsCustomdialogConfirmbutton) {
    this.dialogRef.close(button.id);
  }
}
