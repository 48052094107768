import {AppAsyncTimeout} from "../helpers/app.asyncTimeout";

export class Message {
  severity: string;
  message: string;
  duration: number;
  visible: string;
  delete: boolean;

  constructor(severity: string, message: string, duration: number) {
    this.severity = severity;
    this.message = message;
    this.duration = duration;
    this.visible = 'false';
    this.delete = false;
    const me = this;

    AppAsyncTimeout.setTimeout(function () {
      me.show();
    }, 100);

  }

  show() {
    this.visible = 'true';
    const me = this;

    AppAsyncTimeout.setTimeout(function () {
      me.hide();
    }, (this.duration * 1000) + 200);
  }

  hide() {
    this.visible = 'false';
    const me = this;

    AppAsyncTimeout.setTimeout(function () {
      me.delete = true;
    }, 200);
  }

}
