<app-walkthrough
  (on-walkthrough-hidden)="setSeen()"
  [focus-element-selector]="focus_on"
  [has-backdrop]="true"
  [force-caption-location]="'BOTTOM'"
  [icon]="icon"
  [is-active]="true"
  [is-round]="true"
  [main-caption]="text"
  [use-button]="false"
  [walkthrough-type]="'transparency'">
</app-walkthrough>
