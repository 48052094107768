import {Injectable} from '@angular/core';
import {Message} from '../models/message';
import {AppConfigService} from './app.config.service';
import {AppSnackbarMessageComponent} from '../components/snackbar-message/app.snackbar-message.component';
import {AppSnackbarService} from "./app.snackbar.service";

@Injectable({providedIn: 'root'})
export class AppMessageService {
  constructor(private cfg: AppConfigService, private snackBarSvc: AppSnackbarService) {
  }

  info(message: string, durationMilliseconds: number = 0) {
    this.addMessage('info', message, durationMilliseconds);
  }

  error(message: string, durationMilliseconds: number = 0) {
    this.addMessage('error', message, durationMilliseconds);
  }

  warning(message: string, durationMilliseconds: number = 0) {
    this.addMessage('warning', message, durationMilliseconds);
  }

  private addMessage(severity: string, message: string, durationMilliseconds: number = 0) {
    if (durationMilliseconds == 0) {
      durationMilliseconds = this.cfg.getDefaultMessageDuration();
    }

    //durationMilliseconds = 10000;

    const msg = new Message(severity, message, durationMilliseconds);
    this.snackBarSvc.open(AppSnackbarMessageComponent, msg, durationMilliseconds);
  }
}
