import {Component, OnInit} from '@angular/core';
import {AppUserService} from '../../services/app.user.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {RegisterRequest} from '../../models/register-request';
import {DomainConfig} from '../../models/domain-config';
import {ActivatedRoute, Router} from '@angular/router';
import {AppMessageService} from '../../services/app.message.service';
import {AppDialogsService} from '../../services/app.dialogs.service';
import {AppConfigService} from '../../services/app.config.service';
import {AppPageTitleService} from '../../services/app.page-title.service';
import {RegisterSettings} from '../../models/register-settings';
import {MyErrorStateMatcher} from '../../others/my-error-state-matcher';
import {AppMobileService} from '../../services/app.mobile.service';

@Component({
  templateUrl: 'app.register.component.html',
  styleUrls: ['app.register.component.scss']
})
export class AppRegisterComponent implements OnInit {

  req = new RegisterRequest();

  interest_list: {};

  settings: RegisterSettings;

  domaincfg: DomainConfig;

  valid = false;

  valid1 = false;

  state_done = false;
  state_ok = false;
  state_error = false;

  error_company_name = new MyErrorStateMatcher();
  error_company_uid = new MyErrorStateMatcher();
  error_firstname = new MyErrorStateMatcher();
  error_lastname = new MyErrorStateMatcher();
  error_street = new MyErrorStateMatcher();
  error_housenr = new MyErrorStateMatcher();
  error_country = new MyErrorStateMatcher();
  error_plz = new MyErrorStateMatcher();
  error_city = new MyErrorStateMatcher();
  error_telnr = new MyErrorStateMatcher();
  error_password = new MyErrorStateMatcher();
  error_mail = new MyErrorStateMatcher();

  info_ok = false;

  regConfig: { text: string, required: boolean };

  constructor(private route: ActivatedRoute, private router: Router, private svc: AppUserService,
              private msg: AppMessageService, private dialog: AppDialogsService, private cfg: AppConfigService,
              private tsvc: AppPageTitleService, private loader: AppPageloaderService, public msvc: AppMobileService) {

    this.domaincfg = cfg.domainconfig;
  }

  validate() {
    this.sub_validate1();
    this.sub_validateEmail();
  }


  send() {
    const interests = [];
    Object.keys(this.interest_list).forEach(k => {
      if (this.interest_list[k]) {
        interests.push(k);
      }
    });

    this.req.interests = interests;

    this.valid = false;

    const l = this.loader.start();
    this.svc.registerUser(this.req).subscribe({
      next: (uid) => {
        if (uid) {
          this.state_ok = true;
          this.state_error = false;

        } else {
          this.state_error = true;
          this.state_ok = false;
        }
        this.state_done = true;
        l.stop();
      }, error: () => {
        this.state_error = true;
        this.state_ok = false;
        this.state_done = true;
        l.stop();
      }
    });
  }

  resetState() {
    this.state_error = false;
    this.state_ok = false;
    this.state_done = false;
  }

  ngOnInit(): void {
    this.cfg.getRegisterConfig().subscribe(s => {
      this.regConfig = s;
    })
    this.svc.getRegisterSettings().subscribe(s => {
      this.interest_list = {};
      s.interests.forEach(i => {
        this.interest_list[i] = false;
      });
      this.settings = s;

    });
  }

  private check_valid() {
    if (this.valid1 && !this.error_mail.isError) {
      this.valid = true;
    } else {
      this.valid = false;
    }

    if (this.regConfig.required && !this.info_ok && this.regConfig.text != '') {
      this.valid = false;
    }
  }

  private sub_validateEmail() {
    if (this.req && this.req.mail !== undefined) {
      this.error_mail.startLoading();
      if (this.req.mail == '') {
        this.error_mail.setError('Wird benötigt!');
        this.error_mail.stopLoading();

      } else if (!this.req.mail.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
        this.error_mail.setError('Untültige E-Mail Adresse!');
        this.error_mail.stopLoading();

      } else {
        this.svc.testUserExists(this.req.mail).subscribe({
          next: (r) => {
            if (!r.BenutzerExistiert) {
              this.error_mail.setOk();
            } else {
              this.error_mail.setError('Benutzer existiert bereits!');
            }
            this.error_mail.stopLoading();
            this.check_valid();
          }, error: () => {
            this.error_mail.setError('Prüfung fehlgeschlagen!');
            this.check_valid();
          }
        });
      }
    } else {
      this.error_mail.setError('Wird benötigt!');
    }
  }

  private sub_validate1() {
    let state = true;

    if (!this.req.agb) {
      state = false;
    }

    if (!this.req.dsgvo) {
      state = false;
    }

    if (this.req.company_name && this.req.company_name != '') {
      if (this.req.company_name.match(/^[\- A-z\u00C0-\u00ff0-9]*$/)) {
        this.error_company_name.setOk();
      } else {
        this.error_company_name.setError('Es sind unerlaubte Zeichen enthalten. Es wird nur ISO-8859-1 unterstützt.');
        state = false;
      }
    } else {
      this.error_company_name.setRequired();
    }

    if (this.req.company_uid && this.req.company_uid != '') {
      this.error_company_uid.setOk();
    } else {
      this.error_company_uid.setRequired();
      state = false;
    }

    if (this.req.firstname && this.req.firstname != '') {
      if (this.req.firstname.match(/^[\- A-z\u00C0-\u00ff0-9]*$/)) {
        this.error_firstname.setOk();
      } else {
        this.error_firstname.setError('Es sind unerlaubte Zeichen enthalten. Es wird nur ISO-8859-1 unterstützt.');
        state = false;
      }
    } else {
      this.error_firstname.setRequired();
      state = false;
    }

    if (this.req.lastname && this.req.lastname != '') {
      if (this.req.lastname.match(/^[\- A-z\u00C0-\u00ff0-9]*$/)) {
        this.error_lastname.setOk();
      } else {
        this.error_lastname.setError('Es sind unerlaubte Zeichen enthalten. Es wird nur ISO-8859-1 unterstützt.');
        state = false;
      }
    } else {
      this.error_lastname.setRequired();
      state = false;
    }

    if (this.req.street && this.req.street != '') {
      if (this.req.street.match(/^[\- A-z\u00C0-\u00ff0-9]*$/)) {
        this.error_street.setOk();
      } else {
        this.error_street.setError('Es sind unerlaubte Zeichen enthalten. Es wird nur ISO-8859-1 unterstützt.');
        state = false;
      }
    } else {
      this.error_street.setRequired();
      state = false;
    }

    if (this.req.housenr && this.req.housenr != '') {
      if (this.req.housenr.match(/^[\- A-z\u00C0-\u00ff0-9]*$/)) {
        this.error_housenr.setOk();
      } else {
        this.error_housenr.setError('Es sind unerlaubte Zeichen enthalten. Es wird nur ISO-8859-1 unterstützt.');
        state = false;
      }
    } else {
      this.error_housenr.setRequired();
      state = false;
    }

    if (this.req.plz && this.req.plz != '') {
      if (this.req.plz.match(/^[A-z\u00C0-\u00ff0-9]*$/)) {
        this.error_plz.setOk();
      } else {
        this.error_plz.setError('Es sind unerlaubte Zeichen enthalten. Es wird nur ISO-8859-1 unterstützt.');
        state = false;
      }
    } else {
      this.error_plz.setRequired();
      state = false;
    }

    if (this.req.city && this.req.city != '') {
      if (this.req.city.match(/^[\- A-z\u00C0-\u00ff0-9]*$/)) {
        this.error_city.setOk();
      } else {
        this.error_city.setError('Es sind unerlaubte Zeichen enthalten. Es wird nur ISO-8859-1 unterstützt.');
        state = false;
      }
    } else {
      this.error_city.setRequired();
      state = false;
    }

    if (this.req.telnr && this.req.telnr != '') {
      if (this.req.telnr.match(/^[+ A-z\u00C0-\u00ff0-9]*$/)) {
        this.error_telnr.setOk();
      } else {
        this.error_telnr.setError('Es sind unerlaubte Zeichen enthalten. Es wird nur ISO-8859-1 unterstützt.');
        state = false;
      }
    } else {
      this.error_telnr.setRequired();
      state = false;
    }

    if (this.req.password && this.req.password != '') {
      if (this.req.password && this.req.password.match(/^[a-zA-Z0-9+*~#'\-_.,;:!"§$%&\/()=?{}\[\]]{8,30}$/)) {
        this.error_password.setOk();
      } else {
        this.error_password.setError('Ungültig!');
        state = false;
      }
    } else {
      this.error_password.setRequired();
      state = false;
    }

    this.valid1 = state;
    this.check_valid();
  }


}
