import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {GlobalSearchResult} from "../models/global-search-result";
import {AppMenuButtonService} from "./app.menu-button.service";
import {GlobalSearchResultEntry} from "../models/global-search-result-entry";
import {Artikel} from "../models/artikel";
import {map} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class AppSearchService {
  constructor(private http: HttpClient, private mbsvc: AppMenuButtonService) {
  }

  articleSearch(search: string, filterRezeptzeichen = []): Observable<Artikel[]> {
    return this.http.post<Artikel[]>(
      '/search/article',
      {
        search: search,
        filterRezeptzeichen: filterRezeptzeichen
      }
    ).pipe(map(as => {
      if (as) {
        as.forEach(a => {
          Object.setPrototypeOf(a, Artikel.prototype);
        })
      }

      return as;
    }));
  }

  globalSearch(search: string): Observable<GlobalSearchResult[]> {
    return new Observable<GlobalSearchResult[]>(obs => {
      let results1: any;
      let results2: any;

      let check = () => {
        if (results1 && results2) {
          let results = results2.concat(results1);
          obs.next(results);
          obs.complete();
        }
      };

      this.http.post<GlobalSearchResult[]>(
        '/search/global',
        {
          search: search
        }
      ).subscribe(res1 => {
        results1 = res1;
        check();
      });

      let foundMenuEntries = this.mbsvc.searchMainMenuEntries(search);
      let r = new GlobalSearchResult();
      r.label = "Menü Einträge";
      r.description = "Hauptmenü";
      r.icon = 'menue';
      r.entries = [];

      foundMenuEntries.forEach(e => {
        let rr = new GlobalSearchResultEntry();
        rr.label = e.label;

        if (e.view) {
          rr.url = '/shop/'+e.view.slug;
        } else {
          rr.url = e.route;
        }

        if (e.view && e.view.settings && e.view.settings.icon) {
          rr.icon = e.view.settings.icon;
        } else {
          rr.icon = e.icon;
        }

        rr.description = e.description;
        r.entries.push(rr);
      });
      if (r.entries.length > 0) {
        results2 = [r];
      } else {
        results2 = [];
      }
      check();
    });
  }
}
