import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ShopViewResultTableCompontent} from '../view-result-table/shop.view-result-table.compontent';
import {
  SharedViewFieldSelectorComponent
} from "../../../shared/components/view-field-selector/shared.view-field-selector.component";
import {AppMobileService} from "../../../../services/app.mobile.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'shop-view-result-box',
  templateUrl: 'shop.view-result-box.compontent.html',
  styleUrls: ['shop.view-result-box.compontent.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ShopViewResultBoxCompontent extends ShopViewResultTableCompontent {
  column_order = SharedViewFieldSelectorComponent.fields_order_box;

  loadFields() {
    let ucfg = this.vsvc.cfg_gui_box_columns.value;

    let tmp_fields = [];

    if (ucfg && ucfg[this.view.knoten_id] && typeof ucfg[this.view.knoten_id] !== 'undefined') {
      tmp_fields = ucfg[this.view.knoten_id];

    } else {
      tmp_fields = this.view.settings.box_default_fields;
      if (!tmp_fields || tmp_fields.length == 0) {
        tmp_fields = [
          "logo1",
          "logo2",
          "bezeichnung",
          "minablauf",
          "darreichungsform",
          "hersteller",
          "pzn",
          "einkaufspreis",
          "verkaufspreis",
          "rezeptzeichen",
          "istarzneimittel",
          "status",
          "aktionen_button"
        ];
      }
    }

    let active_fields = [];
    this.column_order.forEach(c => {
      if (tmp_fields.includes(c)) {
        active_fields.push(c);
      }
    })
    this.active_fields = active_fields;

    this.updateView(false);
  }

  saveFields() {
    let tmp = this.vsvc.cfg_gui_box_columns.value;
    tmp[this.view.knoten_id] = this.active_fields;
    this.sett.setUserSettingValue('GUI_ShopViewBoxColumns', tmp).subscribe();
  }
}
