import {User} from './user';
import {Subbestellung} from './subbestellung';

export class Bestellung {
  id: number;
  label: string;
  note: string;
  subbestellungen: Subbestellung[];
  shared: boolean;
  locked: boolean;
  agbaccepted: boolean;
  artikel_count: number;
  wert_summe: number;
  owner: boolean;
  owner_user: User;
  send: boolean;
  response: any;
  lieferkunde_nr: string;
  kunde_nr: string;
  hasErrors: boolean;
  valutaDate: string;
  reTage: string;
  liefernAb: string;

  ts_insert: string;


  view_collapsed = true;
}
