import {AppPlatformService} from './app.platform.service';
import {Injectable} from '@angular/core';
import {AppUserService} from './app.user.service';

@Injectable({providedIn: 'root'})
export class AppMemoryService {
  clear_list = [];

  constructor(private ps: AppPlatformService, private usvc: AppUserService) {
  }

  get(key: string, uid = null): any {
    if (this.ps.isBrowser) {
      if (uid) {
        key = uid + '__' + key;
      } else {
        key = this.usvc.user.value.id + '__' + key;
      }

      const tmp = localStorage.getItem(key);
      if (tmp && tmp != 'undefined') {
        return JSON.parse(tmp);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  set(key: string, value: any, clear_after_logout = false, uid = null) {
    if (this.ps.isBrowser) {
      if (uid) {
        key = uid + '__' + key;
      } else {
        key = this.usvc.user.value.id + '__' + key;
      }
      if (clear_after_logout) {
        this.usvc.addUserLocalSessionEntry(key);
      }
      localStorage.setItem(key, JSON.stringify(value));

      return true;
    } else {
      return true;
    }
  }

  has(key: string, uid = null) {
    if (this.ps.isBrowser) {
      if (uid) {
        key = uid + '__' + key;
      } else {
        key = this.usvc.user.value.id + '__' + key;
      }
      if (localStorage.getItem(key)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  delete(key: string, uid = null) {
    if (this.ps.isBrowser) {
      if (uid) {
        key = uid + '__' + key;
      } else {
        key = this.usvc.user.value.id + '__' + key;
      }
      localStorage.removeItem(key);
      return true;
    } else {
      return true;
    }
  }
}
