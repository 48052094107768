<section-title [hasBack]="true" [label]="'Kunden-Einstellungen'" icon="einstellungen"></section-title>

<div class="paddedcontent">
  <div class="mat-elevation-z2 settingsbox">
    <button (click)="save()" [disabled]="!saveReady" mat-button *ngIf="loadReady">
      <svg-icon>speichern</svg-icon>
      Speichern
    </button>
    <settings-box [settings]="settings" [(saveReady)]="saveReady" [(value)]="value" [settings_if_parent]="this"
                  (settingsLoaded)="loadReady = $event"></settings-box>

    <div *ngIf="!loadReady" style="text-align: center; padding: 20px">
      <div style="display: inline-block">
        <mat-spinner></mat-spinner>
        <h3 style="margin-top: 20px">Lade...</h3>
      </div>
    </div>
  </div>
</div>
