<router-outlet (activate)="subRouteActivate()" (deactivate)="subRouteDeactivate()"></router-outlet>

<div *ngIf="(view|async)" class="tut_shopview" [class.hidden]="sub_route_active">
  <section-title
    [label]="((view|async)?.settings && (view|async)?.settings.label) ? (view|async)?.settings.label : (view|async)?.label"
    [hasBack]="true" icon="{{(view|async)?.getIcon()}}">
    <warenkorb-import-ticker></warenkorb-import-ticker>
    <div rightbuttons style="text-align: right">
      <bonus-punkte-icons></bonus-punkte-icons>

      <mat-button-toggle-group>
        <mat-button-toggle (click)="setTableLayout()"
                           [disabled]="(isMobile|async) ? ((layoutMobile|async) == 'table') : ((layout|async) == 'table')"
                           class="tut_shopview_view_tab"
                           matTooltip="Tabellen-Ansicht">
          <svg-icon>tabellarische-ansicht</svg-icon>
        </mat-button-toggle>

        <mat-button-toggle (click)="setBoxLayout()"
                           [disabled]="(isMobile|async) ? ((layoutMobile|async) == 'box') : ((layout|async) == 'box')"
                           matTooltip="Box-Ansicht"
                           class="tut_shopview_view_box">
          <svg-icon>box-ansicht</svg-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </section-title>

  <div class="view_contact_button" [class.mobile]="isMobile|async">
    <contact-button [view]="view|async"></contact-button>
  </div>

  <mat-drawer-container [hasBackdrop]="false">
    <mat-drawer mode="side" #drawer *ngIf="(view|async)?.settings && (view|async)?.settings.ajax_warenkorb_enabled"
                [opened]="drawer_state_opened|async">
      <div class="tut_shopview_ajaxwk">
        <ajax-warenkorb></ajax-warenkorb>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <div class="">
        <div *ngIf="(view|async)?.settings && (view|async)?.settings.ajax_warenkorb_enabled">
          <button mat-raised-button (click)="openDrawer()" *ngIf="!(drawer_state_opened|async)"
                  matTooltip="Warenkorb ausklappen" class="tut_shopview_ajaxwktoggle">
            <svg-icon>weiter</svg-icon>
          </button>
          <button mat-raised-button (click)="closeDrawer()" *ngIf="(drawer_state_opened|async)"
                  matTooltip="Warenkorb einklappen" class="tut_shopview_ajaxwktoggle">
            <svg-icon>zurueck</svg-icon>
          </button>
        </div>
        <div style="height: 5px; position: absolute; width: 100%">
          <mat-progress-bar *ngIf="(loading$|async)" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="shop-view-filterbox mat-elevation-z1" #filterbox>

          <div class="shop-view-filterbox-inner" [class.mobile]="isMobile|async">
            <div *ngIf="(view|async)?.filters.length > 0" class="shop-view-filterbox-filter tut_shopview_filter">
              <button mat-button class="buttonstyle2" [class.active]="active_filters.length > 0" (click)="showFilter()">
                Filter
                <div style="display: inline-block; vertical-align: top; width: 20px">
                  <span *ngIf="active_filters.length > 0">({{active_filters.length}})</span>
                </div>
              </button>
              <button mat-raised-button class="buttonstyle2" (click)="resetsearch()"
                      [disabled]="active_filters.length <= 0" matTooltip="Filter zurücksetzen">
                <svg-icon>zuruecksetzen</svg-icon>
              </button>
            </div>

            <div [class.borderleft]="(view|async)?.filters.length > 0" class="shop-view-searchbox"
                 style="vertical-align: top">
              <div>
                <search-box [(value)]="search" (search)="query()" [scannerEnabled]="true"
                            [reset_triggers_search]="false" [infoTextInNewline]="isMobile|async"
                            [infoText]="'Suche mit * möglich!'"></search-box>
              </div>
            </div>

            <warenkorb-bestellblock-button [viewComp]="this" style="vertical-align: top; margin-left: 10px;"
                                           *ngIf="(view|async)?.settings.bestellblock_mode"></warenkorb-bestellblock-button>
          </div>
        </div>

        <div class="result">
          <ngx-simplebar #view_scrollbox class="view-scroll"
                         [style.height]="(current_viewscroll_height|async) ? (current_viewscroll_height|async)+'px' : null"
                         [class.with_ajax_wk]="(view|async)?.settings.ajax_warenkorb_enabled">
            <div *ngIf="entries|async" class="view_content {{fsize}}">
              <div *ngIf="(total_count|async) == 0 && !(loading$|async)" style="clear: both"></div>
              <div *ngIf="(total_count|async) == 0 && !(loading$|async)" class="infobox">
                <h3>Keine Ergebnisse gefunden!</h3>
              </div>
              <div *ngIf="(total_count|async) == 0 && (loading$|async)" style="clear: both"></div>
              <div *ngIf="(total_count|async) == 0 && (loading$|async)" class="infobox">
                <div style="text-align: center">
                  <div style="display: inline-block">
                    <mat-spinner></mat-spinner>
                  </div>
                </div>
                <h3>Suche läuft!</h3>
              </div>
              <shop-view-result-table
                *ngIf="(total_count|async) > 0 && (isMobile|async) ? ((layoutMobile|async) == 'table') : ((layout|async) == 'table')"
                [entries]="entries"
                [view]="view|async"
                [viewcomp]="this"
                [infoText]="(loading$|async) ? 'Lade...' : ((total_count|async)+' Artikel')"
                [visible_artikel_aktionen]="visible_artikel_aktionen"
              ></shop-view-result-table>
              <shop-view-result-box
                *ngIf="(total_count|async) > 0 && (isMobile|async) ? ((layoutMobile|async) == 'box') : ((layout|async) == 'box')"
                [entries]="entries"
                [view]="view|async"
                [viewcomp]="this"
                [infoText]="(loading$|async) ? 'Lade...' : ((total_count|async)+' Artikel')"
                [visible_artikel_aktionen]="visible_artikel_aktionen"
              ></shop-view-result-box>
            </div>

            <div *ngIf="!(entries|async) && !(loading$|async)" class="view_content">
              <div style="clear: both"></div>
              <div class="infobox">
                <h3>Bitte Suche oder Filter nutzen!</h3>
              </div>
            </div>
          </ngx-simplebar>
          <div style="clear: both"></div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>


</div>

