

<span class="dot {{color}}{{showTooltip ? ' helpcursor':''}}" style="vertical-align: middle"
      matTooltip="{{showTooltip ? artikel.VerfuegbarkeitsstatusText:null}}"></span>

<div class="lginfo" *ngIf="artikel.bestandSum !== undefined" [class.hasMore]="artikel.bestand">
  {{artikel.bestandSum}} Stk.

  <div class="lgdetails mat-elevation-z1" *ngIf="artikel.bestand">
    <div class="header">Lagerstände</div>
    <table>
      <tr *ngFor="let bs of artikel.bestand">
        <td>
          {{bs.Betriebsstaette.Bezeichnung}}
        </td>
        <td style="text-align: right">
          {{bs.VBestand}} Stk.
        </td>
      </tr>
    </table>
  </div>
</div>
