import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {FavMenuButtonService} from "../../../main-menu/services/fav-menu-button.service";
import {MainMenuButtonConfig} from '../../../../models/main-menu-button-config';
import {AppMenuButtonService} from '../../../../services/app.menu-button.service';
import {takeUntil} from "rxjs/operators";
import {AppDialogsService} from "../../../../services/app.dialogs.service";

@Component({
  selector: 'app-dashboard-widgets-favourite-menu-buttons',
  templateUrl: 'dashboard.widgets.favourite-menu-buttons.component.html',
  styleUrls: ['dashboard.widgets.favourite-menu-buttons.component.scss'],
})
export class DashboardWidgetsFavouriteMenuButtonsComponent implements OnInit, OnDestroy {
  static id = 'DashboardWidgetsFavouriteMenuButtonsComponent';
  static label = 'Schnellzugriff';
  static description = 'Zeigt Ihre Favoriten an.';
  static cols = 6;
  static rows = 6;

  _destroy = new Subject();

  buttons = new BehaviorSubject<MainMenuButtonConfig[]>([]);


  favids: any;


  constructor(private favbsvc: FavMenuButtonService, private mbsvc: AppMenuButtonService, private dsvc: AppDialogsService) {
  }

  ngOnInit(): void {
    this.favbsvc.favIds.pipe(takeUntil(this._destroy)).subscribe(favids => {
      this.favids = favids;
      this.renderButtons();
    });
    this.mbsvc.mainMenuConfig.pipe(takeUntil(this._destroy)).subscribe(vmb => {
      this.renderButtons();
    });
  }

  reset() {
    this.dsvc.confirm('zuruecksetzen', 'Auf Standard zurücksetzen', 'Wollen Sie den Schnellzugriff zurücksetzen?', () => {
      this.favbsvc.reset();
    });
  }

  renderButtons() {
    if (this.favids) {
      const all = this.mbsvc.all();

      const buttons = [];

      this.favids.forEach(id => {
        all.forEach((b) => {
          if (b.id == id) {
            buttons.push(b);
          }
        });
      });

      this.buttons.next(buttons);
    }
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }
}
