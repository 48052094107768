<div #walkthroughcomponent (click)="onCloseClicked($event)" [hidden]="!isVisible"
     [ngClass]="{'walkthrough-active': isVisible}" class="{{DOM_WALKTHROUGH_CLASS}}">
  <div [hidden]="walkthroughType!=='transparency'" class="walkthrough-container walkthrough-container-transparency">
    <div class="walkthrough-inner">
      <div class="{{DOM_TRANSCLUDE}}">
        <ng-content select="img"></ng-content>
      </div>
      <div [hidden]="hasTransclude" class="walkthrough-non-transclude-template">
        <div [ngClass]="{'walkthrough-top': (!forceCaptionLocation || forceCaptionLocation==='TOP'), 'walkthrough-bottom': forceCaptionLocation==='BOTTOM'}"
             class="walkthrough-text-container">
          <pre [innerHTML]="mainCaption" class="walkthrough-element walkthrough-text"></pre>
          <img (click)="onWalkthroughContentClicked()" *ngIf="walkthroughHeroImage"
               class="walkthrough-element walkthrough-hero-image" src="{{walkthroughHeroImage}}">
        </div>
        <img [hidden]="walkthroughIconWanted && walkthroughIconWanted==='arrow'"
             class="walkthrough-element walkthrough-icon" src="{{walkthroughIcon}}">
        <div [hidden]="walkthroughIconWanted!=='arrow'" class="walkthrough-element walkthrough-arrow"></div>
        <button (click)="onCloseClicked($event)" *ngIf="useButton"
                class="walkthrough-element walkthrough-button-positive walkthrough-done-button" type="button">
          {{buttonCaption}}
        </button>
      </div>
    </div>
  </div>
  <div [hidden]="walkthroughType!=='tip'" class="walkthrough-container walkthrough-container-tip">
    <div [ngClass]="{'walkthrough-top': ((!forceCaptionLocation && !tipIconLocation) || forceCaptionLocation==='TOP' || tipIconLocation =='TOP'), 'walkthrough-bottom': (forceCaptionLocation=='BOTTOM' || tipIconLocation =='BOTTOM')}"
         class="walkthrough-inner">
      <img [hidden]="walkthroughIconWanted && walkthroughIconWanted==='arrow'"
           [ngClass]="{'walkthrough-tip-icon-image-front': tipIconLocation==='FRONT', 'walkthrough-tip-icon-image-back': tipIconLocation=='BACK'}"
           alt="icon" class="walkthrough-element walkthrough-tip-icon-text-box" src="{{walkthroughIcon}}">
      <button (click)="onCloseClicked($event)"
              *ngIf="useButton"
              [ngClass]="{'walkthrough-tip-done-button-no-icon': !icon}" class="walkthrough-done-button walkthrough-tip-done-button-text-box" type="button">
        <img alt="x" class="walkthrough-tip-button-image-text-box" src="{{closeIcon}}">
      </button>
      <div (click)="onWalkthroughContentClicked()" [ngClass]="{'walkthrough-tip-text-box-color-black': tipColor=='BLACK', 'walkthrough-tip-text-box-color-white': tipColor=='WHITE'}"
           class="walkthrough-element walkthrough-tip-text-box">
        <pre [innerHTML]="mainCaption"></pre>
        <img *ngIf="walkthroughHeroImage" class="walkthrough-element walkthrough-hero-image"
             src="{{walkthroughHeroImage}}">
        <div class="{{DOM_TRANSCLUDE}}">
          <ng-content select="img"></ng-content>
        </div>
      </div>
    </div>
  </div>
  <div (click)="onWalkthroughContentClicked()" [hidden]="!hasBackdrop" [ngClass]="{'walkthrough-hole-round': isRound}"
       class="walkthrough-hole">
  </div>
  <div [hidden]="!(hasGlow && (focusElementSelector))" [ngClass]="{'walkthrough-hole-round': isRound}"
       class="walkthrough-hole walkthrough-hole-glow">
  </div>
</div>
