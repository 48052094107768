<div style="display: inline-block; position: relative">
  <button (mouseenter)="show()" (mouseleave)="hide()" *ngIf="lieferkunde"
          class="warenkorb-menu-cart-button tut_navbar_wkbutton"
          mat-button
          routerLink="{{warenkorb_link}}">
    <svg-icon matBadge="{{article_count}}" matBadgeSize="large">warenkorb</svg-icon>
  </button>

  <div (mouseenter)="show2()" (mouseleave)="hide2()" *ngIf="warenkoerbe" [class.hidden]="!open && !open2"
       class="menu mat-elevation-z1" [class.mobile]="msvc.isMobile()|async">
    <table>
      <tr>
        <th></th>
        <th *ngIf="warenkoerbe.length > 1" style="text-align: left">Bezeichnung</th>
        <th style="padding: 2px">Positionen</th>
        <th style="padding: 2px">Einkaufswert</th>
      </tr>
      <tr (click)="navigateTo('/shop/warenkorb/'+wk.id)" *ngFor="let wk of warenkoerbe; let first = first; let last=last;"
          class="buttontr" [class.default]="!last && !first" [class.last]="last" [class.first]="first">
        <td>
          <svg-icon *ngIf="primary_warenkorb && primary_warenkorb.id == wk.id" matTooltip="Haupt-Warenkorb"
                    style="font-size: 12px; height: 12px">radiobutton-gefuellt
          </svg-icon>
          <svg-icon *ngIf="primary_warenkorb && primary_warenkorb.id != wk.id" style="font-size: 12px; height: 12px">
            radiobutton-leer
          </svg-icon>
        </td>
        <td *ngIf="warenkoerbe.length > 1" style="text-align: left">
          {{wk.label}}
        </td>
        <td>
          {{wk.artikel_count}}
        </td>
        <td style="text-align: right">
          {{wk.wert_summe|NiceNumber}} &euro;
        </td>
      </tr>
    </table>
    <a routerLink="/shop/warenkorb">Zu den Warenkörben</a>
  </div>
</div>
