import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {Condition} from '../modules/admin/model/condition';
import {AppUserService} from './app.user.service';
import {map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {AppEventService} from './app.event.service';

@Injectable({providedIn: 'root'})
export class AppConditionsService {
  open_conditions = new BehaviorSubject<Condition[]>(undefined);

  constructor(private http: HttpClient, private cfg: AppConfigService, private usvc: AppUserService, private esvc: AppEventService) {
    this.usvc.user$.subscribe(u => {
      this.load();
    });

    this.esvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\ConditionEngine\\ConditionChangedEvent') {
        this.load();
      }
    });
  }

  load() {
    this.getForCustomer().subscribe(cs => {
      this.open_conditions.next(cs);
    });
  }

  getForCustomer() {
    return this.http.get<Condition[]>(
      '/condition/getall'
    );
  }

  acceptConditions(c: Condition) {
    const me = this;
    return this.http.get<boolean>(
      '/condition/' + c.matchcode + '/accept'
    ).pipe(map((result: boolean) => {
      this.load();
      return result;
    }));
  }

  declineConditions(c: Condition) {
    return this.http.get<boolean>(
      '/condition/' + c.matchcode + '/decline'
    ).pipe(map((result: boolean) => {
      this.load();
      return result;
    }));
  }

  orderupConditions(c: Condition) {
    const me = this;
    return this.http.get<boolean>(
      '/admin/condition/orderup/' + c.id
    ).pipe(map((result: boolean) => {
      this.load();
      return result;
    }));
  }

  orderdownConditions(c: Condition) {
    const me = this;
    return this.http.get<boolean>(
      '/admin/condition/orderdown/' + c.id
    ).pipe(map((result: boolean) => {
      this.load();
      return result;
    }));
  }

  getAllConditions() {
    return this.http.get<Condition[]>(
      '/admin/condition/getall'
    );
  }

  getConditionById(id: string) {
    return this.http.get<Condition>(
      '/admin/condition/get/' + id
    );
  }

  saveCondition(condition: Condition) {
    return this.http.post<Boolean>(
      '/admin/condition/update/' + condition.id,
      {
        post_condition: condition
      }
    );
  }
}
