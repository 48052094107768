import {Datamatrix} from "./datamatrix";

export class GS1DataMatrix implements Datamatrix{
    '01': any;
    '10': any;
    '17': any;
    '21': any;
    GTIN: any;
    LOT: any;
    EXP: any;
    SN: any;

    render(){
        if (this['01']) {
            this.GTIN = this['01'];
        }
        if (this['10']) {
            this.LOT = this['10'];
        }
        if (this['17']) {
            this.EXP = this['17'];
        }
        if (this['21']) {
            this.SN = this['21'];
        }

        if (this.EXP) {
            let date = this.EXP.match(/^([0-9]{2})([0-9]{2})([0-9]{2})$/);
            this.EXP = date[3] + '.' + date[2] + '.' + '20' + date[1];
        }
    }

    getType() {
        return "GS1";
    }

    getBarcodes(): string[] {
        return [this.GTIN];
    }

    getExp(): string {
        return this.EXP;
    }

    getSN(): string {
        return this.SN;
    }

    getLOT(): string {
        return this.LOT;
    }
}