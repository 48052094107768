import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'unitconverter_g'})
export class SharedUnitconverterGPipe implements PipeTransform {
  transform(value: number): string {
    if (value > 1000) {
      value = value / 1000;

      return value + ' kg';

    } else {
      return value + ' g';
    }
  }
}
