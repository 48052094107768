import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppCustomerSurveyService} from '../../services/app.customer-survey.service';
import {AppEventService} from '../../services/app.event.service';
import {AppUserService} from '../../services/app.user.service';
import {Subscription} from 'rxjs';
import {AppDialogsService} from '../../services/app.dialogs.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {CustomerSurvey} from '../../models/customer-survey';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {AppMessageService} from '../../services/app.message.service';
import {AppDialogsCustomerSurveyComponent} from '../../dialogs/customer-survey/app.dialogs.customer-survey.component';

@Component({
  selector: 'customer-service-watcher',
  template: ''
})
export class AppCustomerSurveyWatcherComponent implements OnInit, OnDestroy {
  usub: Subscription;
  esub: Subscription;


  constructor(private svc: AppCustomerSurveyService, private esvc: AppEventService, private usvc: AppUserService,
              private dsvc: AppDialogsService, private router: Router, private dialog: MatDialog,
              private loader: AppPageloaderService, private msg: AppMessageService) {
  }

  openSurvey(s: CustomerSurvey) {
    const ref = this.dialog.open(AppDialogsCustomerSurveyComponent, {
      data: s
    });
    ref.afterClosed().subscribe((reload) => {
      if (reload) {
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.usub instanceof Subscription) {
      this.usub.unsubscribe();
    }
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }
  }

  load() {
    this.svc.getMine().subscribe(s => {
      if (s) {
        this.dsvc.ask_survey(
          'Hätten Sie einen Augenblick Zeit?',
          'Wir hätten eine Umfrage für Sie. <br> Wären Sie so nett und würden daran teilnehmen?',
          () => {
            this.openSurvey(s);
          },
          () => {
            const l = this.loader.start();
            this.svc.postpone1(s).subscribe(ss => {
              this.msg.info('Danke!');
              l.stop();
            });

          },
          () => {
            const l = this.loader.start();
            this.svc.postpone2(s).subscribe(ss => {
              this.msg.info('Danke!');
              l.stop();
            });

          },
          () => {
            const l = this.loader.start();
            this.svc.decline(s).subscribe(ss => {
              l.stop();
              this.load();
            });
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.esub = this.esvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\CustomerSurveyEngine\\SurveyChangedEvent') {
        this.load();
      }
    });

    this.usub = this.usvc.user$.subscribe(u => {
      this.load();
    });
  }


}
