import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NewsEntry} from '../../../../models/news_entry';
import {AppNewsService} from '../../../../services/app.news.service';
import {User} from '../../../../models/user';
import {AppUserService} from '../../../../services/app.user.service';
import {Subscription} from 'rxjs';
import {AppConfigService} from '../../../../services/app.config.service';

@Component({
  selector: 'app-dashboard-widgets-news-entry',
  templateUrl: 'dashboard.widgets.news.entry.component.html',
  styleUrls: ['dashboard.widgets.news.entry.component.scss']
})
export class DashboardWidgetsNewsEntryComponent implements OnInit, OnDestroy {
  static id = 'DashboardWidgetsNewsEntryComponent';
  @Input() entry: NewsEntry;
  @Input() tags: string[];
  @Input() tag_colors: {};

  user: User;

  usub: Subscription;
  visible = false;

  img_base_path = '';

  constructor(private svc: AppNewsService, private usvc: AppUserService, private cfg: AppConfigService) {
    this.img_base_path = cfg.buildUrl('/news/get');
  }

  ngOnInit(): void {
    this.usub = this.usvc.user$.subscribe(u => {
      if (this.entry instanceof NewsEntry && this.entry.is_new) {
        this.svc.setSeen(this.entry).subscribe(() => {
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.usub instanceof Subscription) {
      this.usub.unsubscribe();
    }
  }

  toggle() {
    if (this.visible) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  toggleLike() {
    this.svc.toggleLike(this.entry).subscribe((r) => {
      this.entry.ilike = r.ilike;
      this.entry.likes = r.likes;
    });
  }
}
