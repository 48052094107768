<div class="news-widget-entry-header">
  <table>
    <tr>
      <td rowspan="2">
        <span class="label">
          {{entry.label}}
        </span>

        <span class="new" *ngIf="entry.is_new" matTooltip="NEU">
          <svg-icon>news-neu</svg-icon>
        </span>
      </td>
      <td class="time">
        <svg-icon size="12">auftragsplaner</svg-icon>
        {{entry.ts_insert}}
      </td>
    </tr>
    <tr>
      <td>
        <div class="tagsbox">
          <span *ngFor="let tag of entry.tags" [style.color]="tag_colors[tag.id]">{{tag.name}}</span>
        </div>
      </td>
    </tr>
  </table>
</div>
<div class="content">
  <table>
    <tr>
      <td *ngIf="entry.has_picture" class="img" rowspan="2">
        <img src="{{img_base_path}}/{{entry.id}}/teaser.png" />
      </td>
      <td class="text" colspan="2">
        <div [innerHTML]="entry.teaser|raw"></div>
      </td>
    </tr>
    <tr>
      <td style="vertical-align: bottom; padding-bottom: 10px" colspan="2">
        <a *ngIf="entry.has_more" routerLink="/news/{{entry.id}}" style="margin-top: 5px">
          weiterlesen
        </a>
      </td>
    </tr>
    <tr>
      <td style="text-align: right; vertical-align: bottom" colspan="2">
        <span *ngIf="entry.ts_seen">
          <small>
            gesehen: {{entry.ts_seen}}
          </small>
        </span>
        <span>
          <button mat-button class="likesbutton" (click)="toggleLike()" [class.liked]="entry.ilike" matTooltip="{{entry.likes}} Likes">
            <svg-icon *ngIf="entry.ilike">liked</svg-icon>
            <svg-icon *ngIf="!entry.ilike">like</svg-icon> {{entry.likes}}
          </button>
        </span>
      </td>
    </tr>
  </table>
</div>
