export class DomainVertreterConfig {
  DomainVertreterUseDialog: boolean;
  DomainVertreterShowFreimenge: boolean;
  DomainVertreterShowAktionsrabattmenge: boolean;
  DomainVertreterShowAktionsrabattprozent: boolean;
  DomainVertreterShowNaturalrabattmenge: boolean;
  DomainVertreterShowBasisverrechnungspreis: boolean;
  DomainVertreterShowBasisverrechnungspreisRabattProzent: boolean;
  DomainVertreterShowPositionsart: boolean;
  DomainVertreterShowLkndInHead: boolean;
  DomainVertreterShowReTage: boolean;
  DomainVertreterShowValutaDatum: boolean;
  DomainVertreterShowLiefernAbDatum: boolean;
  DomainVertreterOrderCopyMailPersistPerCustomer: boolean;
  HasFields: boolean;
}
