import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {StatisticsArtikelEinkaufComponent} from "./components/artikel-einkauf/statistics.artikel-einkauf.component";
import {
  StatisticsArtikelEinkaufViewComponent
} from "./components/artikel-einkauf-view/statistics.artikel-einkauf-view.component";


@NgModule({
  declarations: [
    StatisticsArtikelEinkaufComponent,
    StatisticsArtikelEinkaufViewComponent,
  ],
  exports: [
    StatisticsArtikelEinkaufComponent,
    StatisticsArtikelEinkaufViewComponent,
  ],
  imports: [
    SharedModule,
  ],
  providers: []
})
export class StatisticsComponentsModule {
}
