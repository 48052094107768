<!--<div class="loaderbox" *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>-->

<div *ngIf="offline || apioffline || hasText" class="fullscreenloader">

  <div class="inner">
    <div class="logo">
      <img src="assets/images/logos/default.png"/>
    </div>
    <div *ngIf="apioffline && !offline && !hasText" class="text">
      Server <br> derzeit nicht erreichbar
    </div>
    <div *ngIf="offline && !hasText" class="text">
      Derzeit offline. <br> Bitte Internetverbindung prüfen!
    </div>
    <div *ngIf="hasText && loading_title" class="text">
      {{loading_title}}
    </div>
    <br>

    <div class="loading">
            <span *ngIf="hasText && loading_text">
                {{loading_text}}
            </span>
      <span *ngIf="!hasText || !loading_text">
                Warte
            </span>
      <span class="d">.</span><span class="d d-2">.</span><span class="d d-3">.</span>
    </div>
  </div>
</div>
