import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {AppEventService} from '../../../services/app.event.service';
import {AppSettingsService} from '../../../services/app.app_settings.service';

@Injectable({providedIn: 'root'})
export class FavMenuButtonService {
  favIds = new BehaviorSubject([]);

  constructor(private http: HttpClient, private esvc: AppEventService, private sett: AppSettingsService) {
    esvc.getQueue().subscribe(e => {
      if (e.name == 'impersonate_end' || e.name == 'depersonate_end') {
        this.load();
      }
    });
    this.load();
  }

  rem(id: string) {
    const ids = this.favIds.value;
    const idx = ids.indexOf(id);
    ids.splice(idx, 1);
    this.save(ids);
  }

  add(id: string) {
    const ids = this.favIds.value;
    ids.push(id);
    this.save(ids);
  }

  private save(ids) {
    this.sett.setUserSettingValue('GUI_FavMenuButtons', ids).subscribe();
    this.favIds.next(ids);
  }

  reset() {
    this.sett.clearUserSettingValue('GUI_FavMenuButtons').subscribe(r => {
      this.load();
    });
  }

  private load() {
    this.sett.getUserSettingValue('GUI_FavMenuButtons').subscribe(ids => {
      this.favIds.next(ids);
    });
  }

}
