import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ShopViewFilterComponent} from '../view-filter/shop.view-filter.component';
import {MatCheckbox} from '@angular/material/checkbox';
import {ViewFilter} from '../../../../models/view-filter';

@Component({
  selector: 'shop-view-filter-menu',
  templateUrl: 'shop.view-filter-menu.component.ts.html',
  styleUrls: ['shop.view-filter-menu.component.ts.scss']
})
export class ShopViewFilterMenuComponent implements OnInit, OnDestroy {
  @Input() filter: ViewFilter;
  @Output() filterChange = new EventEmitter<ViewFilter>();
  @Output() change = new EventEmitter();
  @Input() filtercomp: ShopViewFilterComponent;

  open = false;

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.doChange();
  }

  toggle() {
    if (this.filter.active) {
      this.filter.active = false;
    } else {
      this.filter.active = true;
    }
    this.doChange();
  }


  doChangeAll() {
    if (this.filter.active) {
      this.filter.children.forEach(f => {
        f.active = true;
      });
    } else {
      this.filter.children.forEach(f => {
        f.active = false;
      });
    }
    this.doChange();
  }


  doChange(child=null) {
    if (child) {
      this.filter.toggleKnotenId(child.knoten_id);
    }
    this.filter.checkActiveKnoten();
    this.filterChange.emit(this.filter);
    this.change.emit();
  }

  markFilter(event, elem: MatCheckbox) {
    event.stopPropagation();
    elem._onInputClick();
  }
}
