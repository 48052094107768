import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUserService} from '../../services/app.user.service';
import {AppMessageService} from '../../services/app.message.service';
import {AppDialogsService} from '../../services/app.dialogs.service';
import {AppConfigService} from '../../services/app.config.service';
import {AppPageTitleService} from '../../services/app.page-title.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DomainConfig} from '../../models/domain-config';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../validators/custom-validators';

@Component({
  // moduleId: module.id,
  selector: 'app-change-password',
  templateUrl: 'app.change-password.component.html',
  styleUrls: ['app.change-password.component.scss']
})
export class AppChangePasswordComponent implements OnInit, OnDestroy {
  static id = 'AppChangePasswordComponent';
  email: string;
  token: string;

  password: string;
  confirmPassword: string;

  loading = new BehaviorSubject<boolean>(false);

  domaincfg: DomainConfig;

  rsub: Subscription;

  error = new BehaviorSubject<string>(null);
  message = new BehaviorSubject<string>(null);

  public frmgrp: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private usvc: AppUserService,
              private msg: AppMessageService, private dialog: AppDialogsService, private cfg: AppConfigService,
              private tsvc: AppPageTitleService, private loader: AppPageloaderService) {
    this.domaincfg = cfg.domainconfig;
  }

  createForm() {
    return this.fb.group(
      {
        // email is required and must be a valid email email
        password: [
          null,
          Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {hasNumber: true}),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(/[@$!%*#?&.:_\-(){}\[\]]/, { hasSpecialCharacters: true }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8),
            Validators.maxLength(30),
          ])],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  send() {
    const l = this.loader.start();

    this.usvc.changePassword(this.frmgrp.get('password').value).subscribe(s => {
      if (s) {
        this.message.next('Erfolgreich geändert!');
        this.error.next(null);
      } else {
        this.error.next('Fehlgeschlagen!');
        this.message.next(null);
      }

      l.stop();
    })
    ;
  }

  ngOnDestroy(): void {
    this.tsvc.resetTitle();
  }

  ngOnInit(): void {
    this.tsvc.setTitle('Passwort ändern');
    this.frmgrp = this.createForm();
  }


}
