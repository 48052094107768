import {DomSanitizer} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';
import {AppConfigService} from '../../../services/app.config.service';

@Pipe({name: 'PhZNr'})
export class SharedPipePhznrPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private cfg: AppConfigService) {
  }

  transform(value: any): any {
    return this.pad(value, 7);
  }

  private pad(num, size) {
    if (num) {
      num = num.toString();
      while (num.length < size) {
        num = '0' + num;
      }
    }
    return num;
  }


}
