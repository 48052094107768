import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppEventService} from '../../../../services/app.event.service';
import * as moment from 'moment';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AppCrontaskService} from '../../../../services/app.crontask.service';
import {AppSettingsService} from '../../../../services/app.app_settings.service';

@Component({
  selector: 'app-dashboard-widgets-imp-warenkorb-ticker',
  templateUrl: 'dashboard.widgets.imp-warenkorb-ticker.component.html',
  styleUrls: ['dashboard.widgets.imp-warenkorb-ticker.component.scss'],
})
export class DashboardWidgetsImpWarenkorbTickerComponent implements OnInit, OnDestroy {
  static id = 'DashboardWidgetsImpWarenkorbTickerComponent';
  static label = 'Import Ticker';
  static cols = 3;
  static rows = 6;
  static description = 'Zeigt Ihnen einen Countdown bis zum nächsten Import-Abschluss an.';


  next_cron_import_run = new BehaviorSubject<string>(null);
  next_cron_import_diff = new BehaviorSubject<string>(null);
  cron_import_enabled = new BehaviorSubject<boolean>(false);

  esub: Subscription;

  next_moment: any;

  interval: any;

  constructor(private ctsvc: AppCrontaskService, private settsvc: AppSettingsService, private esvc: AppEventService) {
  }

  private pad(num, size) {
    num = num.toString();
    while (num.length < size) {
      num = '0' + num;
    }
    return num;
  }

  loadSettings() {
    this.settsvc.getCustomerSettingValue('ShopImportAutosend').subscribe(s => {
      this.cron_import_enabled.next(s);
    });
  }

  loadImpCronTime() {
    this.ctsvc.getnextTimeWKImportAutosendTask().subscribe(t => {
      this.next_moment = moment(t, 'DD.MM.YYYY H:i:s');
      this.next_cron_import_run.next(t);
      this.calcDiff();
    });
  }

  private secondsToString(seconds) {
    const numyears = Math.floor(seconds / 31536000);
    const numdays = Math.floor((seconds % 31536000) / 86400);
    const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    const numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    const string = [];
    if (numyears > 0) {
      string.push(numyears + ' J');
    }
    if (numdays > 0) {
      string.push(numdays + ' T');
    }
    string.push(this.pad(numhours, 2) + ':');
    string.push(this.pad(numminutes, 2) + ':');
    string.push(this.pad(numseconds, 2));
    return string.join('');
  }

  calcDiff() {
    if (this.next_moment) {
      const now = moment();
      const diff = this.next_moment.diff(now, 'seconds');

      this.next_cron_import_diff.next(this.secondsToString(diff));
    }
  }

  ngOnInit(): void {
    this.loadImpCronTime();
    this.loadSettings();

    this.interval = setInterval(() => {
      this.calcDiff();
    }, 1000);

    this.esub = this.esvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\SettingsEngine\\SettingsChangedEvent') {
        if (e.data.id == 'App\\Model\\Settings\\Definition\\Customer\\ShopImportAutosend') {
          this.loadSettings();
        }
      } else if (e.name == 'App\\Events\\TaskEngine\\AllUserTaskRunEvent' && e.data && e.data.tasks) {
        e.data.tasks.forEach(t => {
          if (t.name && t.name == 'App\\AsyncTasks\\WKImportAutosendTask') {
            this.loadImpCronTime();
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
