import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ShopViewComponent} from '../view/shop.view.component';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';
import {Router} from "@angular/router";
import {AppEventService} from "../../../../services/app.event.service";
import {BehaviorSubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'warenkorb-bestellblock-button',
  templateUrl: 'shop.warenkorb-bestellblock-button.component.html',
  styleUrls: ['shop.warenkorb-bestellblock-button.component.scss']
})
export class ShopWarenkorbBestellblockButtonComponent implements OnInit, OnDestroy {
  @Input() viewComp: ShopViewComponent;

  artCount = new BehaviorSubject(0);

  _destroy = new Subject();

  constructor(public wksvc: AppWarenkorbService, public router: Router, private esvc: AppEventService) {
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  ngOnInit(): void {
    this.esvc.getQueue()
      .pipe(takeUntil(this._destroy)).subscribe(e => {
      if (e.name == 'gui.view_article_changed') {
        let count = 0;
        this.viewComp.actioncomps.forEach(c => {
          if (c.entry.menge > 0) {
            count++;
          }
        });
        this.artCount.next(count);
      }
    });

  }

  addAll() {
    this.viewComp.addAll();
  }

  addAllAndSend() {
    this.viewComp.addAll(() => {
      let pid = this.wksvc.primary_warenkorb.value.id;

      this.router.navigateByUrl('/shop/warenkorb/' + pid + '/checkout');
    });
  }

}
