import {Component, OnInit} from '@angular/core';
import {AppPageloaderService} from '../../../../services/app.pageloader.service';
import {AppUserService} from '../../../../services/app.user.service';
import {UserIp} from '../../../../models/user-ip';
import {AppDialogsService} from '../../../../services/app.dialogs.service';
import {AppMessageService} from '../../../../services/app.message.service';

@Component({
  selector: 'user-ips',
  templateUrl: 'shared.settings-user-ips.component.html',
  styleUrls: ['shared.settings-user-ips.component.scss']
})
export class SharedSettingsUserIpsComponent implements OnInit {

  ips: UserIp[];

  constructor(private loader: AppPageloaderService, private usvc: AppUserService, private dsvc: AppDialogsService,
              private msg: AppMessageService) {
  }

  delete(ip: UserIp) {
    this.dsvc.confirm(null, 'IP Löschen?',
      'Wollen Sie wirklich ' + ip.ip + ' löschen?',
      () => {
        this.usvc.deleteUserIp(ip).subscribe(s => {
          if (s) {
            this.msg.info('Gelöscht!');
          } else {
            this.msg.error('Es ist ein Fehler aufgetreten!');
          }

          this.load();
        });
      }
    );
  }

  load() {
    const l = this.loader.start();
    this.usvc.getUserIps().subscribe(ips => {
      this.ips = ips;
      l.stop();
    });
  }

  ngOnInit(): void {
    this.load();
  }

}
