import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ShopViewComponent} from '../view/shop.view.component';
import {View} from '../../../../models/view';

@Component({
  selector: 'shop-view-filter',
  templateUrl: 'shop.view-filter.component.html',
  styleUrls: ['shop.view-filter.component.scss']
})
export class ShopViewFilterComponent {
  @Input() view: View;
  @Output() viewChange = new EventEmitter<View>();
  @Output() change = new EventEmitter();
  @Input() viewcomp: ShopViewComponent;

  doChange() {
    this.viewChange.emit(this.view);
    this.change.emit();
  }

}
