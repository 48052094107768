<div class="ajax-warenkorb" *ngIf="(warenkorb|async)">
  <div class="paddedcontent">
    <h3>
      <svg-icon>warenkorb</svg-icon>
      <span> {{'Warenkorb ' + (warenkorb|async)?.label}}</span>
    </h3>
  </div>
  <div style="position: relative">
    <warenkorb-box [ajax_mode]="true" [warenkorb]="warenkorb|async"></warenkorb-box>
  </div>
</div>
<div *ngIf="!(warenkorb|async)" style="text-align: center; padding-top: 50px">
  <div style="display: inline-block">
    <mat-spinner></mat-spinner>
  </div>
</div>
