import {NgModule} from "@angular/core";
import {MainMenuComponent} from "./components/main-menu/main-menu.component";
import {SharedModule} from "../shared/shared.module";
import {MainMenuMenuButtonComponent} from "./components/menu-button/main-menu.menu-button.component";
import {
  MainMenuMenuButtonElementComponent
} from "./components/menu-button-element/main-menu.menu-button-element.component";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    MainMenuComponent,
    MainMenuMenuButtonComponent,
    MainMenuMenuButtonElementComponent,
  ],
  exports: [
    MainMenuMenuButtonComponent,
    MainMenuComponent,
  ],
  imports: [
    RouterModule.forChild([]),
    SharedModule,
  ]
})
export class MainMenuPartsModule {

}
