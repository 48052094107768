import {Component} from '@angular/core';
import {AppEventService} from '../../../../services/app.event.service';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';

@Component({
  selector: 'app-dashboard-widgets-warenkoerbe',
  templateUrl: 'dashboard.widgets.warenkoerbe.component.html',
  styleUrls: ['dashboard.widgets.warenkoerbe.component.scss'],
})
export class DashboardWidgetsWarenkoerbeComponent {
  static id = 'DashboardWidgetsWarenkoerbeComponent';
  static cols = 3;
  static rows = 5;
  static label = 'Ihre Warenkörbe';
  static description = 'Zeigt eine Liste Ihrer Warenkörbe an.';

  loading1 = false;
  loading2 = false;

  constructor(public svc: AppWarenkorbService, private esvc: AppEventService) {
  }

}
