import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AppConfigService} from './app.config.service';

@Injectable({providedIn: 'root'})
export class AppPageTitleService {
  subtitle1: string = '';
  subtitle2: string = '';


  constructor(private titleService: Title, private cfg: AppConfigService) {
  }

  resetTitle() {
    this.subtitle1 = '';
    this.subtitle2 = '';

    this.render();
  }

  private render() {
    let title = this.cfg.domainconfig.pagetitle;

    if (this.subtitle1) {
      title = this.subtitle1 + ' | ' + title;
    }

    if (this.subtitle2) {
      title = this.subtitle2 + ' | ' + title;
    }

    this.titleService.setTitle(title);
  }

  setTitle(text: string) {
    this.subtitle1 = text;
    this.render();
  }

  setTitle2(text: string) {
    this.subtitle2 = text;
    this.render();
  }

  resetTitle2() {
    this.subtitle2 = '';
    this.render();
  }
}
