import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {KundeBonuspunkt} from '../../../../models/kunde-bonuspunkt';
import {BonuspunktprogrammStufe} from '../../../../models/bonuspunktprogramm-stufe';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'shop.dialogs.bonus-punkte-details.component.html',
  styleUrls: ['shop.dialogs.bonus-punkte-details.component.scss']
})
export class ShopDialogsBonusPunkteDetailsComponent implements OnInit {
  max_points = 0;

  percentage_per_point = 0;

  steps: BonuspunktprogrammStufe[];

  step_heights = [];

  constructor(@Inject(MAT_DIALOG_DATA) public punkte: KundeBonuspunkt, private cd: ChangeDetectorRef) {

  }

  private detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.max_points = this.punkte.Bonuspunkteprogramm.BonuspunktprogrammStufen[this.punkte.Bonuspunkteprogramm.BonuspunktprogrammStufen.length - 1].AbBonuspunktSaldo;

    this.percentage_per_point = (100 / this.max_points);

    let last_level_height = 0;
    this.punkte.Bonuspunkteprogramm.BonuspunktprogrammStufen.forEach((s, level) => {
      this.step_heights[level] = s.AbBonuspunktSaldo - last_level_height;
      last_level_height = s.AbBonuspunktSaldo;
      s.level = level;
    });

    this.steps = [];
    this.punkte.Bonuspunkteprogramm.BonuspunktprogrammStufen.forEach(s => {
      this.steps.push(s);
    });
    this.steps.reverse();

    this.detectChanges();
  }


  calcPercentage(points: number) {
    if (points > this.max_points) {
      points = this.max_points;
    }
    return points * this.percentage_per_point;
  }

  getPointsLabel() {
    return 'Punkte';

  }
}
