import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppNotificationService} from '../../services/app.notification.service';
import {Notification} from '../../models/notification';
import {AppUserService} from '../../services/app.user.service';
import {NavigationEnd, Router} from '@angular/router';
import {AppEventService} from '../../services/app.event.service';
import {User} from '../../models/user';
import {AppMobileService} from '../../services/app.mobile.service';
import {Location} from '@angular/common';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {AppMobileappService} from '../../services/app.mobileapp.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-notifications',
  templateUrl: 'app.notification.component.html',
  styleUrls: ['app.notification.component.scss']
})
export class AppNotificationComponent implements OnInit, OnDestroy {
  static id = 'AppNotificationComponent';
  do_fetch = false;
  last_not_id = 0;
  notifications = new BehaviorSubject<Notification[]>([]);
  visible_notifications = new BehaviorSubject<Notification[]>([]);
  menu_visible = new BehaviorSubject(false);

  user: User;

  notificationcounter = new BehaviorSubject(0);
  start = 0;
  limit = 5;

  current_path = '';

  init = false;

  _onDestroy = new Subject();

  hideMenuTimeout: any;

  constructor(protected router: Router, protected location: Location, protected userService: AppUserService,
              protected eventsvc: AppEventService, public notificationService: AppNotificationService,
              public mobsvc: AppMobileService, protected appsvc: AppMobileappService,
              protected loader: AppPageloaderService) {
  }

  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this.notificationService.notifications$.pipe(takeUntil(this._onDestroy)).subscribe(n => {
      this.notifications.next(n);
      this.render();
    });

    this.notificationService.notifications_count$.pipe(takeUntil(this._onDestroy)).subscribe(n => {
      this.notificationcounter.next(n);
    });

    this.router.events.pipe(takeUntil(this._onDestroy)).subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.current_path = this.location.path();
      }
    });

    this.notificationService.menu_visible$.pipe(takeUntil(this._onDestroy)).subscribe(state => this.menu_visible.next(state));
    this.userService.user$.pipe(takeUntil(this._onDestroy)).subscribe((user: User) => {
      if (user instanceof User) {
        this.user = user;
      }
    });

    this.eventsvc.getQueue().pipe(takeUntil(this._onDestroy)).subscribe(e => {
      // if (e.name == 'gui.app_body_click' && !this.isMobile && this.menu_visible) {
      if (e.name == 'gui.app_body_click' && this.menu_visible) {
        this.notificationService.menu_visible.next(false);
      }
    });
  }

  isCurrentUser(testuser: User) {
    let state = false;

    if (testuser.id && this.user instanceof User && testuser.id == this.user.id) {
      state = true;
    }

    return state;
  }

  reduceLimit(num) {
    this.limit = this.limit - num;
    if (this.limit < 5) {
      this.limit = 5;
    }
  }

  shownSeen($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    const ids = [];
    for (const elem of this.visible_notifications.value) {
      ids.push(elem.id);
    }

    this.loader.start('Notification_1');
    this.notificationService.setAllSeen(ids).subscribe(state => {
      this.limit = 5;
      this.loader.stop('Notification_2');
      this.fetch();
    });
  }

  allSeen($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    const ids = [];
    for (const elem of this.notifications.value) {
      ids.push(elem.id);
    }

    this.loader.start('Notification_2');
    this.notificationService.setAllSeen(ids).subscribe(state => {
      this.limit = 5;
      this.loader.stop('Notification_2');
      this.fetch();
    });
  }

  more($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.limit = this.limit + 5;
    this.render();
  }

  showAll($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.limit = this.notifications.value.length;
    this.render();
  }

  render() {
    this.visible_notifications.next(this.notifications.value.filter((elem, i) => {
      if (i >= this.start && i <= (this.limit + this.start)) {
        return true;
      } else {
        return false;
      }
    }));
  }

  toggle($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.notificationService.toggleMenu();
  }

  showMenu($event) {
    if (!this.mobsvc.isMobile()) {
      if (this.hideMenuTimeout) {
        clearTimeout(this.hideMenuTimeout);
      }
      $event.preventDefault();
      $event.stopImmediatePropagation();
      this.notificationService.showMenu();
    }
  }

  hideMenu($event) {
    if (!this.mobsvc.isMobile()) {
      this.hideMenuTimeout = setTimeout(() => {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.notificationService.hideMenu();
      }, 200);
    }
  }

  setSeen($event: Event, notification: Notification) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.loader.start('Notification_3');
    this.notificationService.setSeen(notification.id).subscribe(state => {
      this.reduceLimit(1);
      this.loader.stop('Notification_3');
      this.fetch();
    });
  }

  navigateSeen(notification: Notification, url = '') {
    this.notificationService.setSeen(notification.id).subscribe(state => {
      this.reduceLimit(1);
      this.fetch();
      this.menu_visible.next(false);
      if (url != '') {
        this.notificationService.addNotificationData(url, notification);

        if (url.match('/http:\/\/.*/g') || url.match('/https:\/\/.*/g')) {
          window.open(url, '_blank');
        } else {
          this.router.navigateByUrl(url);
        }


      }
    });
  }

  fetch() {
    this.notificationService.load();
  }
}
