import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'trim'})
export class SharedPipeTrimPipePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.trim();
    } else {
      return value;
    }
  }
}
