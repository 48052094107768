<section-title [buttons_right]="section_title_buttons" icon="lieferadresse" *ngIf="!differential_mode && user && user.vertreter && (show|async) && !(autoSelecting|async)"
               label="Kunde auswählen"></section-title>

<section-title [buttons_right]="section_title_buttons" icon="lieferadresse" *ngIf="!differential_mode && user && user.kunde && (show|async) && !(autoSelecting|async)"
               label="Lieferanschrift auswählen"></section-title>

<section-title [buttons_right]="section_title_buttons" icon="lieferadresse" *ngIf="differential_mode && (show|async) && !(autoSelecting|async)" [hasBack]="true"
               label="Lieferung für"></section-title>


<div *ngIf="lieferkunden_filtered && !(show|async) && (autoSelecting|async)" class="lieferkundenbox_holder tut_lieferkunden_selector" style="text-align: center; margin-top: 100px">
  <div style="display: inline-block; text-align: center">
    <div style="display: inline-block">
      <h3>Angemeldet als</h3>
      <h1 style="text-shadow: 0 0 1px white">
        <span *ngIf="domaincfg.show_customer_numbers">
                {{(autoSelecting|async).LieferkundeNr}}
          <br>
        </span>
        {{(autoSelecting|async).Vorname}}
        <div style="font-size: 18px" *ngIf="(autoSelecting|async).Nachname">{{(autoSelecting|async).Nachname}}</div>
        <br>
        <small>{{(autoSelecting|async).Strasse}}, {{(autoSelecting|async).Plz}} {{(autoSelecting|async).Ort}}</small>
      </h1>
    </div>
    <br>
    <br>
    <div style="display: inline-block">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>

<div *ngIf="(show|async) && !(autoSelecting|async)" class="lieferkundenbox_holder tut_lieferkunden_selector">
  <search-box [(value)]="search" (search)="filter(true)" (valueChange)="search_dirty = true"></search-box>

  <div *ngIf="user.kunde && !differential_mode" style="padding: 5px">
    <mat-checkbox [(ngModel)]="remember_lknd_nr">Künftig automatisch wählen</mat-checkbox>
  </div>
  <div *ngIf="lieferkunden_filtered">
    <div (click)="doSelect(lk)" *ngFor="let lk of lieferkunden_filtered"
         [class.active]="current_lieferkunde && lk.LieferkundeNr == current_lieferkunde.LieferkundeNr"
         class="lieferkundenbox mat-elevation-z1"
         [class.highlight]="(usvc.user$|async)?.kunde && lk.LieferkundeNr == (usvc.user$|async)?.kunde.KundeNr"
         matTooltip="{{ ((usvc.user$|async)?.kunde && lk.LieferkundeNr == (usvc.user$|async)?.kunde.KundeNr) ? 'Standard Lieferkunde - ' : '' }} Klicken um auszuwählen"
    >
      <table>
        <tr>
          <td class="icontd" rowspan="2">
            <svg-icon>lieferadresse</svg-icon>
          </td>
          <td class="title">{{lk.Vorname}}</td>
        </tr>
        <tr>
          <td>
            <span *ngIf="domaincfg.show_customer_numbers">
              <ng-container *ngIf="domaincfg.showA2KndNrOverwrite == 'A2KndNrDefault'">
                {{lk.A2KndNrDefault}}
              </ng-container>
              <ng-container *ngIf="domaincfg.showA2KndNrOverwrite == 'A2KndNrOrganisationseinheit'">
                {{lk.A2KndNr}}
              </ng-container>
              <ng-container *ngIf="domaincfg.showA2KndNrOverwrite == 'Nein'">
                {{lk.LieferkundeNr}}
              </ng-container>
              <br>
            </span>
            <span *ngIf="lk.Nachname">
              {{lk.Nachname}}
            </span>
            <br>
            <small>{{lk.Strasse|trim}}, {{lk.Plz}} {{lk.Ort}}</small>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="lieferkunden_filtered && lieferkunden_filtered.length == 0" style="text-align: center">
    <h1>
      <info-box type="app-lieferkunde-selector-no-lieferkunden-filtered-vertreter" *ngIf="(user.vertreter && (!search || search_dirty))"></info-box>
      <info-box type="app-lieferkunde-selector-no-lieferkunden-filtered" *ngIf="!(user.vertreter && (!search || search_dirty))"></info-box>
    </h1>
  </div>
  <div *ngIf="!lieferkunden_filtered && user.vertreter && !search">
    <h1>
      <info-box type="app-lieferkunde-selector-no-lieferkunden-filtered-vertreter"></info-box>
    </h1>
  </div>
</div>
