<dashboard-widget>
  <div icon>
    <svg-icon>merkliste</svg-icon>
  </div>
  <div header1 matTooltip="Merklisten ({{(svc.merklisten$|async)?.length}})">
    <a routerLink="/shop/merklisten">Merklisten ({{(svc.merklisten$|async)?.length}})</a>
  </div>
  <div content>
    <div *ngFor="let ml of svc.merklisten$|async">
      <button mat-button routerLink="/shop/merkliste/{{ml.id}}">
        {{ml.label}} ({{ml.artikel_count}} Artikel)
      </button>
    </div>
  </div>
</dashboard-widget>
