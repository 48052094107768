import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConfigService} from '../../services/app.config.service';
import {AppLieferkundeService} from '../../services/app.lieferkunde.service';
import {AppMobileService} from '../../services/app.mobile.service';
import {AppPrivacyService} from '../../services/app.privacy.service';
import {BehaviorSubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DomainConfig} from "../../models/domain-config";
import {Lieferkunde} from "../../models/lieferkunde";
import {AppNavbarService} from "../../services/app.navbar.service";

@Component({
  selector: 'app-footer',
  templateUrl: 'app.footer.component.html',
  styleUrls: ['app.footer.component.scss']
})
export class AppFooterComponent implements OnInit, OnDestroy {
  appVersion: number;

  isMobile = new BehaviorSubject(false);
  currentLieferkunde = new BehaviorSubject<Lieferkunde>(null);

  _destroy = new Subject();

  domainConfig: DomainConfig;

  disabled = new BehaviorSubject<boolean>(false);

  constructor(private cfg: AppConfigService, private lksvc: AppLieferkundeService, private msvc: AppMobileService,
              private psvc: AppPrivacyService, private nbsvc: AppNavbarService) {
    this.appVersion = cfg.getVersion();
  }

  openPrivacySettings() {
    this.psvc.openDialog();
  }

  ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  ngOnInit(): void {
    this.domainConfig = this.cfg.domainconfig;

    this.nbsvc.disabled$
      .pipe(takeUntil(this._destroy))
      .subscribe(d => this.disabled.next(d));

    this.lksvc.current_lieferkunde$
      .pipe(takeUntil(this._destroy))
      .subscribe(lk => this.currentLieferkunde.next(lk));

    this.msvc.isMobile()
      .pipe(takeUntil(this._destroy))
      .subscribe(m => {
        this.isMobile.next(m);
      });
  }


}
