import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {SharedDialogsFilePickerDialogComponent} from "../../dialogs/file-picker-dialog/shared.dialogs.file-picker-dialog.component";
import {FileBrowserEntry} from "../../../../models/file-browser-entry";
import {FileBrowserSelectedFile} from "../../models/file-browser-selected-file";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {AppPageloaderService} from "../../../../services/app.pageloader.service";

@Component({
  selector: 'file-picker',
  templateUrl: 'shared.file-picker.component.html',
  styleUrls: ['shared.file-picker.component.scss']
})
export class SharedFilePickerComponent implements OnInit {
  @Input() placeholder: string;
  @Input() file: FileBrowserSelectedFile;
  @Output() fileChange = new EventEmitter<FileBrowserSelectedFile>();

  constructor(private matDialog: MatDialog, private http: HttpClient, private loader: AppPageloaderService) {
  }

  ngOnInit(): void {
    if (!this.file) {
      this.file = new FileBrowserSelectedFile();
    }
  }

  download() {
    const l = this.loader.start();
    this.http.get('/files/download/' + this.file.id + '/' + this.file.label,
      {
        responseType: 'blob',
      }
    ).subscribe(resp => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(resp)
      a.href = objectUrl
      a.download = this.file.label;
      a.click();
      URL.revokeObjectURL(objectUrl);
      l.stop();
    });
  }

  clear() {
    this.file = new FileBrowserSelectedFile();
    this.fileChange.emit(this.file);
  }

  pick() {
    let ref = this.matDialog.open(SharedDialogsFilePickerDialogComponent);
    ref.afterClosed().subscribe(data => {
      if (data instanceof FileBrowserEntry) {
        let d = new FileBrowserSelectedFile();
        d.fromFileEntry(data);
        this.file = d;
        this.fileChange.emit(this.file);
      }
    })
  }
}
