<dashboard-widget>
  <div icon>
    <svg-icon>auftragsplaner</svg-icon>
  </div>
  <div header1 matTooltip="Import Abschluss">
    Import Abschluss
  </div>
  <div content>
    <div style="text-align: center" *ngIf="next_cron_import_diff|async">
      <div style="padding: 5px" *ngIf="(cron_import_enabled|async)">
        <b>Nächster automatischer Importabschluss:</b>
      </div>
      <div style="padding: 5px" *ngIf="!(cron_import_enabled|async)">
        <b>Nächster Importabschluss:</b>
      </div>
      <div style="padding: 5px">
        <span style="font-size: 24px;">{{next_cron_import_run|async}}</span>
      </div>
      <div style="padding: 5px">
        <span style="font-size: 24px;">{{next_cron_import_diff|async}}</span>
      </div>
      <div *ngIf="!(cron_import_enabled|async)" style="color: red">
        Automatischer Abschluss deaktiviert!
      </div>
    </div>
  </div>
</dashboard-widget>
