import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Artikel} from "../models/artikel";
import {Observable} from "rxjs";
import {CustomerSpecialPriceResponse} from "../models/customer-special-price-response";
import {map} from "rxjs/operators";
import {CustomerSpecialPrice} from "../models/customer-special-price";
import {SaveCustomerSpecialPriceResponse} from "../models/save-customer-special-price-response";

@Injectable({providedIn: "root"})
export class AppCustomerSpecialPriceService {
  constructor(private http: HttpClient) {
  }

  deleteSpecialPriceForArticle(article: Artikel, specialPriceId):Observable<CustomerSpecialPriceResponse> {
    return this.http.get<CustomerSpecialPriceResponse>(
      '/customer-special-price/'+article.ArtikelNummer+'/'+specialPriceId+'/delete'
    );
  }

  saveSpecialPriceForArticle(article: Artikel, sp: CustomerSpecialPrice):Observable<SaveCustomerSpecialPriceResponse> {
    return this.http.post<SaveCustomerSpecialPriceResponse>(
      '/customer-special-price/'+article.ArtikelNummer+'/save',
      {
        data: sp
      }
    );
  }

  getSpecialPriceForArticle(article: Artikel, showHistory = false):Observable<CustomerSpecialPriceResponse> {

    let historyUrl = '';

    if (showHistory) {
      historyUrl = '/history';
    }

    return this.http.get<CustomerSpecialPriceResponse>(
      '/customer-special-price/'+article.ArtikelNummer+historyUrl
    ).pipe(map(resp => {
      if (resp) {
        Object.setPrototypeOf(resp, CustomerSpecialPriceResponse.prototype);

        if (resp.SonderpreiseKunde) {
          resp.SonderpreiseKunde.forEach(sp => {
            Object.setPrototypeOf(sp, CustomerSpecialPrice.prototype);
          })
        }
      }

      return resp;
    }))
  }
}
