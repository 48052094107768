<div *ngIf="msg">
  <div [class.error]="msg.severity == 'error'"
       [class.info]="msg.severity == 'info'"
       [class.warning]="msg.severity == 'warning'" class="msg">
    <table>
      <tr>
        <td>
          <svg-icon *ngIf="msg.severity == 'error'">error</svg-icon>
          <svg-icon *ngIf="msg.severity == 'warning'">warning</svg-icon>
          <svg-icon *ngIf="msg.severity == 'info'">info</svg-icon>
        </td>
        <td class="message">
          {{msg.message}}
        </td>
      </tr>
    </table>
  </div>
</div>
