
<ng-container *ngFor="let b of buttons">
  <ng-container *ngIf="b.label">
    <button (click)="b.onclick()" class="mat-button {{b.class}}" mat-button matTooltip="{{b.title}}">
      <svg-icon *ngIf="b.icon">{{b.icon}}</svg-icon>
      {{b.label}}
    </button>
  </ng-container>
  <ng-container *ngIf="!b.label">
    <button (click)="b.onclick()" class="mat-icon-button {{b.class}}" mat-icon-button matTooltip="{{b.title}}">
      <svg-icon>{{b.icon}}</svg-icon>
    </button>
  </ng-container>
</ng-container>
