<strong>Ihre Ips</strong>

<table class="table table-striped table-hover" style="max-width: 500px" *ngIf="ips">
  <tr>
    <th>IP</th>
    <th>Erster Login</th>
    <th>Letzter Login</th>
    <th>

    </th>
  </tr>
  <tr *ngIf="ips.length == 0">
    <td colspan="4">- Keine IPs vorhanden -</td>
  </tr>
  <tr *ngFor="let ip of ips">
    <td>
      {{ip.ip}}
    </td>
    <td>
      {{ip.ts_insert}}
    </td>
    <td>
      {{ip.ts_last_login}}
    </td>
    <td>
      <span [matTooltip]="ip.current ? 'Sie können Ihre aktuelle IP nicht löschen' : 'Löschen'">
        <button mat-icon-button [disabled]="ip.current" (click)="delete(ip)">
          <svg-icon>loeschen</svg-icon>
        </button>
        </span>
    </td>
  </tr>
</table>
