import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {AppPageloaderService} from '../../../../services/app.pageloader.service';
import {AppMerklisteService} from '../../../../services/app.merkliste.service';
import {BehaviorSubject} from 'rxjs';
import {Merkliste} from '../../../../models/merkliste';
import {MerklistActionInfo} from '../../../../models/merklist-action-info';
import {ShopMerklisteActionInfoComponent} from '../merkliste-action-info/shop.merkliste-action-info.component';
import {Artikel} from '../../../../models/artikel';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {AppSnackbarService} from "../../../../services/app.snackbar.service";

@Component({
  templateUrl: 'shop.merkliste-menu.component.html',
  styleUrls: ['shop.merkliste-menu.component.scss']
})
export class ShopMerklisteMenuComponent implements OnInit, OnDestroy {
  static id = 'ShopMerklisteMenuComponent';
  merklisten: Merkliste[];

  primary_merkliste = new BehaviorSubject<Merkliste>(null);

  add_mode = false;

  newml: Merkliste;

  constructor(private loader: AppPageloaderService, public mlsvc: AppMerklisteService,
              private snackBarSvc: AppSnackbarService, private _bottomSheetRef: MatBottomSheetRef<ShopMerklisteMenuComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
                artikel: Artikel,
                view_slug: string,
                entry: WarenkorbEntry
              }) {
  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {

  }

  save() {
    this.loader.start('ShopViewActionComponent_' + this.data.artikel.ArtikelNummer + '_11');
    this.mlsvc.createMerkliste(this.newml).subscribe(ml => {
      this.loader.stop('ShopViewActionComponent_' + this.data.artikel.ArtikelNummer + '_11');
      this.addToMerkliste(ml);
      this._bottomSheetRef.dismiss();
    });
  }

  cancel() {
    this.add_mode = false;
    this.newml = undefined;
  }

  add() {
    this.newml = new Merkliste();
    this.newml.label = 'Neue Merkliste';
    this.newml.shared = true;
    this.add_mode = true;
  }


  addToPrimaryMerkliste() {
    return this.addToMerkliste(this.mlsvc.primary_merkliste.value);
  }

  addToMerkliste(ml: Merkliste) {
    this.loader.start('ShopViewActionComponent_' + this.data.artikel.ArtikelNummer + '_1');

    this.mlsvc.addToMerklisteById(
      ml.id,
      this.data.view_slug,
      this.data.artikel.ArtikelNummer,
      this.data.entry.menge
    ).subscribe(m => {
      this.loader.stop('ShopViewActionComponent_' + this.data.artikel.ArtikelNummer + '_1');

      this.data.entry.menge = 1;

      const i = new MerklistActionInfo();
      i.action = 'add';
      i.info = m;

      this.snackBarSvc.open(ShopMerklisteActionInfoComponent, i, 2500);
      this._bottomSheetRef.dismiss();
    });
  }

}
