import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppConfigService} from '../../../../services/app.config.service';
import {AppViewService} from '../../../../services/app.view.service';
import {View} from '../../../../models/view';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MainMenuButtonConfig} from '../../../../models/main-menu-button-config';

@Component({
  selector: 'menu-button',
  templateUrl: 'main-menu.menu-button.component.html',
  styleUrls: ['main-menu.menu-button.component.scss']
})
export class MainMenuMenuButtonComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() class: string;
  @Input() small = false;

  @Input() config: MainMenuButtonConfig;

  isView = new BehaviorSubject<boolean>(false);

  view: View | boolean;

  lsub: Subscription;

  constructor(public cfg: AppConfigService, private vsvc: AppViewService) {
  }

  ngOnInit(): void {
    if (this.name.match(/^view_.*$/)) {
      const vslug = this.name.replace('view_', '');
      this.lsub = this.vsvc.menu_views_loaded.subscribe(obs => {
        if (obs) {
          this.view = this.vsvc.getMenuViewBySlug(vslug);
        }
      });

      this.isView.next(true);
    } else {
      this.isView.next(false);
    }
  }

  ngOnDestroy(): void {
    if (this.lsub instanceof Subscription) {
      this.lsub.unsubscribe();
    }
  }
}
