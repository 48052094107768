import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'unitconverter_mm'})
export class SharedUnitconverterMmPipe implements PipeTransform {
  transform(value: number): string {
    if (value > 1000) {
      value = value / 1000;

      return value + ' m';

    } else if (value > 100) {
      value = value / 100;

      return value + ' dm';

    } else if (value > 10) {
      value = value / 10;

      return value + ' cm';

    } else {
      return value + ' mm';
    }
  }
}
