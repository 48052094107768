import {Kunde} from './kunde';
import {Vertreter} from './vertreter';
import {Lieferkunde} from './lieferkunde';

export class User {
  id: string;
  username: string;
  email: string;
  roles: string[];
  benutzerandruckname: string;
  bonuspunkte: any;
  kunde: Kunde;
  vertreter: Vertreter;
  impersonated_by: User;
  last_seen: string;
  deleted: boolean;
  tsDeleted: string;

  hasRole(role: string) {
    if (this.roles === undefined) {
      return false;
    }
    return this.roles.includes(role);
  }

  hasRoles(roles: any[]) {
    if (this.roles === undefined) {
      return false;
    }
    if (roles === undefined) {
      return true;
    }

    let state = false;

    roles.forEach((search) => {
      if (this.roles.includes(search)) {
        state = true;
      }
    });

    return state;
  }

  hasAllRoles(roles: any[]) {
    if (this.roles === undefined) {
      return false;
    }
    if (roles === undefined) {
      return true;
    }

    let hasCount = 0;

    roles.forEach((search) => {
      if (this.roles.includes(search)) {
        hasCount++;
      }
    });

    return roles.length == hasCount;
  }

  getRoles() {
    const roles = [];
    this.roles.forEach((entry) => {
      if (entry != 'User') {
        roles.push(entry);
      }
    });
    return roles;
  }
}
