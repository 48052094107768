import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppCrontaskService} from '../../../../services/app.crontask.service';
import {AppSettingsService} from '../../../../services/app.app_settings.service';
import {AppEventService} from '../../../../services/app.event.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import * as moment from 'moment';
import {AppViewService} from '../../../../services/app.view.service';
import {AppMobileService} from '../../../../services/app.mobile.service';
import {AppConfigService} from '../../../../services/app.config.service';

@Component({
  selector: 'warenkorb-import-ticker',
  templateUrl: 'shop.warenkorb-import-ticker.component.html',
  styleUrls: ['shop.warenkorb-import-ticker.component.scss']
})
export class ShopWarenkorbImportTickerComponent implements OnInit, OnDestroy {
  show = new BehaviorSubject<boolean>(false);
  next_cron_import_run = new BehaviorSubject<string>(null);
  next_cron_import_diff = new BehaviorSubject<string>(null);
  cron_import_enabled = new BehaviorSubject<boolean>(false);

  esub: Subscription;

  next_moment: any;

  interval: any;

  constructor(private ctsvc: AppCrontaskService, private settsvc: AppSettingsService, private esvc: AppEventService,
              private vsvc: AppViewService, public msvc: AppMobileService, public cfg: AppConfigService) {

  }

  loadSettings() {
    this.settsvc.getCustomerSettingValue('ShopImportAutosend').subscribe(s => {
      this.cron_import_enabled.next(s);
    });
  }

  loadImpCronTime() {
    this.ctsvc.getnextTimeWKImportAutosendTask().subscribe(t => {
      this.next_moment = moment(t, 'DD.MM.YYYY H:i:s');
      this.next_cron_import_run.next(t);
      this.calcDiff();
    });
  }

  private secondsToString(seconds) {
    const numyears = Math.floor(seconds / 31536000);
    const numdays = Math.floor((seconds % 31536000) / 86400);
    const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    const numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    const string = [];
    if (numyears > 0) {
      string.push(numyears + ' J');
    }
    if (numdays > 0) {
      string.push(numdays + ' T');
    }
    string.push(this.pad(numhours, 2) + ':');
    string.push(this.pad(numminutes, 2) + ':');
    string.push(this.pad(numseconds, 2));
    return string.join('');
  }

  private pad(num, size) {
    const s = '000000000' + num;
    return s.substr(s.length - size);
  }

  calcDiff() {
    if (this.next_moment) {
      const now = moment();
      const diff = this.next_moment.diff(now, 'seconds');

      this.next_cron_import_diff.next(this.secondsToString(diff));
    }
  }

  ngOnInit(): void {
    if (this.vsvc.getViewBySlug('importservice')) {
      this.show.next(true);

      this.loadImpCronTime();
      this.loadSettings();

      this.interval = setInterval(() => {
        this.calcDiff();
      }, 1000);

      this.esub = this.esvc.getQueue().subscribe(e => {
        if (e.name == 'App\\Events\\SettingsEngine\\SettingsChangedEvent') {
          if (e.data.id == 'App\\Model\\Settings\\Definition\\Customer\\ShopImportAutosend') {
            this.loadSettings();
          }
        } else if (e.name == 'App\\Events\\TaskEngine\\AllUserTaskRunEvent' && e.data && e.data.tasks) {
          e.data.tasks.forEach(t => {
            if (t.name && t.name == 'App\\AsyncTasks\\WKImportAutosendTask') {
              this.loadImpCronTime();
            }
          });
        }
      });
    } else {
      this.show.next(false);
    }
  }

  ngOnDestroy(): void {
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}
