<ng-container *ngIf="view">
  <a class="menu-button {{class}}" routerLink="/shop/{{view.slug}}" *ngIf="view.allowed"
     [style.background-color]="view.getBGColor()" [style.color]="view.getFontColor()"
     [class.hovertransparent]="view.getBGColor() != ''"
     routerLinkActive="active-link">
    <table>
      <tr>
        <td>
          <svg-icon [size]="icon_size">{{view.getIcon()}}</svg-icon>
        </td>
        <td>
          {{view.getLabel()}}
        </td>
        <td>
          <button mat-icon-button (click)="toggleFav($event)"
                  matTooltip="{{isFav ? 'Aus Favoriten entfernen' : 'Zu Favoriten hinzufügen'}}">
            <svg-icon *ngIf="isFav">favorit-voll</svg-icon>
            <svg-icon *ngIf="!isFav">favorit</svg-icon>
          </button>
        </td>
      </tr>
    </table>
  </a>

  <a class="menu-button disabled" *ngIf="!view.allowed"
     [style.background-color]="view.getBGColor()" [style.color]="view.getFontColor()"
     [class.hovertransparent]="view.getBGColor() != ''"
     routerLinkActive="active-link">
    <table>
      <tr>
        <td>
          <svg-icon [size]="icon_size">{{view.getIcon()}}</svg-icon>
        </td>
        <td>
          {{view.getLabel()}}<br>
          Keine Berechtigung
        </td>
        <td>

        </td>
      </tr>
    </table>
  </a>
</ng-container>

<ng-container *ngIf="!view">
  <a class="menu-button {{class}}" [routerLink]="routerLink" [target]="target" routerLinkActive="active-link"
     *ngIf="routerLink">
    <table>
      <tr>
        <td>
          <svg-icon [size]="icon_size" *ngIf="icon" color="{{icon_color}}">{{icon}}</svg-icon>
        </td>
        <td>
          {{label}}
          <br *ngIf="description">
          <small>{{description}}</small>
        </td>
        <td>
          <button mat-icon-button (click)="toggleFav($event)"
                  matTooltip="{{isFav ? 'Aus Favoriten entfernen' : 'Zu Favoriten hinzufügen'}}">
            <svg-icon *ngIf="isFav">favorit-voll</svg-icon>
            <svg-icon *ngIf="!isFav">favorit</svg-icon>
          </button>
        </td>
      </tr>
    </table>
  </a>
  <a class="menu-button {{class}}" [href]="href" [target]="target" routerLinkActive="active-link"
     *ngIf="href && !routerLink">
    <table>
      <tr>
        <td>
          <svg-icon [size]="icon_size" *ngIf="icon" color="{{icon_color}}">{{icon}}</svg-icon>
        </td>
        <td>
          {{label}}
          <br *ngIf="description">
          <small>{{description}}</small>
        </td>
        <td>
          <button mat-icon-button (click)="toggleFav($event)"
                  matTooltip="{{isFav ? 'Aus Favoriten entfernen' : 'Zu Favoriten hinzufügen'}}">
            <svg-icon *ngIf="isFav">favorit-voll</svg-icon>
            <svg-icon *ngIf="!isFav">favorit</svg-icon>
          </button>
        </td>
      </tr>
    </table>
  </a>
</ng-container>
