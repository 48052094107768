<div *ngIf="condition">
  <section-title [label]="condition.title"></section-title>

  <div class="paddedcontent center">
    <div>
      Bitte akzeptieren Sie folgende Bedingungen:
    </div>

    <h1>{{condition.title}}</h1>
    <div [innerHTML]="condition.html|raw" style="text-align: left; margin: auto; padding: 10px; background-color: white; max-width: 1000px;">
    </div>

    <div style="padding: 10px">
      <button mat-raised-button (click)="decline()" style="height: 100px; background-color: red; color: white">
        ablehnen
      </button>

      <button mat-raised-button (click)="accept()" style="height: 100px; margin-left: 50px; background-color: green; color: white">
        akzeptieren
      </button>
    </div>
  </div>
</div>
