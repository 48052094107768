import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WarenkorbEntry} from "../../../../models/warenkorb-entry";
import {MerklistEntry} from "../../../../models/merklist-entry";

@Component({
  templateUrl: 'shop.dialogs.nachliefern-multi-frage.component.html',
  styleUrls: ['shop.dialogs.nachliefern-multi-frage.component.scss']
})
export class ShopDialogsNachliefernMultiFrageComponent implements OnInit {

  nachliefernChecked = {};

  nachliefernCheckedCounter = 0;

  constructor(public dialogRef: MatDialogRef<ShopDialogsNachliefernMultiFrageComponent>,
              @Inject(MAT_DIALOG_DATA) public messages: {
                title: string, msg: string, entry: WarenkorbEntry | MerklistEntry
              }[]) {
  }

  changeNachliefernChecked() {
    let c = 0;
    Object.keys(this.nachliefernChecked).forEach(k => {
      if (this.nachliefernChecked[k] == true) {
        c++;
      }
    });
    this.nachliefernCheckedCounter = c;
  }

  ngOnInit(): void {
    this.nachliefernChecked = {};
    this.messages.forEach((m, idx) => {
      this.nachliefernChecked[idx] = false;
    });
  }

  selectAll() {
    Object.keys(this.nachliefernChecked).forEach(k => {
      this.nachliefernChecked[k] = true;
    });
    this.doClose();
  }

  selectNone() {
    Object.keys(this.nachliefernChecked).forEach(k => {
      this.nachliefernChecked[k] = false;
    });
    this.doClose();
  }

  doClose() {
    let nachliefernEntries = [];
    Object.keys(this.nachliefernChecked).forEach(k => {
      if (this.nachliefernChecked[k] == true) {
        nachliefernEntries.push(this.messages[k].entry);
      }
    });

    this.dialogRef.close(nachliefernEntries);
  }
}
