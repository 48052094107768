import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: 'root'})
export class ContactButtonService {
  visible = new BehaviorSubject(true);

  hide() {
    this.visible.next(false);
  }

  show() {
    this.visible.next(true);
  }
}
