import {Artikel} from '../../../models/artikel';
import {ContactFormType} from './contact-form-type';

export class ContactFormData {
  type: ContactFormType;
  view_id: string;
  artikel: Artikel;
  label: string;
  text: string;
  url: string;
}
