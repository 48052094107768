<div class="footer" style="white-space: nowrap">
  <div style="display: inline-block; float: left; max-width: 48%; overflow: hidden;">
    <a *ngIf="(currentLieferkunde|async)" style="font-size: 11px; line-height: 11px" [class.disabled]="disabled|async" class="tut_lieferkunde_button" routerLink="/lieferkunden" matTooltip="Lieferanschrift  wechseln">
      <span *ngIf="domainConfig.show_customer_numbers">
        <svg-icon verticalAlign="top">lieferadresse</svg-icon>
        <div *ngIf="!(isMobile|async)" style="display: inline-block; text-align: left; padding-top: 1px; padding-left: 5px">
          <ng-container *ngIf="domainConfig.showA2KndNrOverwrite == 'A2KndNrDefault'">
            {{(currentLieferkunde|async)?.A2KndNrDefault}}
          </ng-container>
          <ng-container *ngIf="domainConfig.showA2KndNrOverwrite == 'A2KndNrOrganisationseinheit'">
            {{(currentLieferkunde|async)?.A2KndNr}}
          </ng-container>
          <ng-container *ngIf="domainConfig.showA2KndNrOverwrite == 'Nein'">
            {{(currentLieferkunde|async)?.LieferkundeNr}}
          </ng-container>
           - {{(currentLieferkunde|async)?.Vorname}} <br> {{(currentLieferkunde|async)?.Nachname|trim}},
          {{(currentLieferkunde|async)?.Ort}}</div>
      </span>
      <span *ngIf="!domainConfig.show_customer_numbers">
        <svg-icon verticalAlign="top">lieferadresse</svg-icon>
        <div *ngIf="!(isMobile|async)" style="display: inline-block; text-align: left; padding-top: 1px; padding-left: 5px">
          {{(currentLieferkunde|async)?.Vorname}} <br> {{(currentLieferkunde|async)?.Nachname|trim}},
          {{(currentLieferkunde|async)?.Ort}}</div>
      </span>
    </a>
  </div>
  <div style="display: inline-block; float:right">
    <table>
      <tr>
        <td>
          <a routerLink="/docs/impressum" [class.disabled]="disabled|async">Impressum</a>
        </td>
        <td>
          <a routerLink="/docs/datenschutz" [class.disabled]="disabled|async">Datenschutz</a>
        </td>
        <td>
          <a routerLink="/docs/agbs" [class.disabled]="disabled|async">AGBs</a>
        </td>
        <td>
          <a href="#" (click)="openPrivacySettings(); $event.preventDefault()" [class.disabled]="disabled|async" target="_blank">Privatsphäre</a>
        </td>
        <td>
          <small>V {{appVersion}}</small>
        </td>
      </tr>
    </table>

  </div>
  <div style="clear:both;"></div>
</div>
