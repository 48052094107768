import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppWarenkorbService} from '../../services/app.warenkorb.service';
import {Subscription} from 'rxjs';
import {Warenkorb} from '../../models/warenkorb';
import {Router} from '@angular/router';
import {AppLieferkundeService} from '../../services/app.lieferkunde.service';
import {Lieferkunde} from '../../models/lieferkunde';
import {AppMobileService} from "../../services/app.mobile.service";

@Component({
  selector: 'app-warenkorb-menu',
  templateUrl: 'app.warenkorb-menu.component.html',
  styleUrls: ['app.warenkorb-menu.component.scss']
})
export class AppWarenkorbMenuComponent implements OnInit, OnDestroy {
  wksub: Subscription;
  pwksub: Subscription;
  warenkoerbe: Warenkorb[];

  article_count = 0;

  primary_warenkorb: Warenkorb;

  open = false;
  open2 = false;

  warenkorb_link = '/shop/warenkorb';


  lieferkunde: Lieferkunde;
  lksub: Subscription;

  constructor(private svc: AppWarenkorbService, private router: Router, private lksvc: AppLieferkundeService,
              public msvc: AppMobileService) {
  }

  ngOnDestroy(): void {
    if (this.wksub instanceof Subscription) {
      this.wksub.unsubscribe();
    }
    if (this.pwksub instanceof Subscription) {
      this.pwksub.unsubscribe();
    }
    if (this.lksub instanceof Subscription) {
      this.lksub.unsubscribe();
    }
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  getWarnkorbCount() {
    if (this.warenkoerbe) {
      return this.warenkoerbe.length;
    } else {
      return 0;
    }
  }

  getTotalItemCount() {
    let count = 0;

    if (this.warenkoerbe) {
      for (const wk of this.warenkoerbe) {
        count += wk.artikel_count;
      }
    }

    return count;
  }

  ngOnInit(): void {
    this.lksub = this.lksvc.current_lieferkunde$.subscribe(lk => this.lieferkunde = lk);
    this.pwksub = this.svc.primary_warenkorb$.subscribe(wk => {
      this.primary_warenkorb = wk;
      if (wk) {
        this.article_count = wk.artikel_count;
      } else {
        this.article_count = 0;
      }
    });
    this.wksub = this.svc.warenkoerbe$.subscribe(wks => {
      this.warenkoerbe = wks;
      if (this.warenkoerbe.length == 1) {
        this.warenkorb_link = '/shop/warenkorb/' + this.warenkoerbe[0].id;
      } else {
        this.warenkorb_link = '/shop/warenkorb';
      }
    });
  }

  show() {
    this.open = true;
  }

  hide() {
    setTimeout(() => {
      this.open = false;
    }, 300);

  }

  show2() {
    this.open2 = true;
  }

  hide2() {
    setTimeout(() => {
      this.open2 = false;
    }, 300);
  }
}
