<tr *ngIf="title" class="table_sectiontitle">
  <td [attr.colspan]="actions ? 13 : 10">
    {{title}}
  </td>
</tr>
<tr class="thead" *ngIf="!ajax_mode && !(msvc.isMobile()|async)">
  <th>#</th>
  <th style="text-align: center">A</th>
  <th *ngFor="let fieldname of active_fields; trackBy: trackByValue" class="header_{{fieldname}}">
    <view-field-label-render [fieldname]="fieldname" mode="table"></view-field-label-render>
  </th>
  <th *ngIf="show_category">Kategorie</th>
  <th style="text-align: right; padding-right: 10px">Einzelpreis</th>
  <th></th>
  <th style="text-align: right">Summe</th>
  <th *ngIf="actions"></th>
  <th *ngIf="actions" style="width: 20px"></th>
</tr>
<tr *ngIf="positionen.length == 0" style="background-color: white">
  <td [attr.colspan]="actions ? (13+(show_category?1:0)) : (11+(show_category?1:0))" style="text-align: center;">
    <div style="vertical-align: middle; padding-top: 40px">
      <svg-icon style="width: 100px; height: 100px; font-size: 100px">traurig</svg-icon>
      <br>
      <div style="height: 100px; font-size: 16px; font-weight: bold">
        Der Warenkorb ist leer.
      </div>
    </div>
  </td>
</tr>
<ng-container *ngIf="ajax_mode || (msvc.isMobile() | async)">
  <ng-container *ngFor="let entry of positionen; let i = index; trackBy: trackById">
    <tr>
      <td style="white-space: normal">
        <table style="width: 100%">
          <tr>
            <td style="background-color: rgba(0,0,0,.05)">
              <view-field-value-render [artikel]="entry.artikel" [view]="entry.artikel.view" [mode]="'table'" fieldname="pzn"
                                       [nowrap]="false" append_if_not_empty=" - "
                                       [viewcomp]="this"></view-field-value-render>

              <view-field-value-render [artikel]="entry.artikel" [mode]="'table'" [view]="entry.artikel.view" [nowrap]="false"
                                       [current_warenkorb]="warenkorb"
                                       [viewcomp]="this"
                                       fieldname="bezeichnung"></view-field-value-render>

              <div style="width: 20px; height: 20px; display: inline-block">
                <div *ngIf="entry.dirty" matTooltip="Speichere...">
                  <mat-spinner [diameter]="20"></mat-spinner>
                </div>
              </div>
            </td>
            <td style="background-color: rgba(0,0,0,.05)">
              <shop-entry-edit-fields
                [entry]="positionen[i]"
                [artikel]="entry.artikel"
                [disableDialog]="true"
                [vertreterconfig]="vsvc.vertreterconfig"
                (entryChange)="updatePosition(i, $event)"
                [view_only]="!actions"
              ></shop-entry-edit-fields>
            </td>
          </tr>
          <tr *ngIf="!ajax_mode && (msvc.isMobile()|async)">
            <td colspan="2">
              <table style="width: 100%">
                <tr *ngFor="let fieldname of active_fields; trackBy: trackByValue" class="field_{{fieldname}}">
                  <th style="width: 1%; padding-right: 10px">
                    <view-field-label-render [fieldname]="fieldname" mode="table"></view-field-label-render>
                  </th>
                  <td>
                    <a
                      *ngIf="fieldname == 'bezeichnung'" routerLink="/shop/{{entry.view_slug}}/{{entry.artikel.ArtikelNummer}}">
                      <view-field-value-render
                        [artikel]="entry.artikel"
                        [view]="entry.artikel.view"
                        [viewcomp]="this"
                        [fieldname]="fieldname"
                        mode="table"
                      ></view-field-value-render>
                    </a>

                    <span *ngIf="entry.defect && fieldname == 'bezeichnung'" style="color: red; padding-left: 10px; cursor: help"
                          matTooltip="Bitte einen anderen Artikel wählen">DEFEKT</span>

                    <view-field-value-render
                      *ngIf="fieldname == 'bezeichnung'"
                      [artikel]="entry.artikel"
                      [view]="entry.artikel.view"
                      [viewcomp]="this"
                      [fieldname]="'note'"
                      mode="table"
                    >
                    </view-field-value-render>

                    <view-field-value-render
                      *ngIf="fieldname != 'bezeichnung'"
                      [artikel]="entry.artikel"
                      [view]="entry.artikel.view"
                      [viewcomp]="this"
                      [fieldname]="fieldname"
                      mode="table"
                    ></view-field-value-render>
                  </td>
                </tr>

                <tr *ngIf="show_category">
                  <th>Kategorie</th>
                  <td>
                    {{parent.getViewLabelBySlug(entry.view_slug)}}
                  </td>
                </tr>


                <tr>
                  <th>Einzelpreis</th>
                  <td>
                    {{ (entry.artikel.Kundeneinkaufspreis)|NiceNumber}} &euro;
                  </td>
                </tr>
                <tr>
                  <th>Summe</th>
                  <td>
                    {{ (entry.getSumme() )|NiceNumber}} &euro;
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr
            *ngIf="(visible_artikel_aktionen|async) && (visible_artikel_aktionen|async)?.artikel.ArtikelNummer == entry.artikel.ArtikelNummer"
            style="background-color: white">
            <td colspan="2" style="padding: 5px">
              <artikel-aktionen-details [aktionen]="visible_artikel_aktionen"></artikel-aktionen-details>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: center">
              <button (click)="parent.duplicatePosition(entry)" mat-icon-button class="tut_warenkorb_duplicate"
                      matTooltip="Position duplizieren">
                <svg-icon>zeile-duplizieren</svg-icon>
              </button>

              <button (click)="addNoteLine(entry)" mat-icon-button class="tut_warenkorb_artnote"
                      matTooltip="Textzeile hinzufügen" [disabled]="entry.note.length >= 28">
                <svg-icon>zeile-einfuegen</svg-icon>
              </button>

              <button (click)="parent.deletePosition(entry)" mat-icon-button class="tut_warenkorb_artdelete"
                      matTooltip="Position Entfernen">
                <svg-icon color="red">loeschen</svg-icon>
              </button>

              <verfuegbarkeits-info-button [artikel]="entry.artikel" [entry]="entry" class="tut_warenkorb_vf"
                                           [view_slug]="entry.view_slug"></verfuegbarkeits-info-button>

              <merkliste-button [artikel]="entry.artikel" [entry]="entry" *ngIf="actions" [view_slug]="entry.view_slug"></merkliste-button>
            </td>
          </tr>
          <tr *ngFor="let line of entry.note; let i = index; trackBy: parent.trackByFn">
            <td>
              <mat-checkbox [disabled]="!actions" [(ngModel)]="entry.print_note_lines[i]" matTooltip="Andrucken"
                            (ngModelChange)="parent.noteAutoSave(entry)">
                <span *ngIf="msvc.isMobile()|async">Andrucken</span>
              </mat-checkbox>
              <mat-form-field style="width: 100%">
                <input (focusout)="parent.noteAutoSave(entry)" [(ngModel)]="entry.note[i]" matInput maxlength="28"
                       placeholder="Zusatztext {{i+1}}"  class="nodeline nodeline_{{entry.id}}">
                <mat-hint>Maximal 28 Zeichen!</mat-hint>
              </mat-form-field>
            </td>
            <td>
              <button (click)="parent.deleteNoteLine(entry, i)" mat-icon-button matTooltip="Textzeile löschen">
                <svg-icon>zeile-loeschen</svg-icon>
              </button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </ng-container>
</ng-container>

<ng-container *ngIf="!ajax_mode && !(msvc.isMobile()|async)">
  <ng-container *ngFor="let entry of positionen; let i = index; trackBy: trackById">
    <tr>
      <td>
        {{i + 1}}
      </td>
      <td>

      </td>
      <td *ngFor="let fieldname of active_fields; trackBy: trackByValue" class="field_{{fieldname}}" style="white-space: nowrap">
        <a
          *ngIf="fieldname == 'bezeichnung'" routerLink="/shop/{{entry.view_slug}}/{{entry.artikel.ArtikelNummer}}">
          <view-field-value-render
            [artikel]="entry.artikel"
            [view]="entry.artikel.view"
            [viewcomp]="this"
            [fieldname]="fieldname"
            mode="table"
          ></view-field-value-render>
        </a>

        <view-field-value-render
          *ngIf="fieldname == 'bezeichnung'"
          [artikel]="entry.artikel"
          [view]="entry.artikel.view"
          [viewcomp]="this"
          [fieldname]="'note'"
          mode="table"
        >
        </view-field-value-render>

        <span *ngIf="entry.defect && fieldname == 'bezeichnung'" style="color: red; padding-left: 10px; cursor: help"
              matTooltip="Bitte einen anderen Artikel wählen">DEFEKT</span>

        <view-field-value-render
          *ngIf="fieldname != 'bezeichnung'"
          [artikel]="entry.artikel"
          [view]="entry.artikel.view"
          [viewcomp]="this"
          [fieldname]="fieldname"
          mode="table"
        ></view-field-value-render>
      </td>
      <td *ngIf="show_category">
        {{parent.getViewLabelBySlug(entry.view_slug)}}
      </td>
      <td style="text-align: right; padding-right: 10px">
        <view-field-value-render [artikel]="entry.artikel" [view]="entry.artikel.view" [mode]="'table'"
                                 fieldname="einkaufspreis"></view-field-value-render>
      </td>
      <td style="width: 1%; white-space: nowrap; padding-right: 10px">
        <shop-entry-edit-fields
          class="tut_warenkorb_menge"
          [entry]="positionen[i]"
          [artikel]="entry.artikel"
          [disableDialog]="true"
          [vertreterconfig]="vsvc.vertreterconfig"
          (entryChange)="updatePosition(i, $event)"
          [view_only]="!actions"
        ></shop-entry-edit-fields>
      </td>
      <td style="text-align: right">
        {{ (entry.getSumme() )|NiceNumber}} &euro;
      </td>
      <td *ngIf="actions" style="white-space: nowrap;">
        <button (click)="parent.duplicatePosition(entry)" class="tut_warenkorb_duplicate" mat-icon-button
                matTooltip="Position duplizieren">
          <svg-icon color="green">zeile-duplizieren</svg-icon>
        </button>

        <button (click)="addNoteLine(entry)" mat-icon-button class="tut_warenkorb_artnote"
                matTooltip="Textzeile hinzufügen">
          <svg-icon>zeile-einfuegen</svg-icon>
        </button>

        <button (click)="parent.deletePosition(entry)" mat-icon-button class="tut_warenkorb_artdelete"
                matTooltip="Position Entfernen">
          <svg-icon color="red">loeschen</svg-icon>
        </button>

        <verfuegbarkeits-info-button [artikel]="entry.artikel" [entry]="entry" class="tut_warenkorb_vf"
                                     [view_slug]="entry.view_slug"></verfuegbarkeits-info-button>

        <merkliste-button [artikel]="entry.artikel" [entry]="entry" *ngIf="actions" [view_slug]="entry.view_slug"></merkliste-button>
      </td>
      <td *ngIf="actions">
        <div style="width: 20px; height: 20px">
          <div *ngIf="entry.dirty" matTooltip="Speichere...">
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
        </div>
      </td>
    </tr>
    <tr *ngFor="let line of entry.note; let i = index; trackBy: parent.trackByFn">
      <td>

      </td>
      <td>
        <mat-checkbox [disabled]="!actions" [(ngModel)]="entry.print_note_lines[i]" matTooltip="Andrucken" (ngModelChange)="parent.noteAutoSave(entry)"></mat-checkbox>
      </td>
      <td *ngIf="actions" [attr.colspan]="show_category ? (active_fields.length+4):(active_fields.length+3)">
        <mat-form-field style="width: 100%">
          <input (focusout)="parent.noteAutoSave(entry)" [(ngModel)]="entry.note[i]" matInput maxlength="28"
                 placeholder="Zusatztext {{i+1}}" class="nodeline nodeline_{{entry.id}}">
          <mat-hint>Maximal 28 Zeichen!</mat-hint>
        </mat-form-field>
      </td>
      <td *ngIf="!actions" [attr.colspan]="show_category ? (active_fields.length+2):(active_fields.length+1)">
        {{entry.note[i]}}
      </td>
      <td colspan="2">
        <div *ngIf="actions">
          <button (click)="parent.deleteNoteLine(entry, i)" mat-icon-button matTooltip="Textzeile löschen">
            <svg-icon>zeile-loeschen</svg-icon>
          </button>
        </div>
      </td>
    </tr>
    <tr
      *ngIf="(visible_artikel_aktionen|async) && (visible_artikel_aktionen|async)?.artikel.ArtikelNummer == entry.artikel.ArtikelNummer"
      style="background-color: white">
      <td></td>
      <td [attr.colspan]="active_fields.length + 3" style="padding: 5px">
        <artikel-aktionen-details [aktionen]="visible_artikel_aktionen"></artikel-aktionen-details>
      </td>
      <td colspan="5"></td>
    </tr>
  </ng-container>
</ng-container>


<tr *ngIf="positionen.length > 0 && !ajax_mode && !(msvc.isMobile()|async)" class="spacer">
  <td [attr.colspan]="show_category ? (active_fields.length+5):(active_fields.length+4)"></td>
  <td colspan="3"></td>
</tr>
<tr *ngIf="positionen.length > 0 && !ajax_mode && !(msvc.isMobile()|async)">
  <td [attr.colspan]="show_category ? (active_fields.length+5):(active_fields.length+4)"></td>
  <td class="sumbox">
    {{summe|NiceNumber}} &euro; <br>
  </td>
  <td *ngIf="actions" colspan="2">

  </td>
</tr>

<tr *ngIf="positionen.length > 0 && !ajax_mode && (msvc.isMobile()|async)" class="spacer">
  <td></td>
</tr>
<tr *ngIf="positionen.length > 0 && !ajax_mode && (msvc.isMobile()|async)">
  <td>
    <div style="text-align: right">
      {{summe|NiceNumber}} &euro; <br>
    </div>
  </td>
</tr>
