import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {AppEventService} from '../../services/app.event.service';
import {AppUserService} from '../../services/app.user.service';

declare let navigator: any;

@Component({
  selector: 'app-page-loader',
  templateUrl: 'app.page-loader.component.html',
  styleUrls: ['app.page-loader.component.scss'],
})
export class AppPageLoaderComponent implements OnInit, OnDestroy {
  static id = 'AppPageLoaderComponent';
  isLoading = false;
  listener: Subscription;
  ltitlelistener: Subscription;
  ltextlistener: Subscription;
  lhastextlistener: Subscription;
  offlistener: Subscription;

  apioffline = false;
  offline = false;
  hasText = false;
  loading_text: string;
  loading_title: string;

  constructor(private loader: AppPageloaderService, private esvc: AppEventService, private usvc: AppUserService) {
  }

  ngOnDestroy(): void {
    if (this.listener instanceof Subscription) {
      this.listener.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.listener = this.loader.isLoading().subscribe(async state => {
      this.isLoading = await state;
    });

    this.offlistener = this.esvc.api_not_avaible$.subscribe(s => {
      if (this.apioffline && !s) {
        this.usvc.loadUser();
      }
      this.apioffline = s;
      if (!s) {
        this.checkoffline();
      }
    });

    this.ltitlelistener = this.loader.loading_title$.subscribe(t => {
      this.loading_title = t;
    });

    this.ltextlistener = this.loader.loading_text$.subscribe(t => {
      this.loading_text = t;
    });

    this.lhastextlistener = this.loader.has_text$.subscribe(s => {
      this.hasText = s;
    });
  }

  checkoffline() {
    if (navigator !== undefined && navigator.connection !== undefined) {
      if (navigator.connection.type == 'none') {
        this.offline = true;
      } else {
        this.offline = false;
      }
    } else {
      this.offline = false;
    }
  }


}
