import {Injectable} from '@angular/core';
import {
  ShopDialogsVertreterAddToWarenkorbComponent
} from '../modules/shop/dialogs/vertreter-add-to-warenkorb/shop.dialogs.vertreter-add-to-warenkorb.component';
import {Warenkorb} from '../models/warenkorb';
import {WarenkorbActionInfo} from '../models/warenkorb-action-info';
import {
  ShopWarenkorbActionInfoComponent
} from '../modules/shop/components/warenkorb-action-info/shop.warenkorb-action-info.component';
import {MatDialog} from '@angular/material/dialog';
import {AppWarenkorbService} from './app.warenkorb.service';
import {WarenkorbEntry} from '../models/warenkorb-entry';
import {AppPageloaderService} from './app.pageloader.service';
import {AppViewService} from './app.view.service';
import {AppDialogsService} from './app.dialogs.service';
import {MerklistEntry} from "../models/merklist-entry";
import {
  ShopDialogsNachliefernMultiFrageComponent
} from "../modules/shop/dialogs/nachliefern-multi-frage/shop.dialogs.nachliefern-multi-frage.component";
import {AppSnackbarService} from "./app.snackbar.service";
import {Nachfolgeartikel} from "../models/nachfolgeartikel";

@Injectable({providedIn: 'root'})
export class AppShopService {

  wkkref: any;

  constructor(private mdsvc: MatDialog, private wksvc: AppWarenkorbService, private loader: AppPageloaderService,
              private snackBarSvc: AppSnackbarService, private vsvc: AppViewService, private dsvc: AppDialogsService) {
  }

  addToPrimaryWarenkorb(entry: WarenkorbEntry | MerklistEntry, exec_after_add: any = null) {
    return this.addToWarenkorb(this.wksvc.primary_warenkorb.value, entry, exec_after_add);
  }

  _checkEntriesMessages(warenkorb: Warenkorb, toAddEntries: WarenkorbEntry[] | MerklistEntry[], messages: { title: string, msg: string, entry: WarenkorbEntry | MerklistEntry }[], exec_after_add: any = null) {
    if (messages && messages.length > 0) {
      let dialog = this.mdsvc.open(ShopDialogsNachliefernMultiFrageComponent,
        {
          data: messages
        });

      dialog.afterClosed().subscribe(entries => {
        if (entries) {
          entries.forEach(e => {
            toAddEntries.push(e);
          });

          this.do_addToWarenkorbMulti(warenkorb, toAddEntries, exec_after_add);
        }
      });
    } else {
      this.do_addToWarenkorbMulti(warenkorb, toAddEntries, exec_after_add);
    }
  }

  addToWarenkorbDialog(entry: WarenkorbEntry | MerklistEntry) {
    const ref = this.mdsvc.open(ShopDialogsVertreterAddToWarenkorbComponent, {
      data: entry
    });
    ref.afterClosed().subscribe(d => {
    });
  }

  addMultiToPrimaryWarenkorb(entries: WarenkorbEntry[] | MerklistEntry[], exec_after_add: any = null) {
    return this.addMultiToWarenkorb(this.wksvc.primary_warenkorb.value, entries, exec_after_add);
  }

  addMultiToWarenkorb(warenkorb: Warenkorb, entries: WarenkorbEntry[] | MerklistEntry[], exec_after_add: any = null) {
    const l = this.loader.start();

    let checkEntries = [];
    let toAddEntries = [];

    entries.forEach(entry => {
      if (entry.artikel.isVFStatusPruefbar()) {
        checkEntries.push(entry);
      } else {
        toAddEntries.push(entry);
      }
    });

    let checkDataEntry = [];
    let checkData = [];
    checkEntries.forEach(e => {
      let menge = 0;
      // tslint:disable-next-line:radix
      menge += parseInt(String(e.menge));
      // tslint:disable-next-line:radix
      menge += parseInt(String(e.freimenge));
      checkData.push({
        view_slug: e.view_slug,
        artikelnummer: e.artikel.ArtikelNummer,
        artikelmenge: menge,
        artikelstatuscode: e.artikel.VerfuegbarkeitsstatusCode,
      });
      checkDataEntry.push(e);
    });

    if (checkData.length > 0) {
      this.vsvc.multiVerfuegbarkeitBySlug(checkData).subscribe({
        next: (responses) => {
          let messages: { title: string, msg: string, entry: any }[] = [];

          responses.forEach((s, idx) => {
            let menge = checkData[idx].artikelmenge;
            let entry: any = checkEntries[idx];

            let nur_teilmenge = false;
            let moegliche_menge = 0;

            if (s.Verfuegbarkeitsinformation) {
              s.Verfuegbarkeitsinformation.forEach(i => {
                if (i.LieferungMoeglich) {
                  moegliche_menge += i.Menge;
                }
                if (i.InfoText == 'Teilmenge lagernd') {
                  nur_teilmenge = true;
                }
              });
            }

            let artlabel = entry.artikel.ArtikelBezeichnung;
            if (!artlabel) {
              artlabel = entry.artikel.ArtikelName;
            }

            if (moegliche_menge < menge || nur_teilmenge) {
              let title = '<b>' + artlabel + ' - ' + entry.menge + 'Stk </b>';
              let msg = '';

              if (nur_teilmenge) {
                msg += 'Es ist nur eine Teilmenge sofort lieferbar!';

              } else if (moegliche_menge > 0 && moegliche_menge >= menge) {
                msg = 'Es sind nur ' + moegliche_menge + ' lieferbar!';

              } else {
                msg = 'Kann besorgt werden!';
              }

              messages.push({
                title: title,
                msg: msg,
                entry: entry
              });

            } else {
              toAddEntries.push(entry)
            }
          });

          this._checkEntriesMessages(warenkorb, toAddEntries, messages, exec_after_add);

          l.stop();
        },
        error: (e) => {
          l.stop();
        }
      });
    } else {
      toAddEntries.forEach(entry => {
        this.do_addToWarenkorb(warenkorb, entry, exec_after_add);
      })
    }
  }

  addToWarenkorb(warenkorb: Warenkorb, entry: WarenkorbEntry | MerklistEntry, exec_after_add: any = null) {
    const l = this.loader.start();

    let menge = 0;
    // tslint:disable-next-line:radix
    menge += parseInt(String(entry.menge));
    // tslint:disable-next-line:radix
    menge += parseInt(String(entry.freimenge));

    if (entry.artikel.isVFStatusPruefbar()) {
      this.vsvc.verfuegbarkeitBySlug(entry.view_slug, entry.artikel, menge).subscribe({
        next: (s) => {
          let nur_teilmenge = false;
          let moegliche_menge = 0;
          let not_allowed = false;
          let not_allowed_text = null;
          let nachfolgeArtikel: Nachfolgeartikel = null;

          if (s.Nachfolgeartikel) {
            nachfolgeArtikel = s.Nachfolgeartikel;
          }

          if (s.Verfuegbarkeitsinformation) {
            s.Verfuegbarkeitsinformation.forEach(i => {
              if (i.LieferungMoeglich) {
                moegliche_menge += i.Menge;
              }
              if (i.InfoText == 'Teilmenge lagernd') {
                nur_teilmenge = true;
              }
              if (i.InfoCode == 'AP') {
                not_allowed = true;
                not_allowed_text = i.InfoText;
              }
            });
          }

          let artlabel = entry.artikel.ArtikelBezeichnung;
          if (!artlabel) {
            artlabel = entry.artikel.ArtikelName;
          }

          if (nachfolgeArtikel) {
            this.dsvc.confirm(
              'warning',
              'Nachfolgeartikel verfügbar!',
              'Wollen Sie den Nachfolgeartikel <strong>'+nachfolgeArtikel.ArtikelName+'</strong> in den Warenkorb legen?<br><br>'+
              '<div style="text-align: center">EP: '+nachfolgeArtikel.Kundeneinkaufspreis+'&euro;</div>',
              () => {
                let nachfolgeEntry = new WarenkorbEntry();
                Object.assign(nachfolgeEntry, entry);
                nachfolgeEntry.artikel = nachfolgeArtikel;
                this.do_addToWarenkorb(warenkorb, nachfolgeEntry, exec_after_add);
              }
            );

          } else if (not_allowed) {
            this.dsvc.info(
              'error',
              'Sie haben leider Keine Berechtigung für diesen Artikel!',
              not_allowed_text
            );

          } else if (moegliche_menge < menge || nur_teilmenge) {
            let title = '';
            let msg = '';

            if (nur_teilmenge) {
              title = 'Nicht vollständig lieferbar!';
              msg = 'Es ist nur eine Teilmenge sofort lieferbar! Trotzdem in den Warenkorb legen?';

            } else if (moegliche_menge > 0 && moegliche_menge >= menge) {
              title = 'Nicht vollständig lieferbar!';
              msg = 'Es sind nur ' + moegliche_menge + ' ' + entry.artikel.Packungseinheit + ' lieferbar! Trotzdem in den Warenkorb legen?';

            } else {
              title = 'Nicht auf Lager!';
              msg = 'Die gewünschte Menge von <hr><b>' +
                artlabel + ' - ' + entry.menge + 'Stk </b><hr> kann besorgt werden! Trotzdem in den Warenkorb legen?';
            }

            this.dsvc.confirm(
              'warning',
              title,
              msg,
              () => {
                this.do_addToWarenkorb(warenkorb, entry, exec_after_add);
              }
            );

          } else {
            this.do_addToWarenkorb(warenkorb, entry, exec_after_add);
          }
          l.stop();
        },
        error: (e) => {
          this.dsvc.confirm(
            'warning',
            'Verfügbarkeitsabfrage derzeit nicht möglich',
            'Trotzdem in den Warenkorb legen?',
            () => {
              this.do_addToWarenkorb(warenkorb, entry, exec_after_add);
            }
          );
          l.stop();
        }
      });
    } else {
      this.do_addToWarenkorb(warenkorb, entry, exec_after_add);
    }
  }

  private do_addToWarenkorbMulti(warenkorb: Warenkorb, entries: WarenkorbEntry[] | MerklistEntry[], exec_after_add: any = null) {
    const l = this.loader.start();

    if (warenkorb && entries.length > 0) {
      this.wksvc.addMultiToWarenkorbById(
        warenkorb.id,
        entries
      ).subscribe({
        next: (msgs) => {
          l.stop();

          entries.forEach(e => {
            e.menge = 1;
          });

          msgs.forEach(m => {
            const i = new WarenkorbActionInfo();
            i.action = 'add';
            i.info = m;

            this.snackBarSvc.open(
              ShopWarenkorbActionInfoComponent,
              i,
              2500,
              );
          });

          if (exec_after_add) {
            exec_after_add();
          }

        }, error: () => {
          l.stop();
        }
      });
    } else if (entries.length == 0) {
      this.snackBarSvc.openMessage('Nichts hinzuzufügen!');
      l.stop();

    } else {
      this.snackBarSvc.openMessage('Fehlgeschlagen. Kein Warenkorb ausgewählt!');
      l.stop();
    }
  }

  private do_addToWarenkorb(warenkorb: Warenkorb, entry: WarenkorbEntry | MerklistEntry, exec_after_add: any = null) {
    const l = this.loader.start();

    if (warenkorb) {
      this.wksvc.addToWarenkorbById(
        warenkorb.id,
        entry.view_slug,
        entry
      ).subscribe({
        next: (m) => {
          l.stop();

          entry.menge = 1;

          const i = new WarenkorbActionInfo();
          i.action = 'add';
          i.info = m;

          if (exec_after_add) {
            exec_after_add();
          }

          this.snackBarSvc.open(ShopWarenkorbActionInfoComponent, i, 2500);
        }, error: () => {
          l.stop();
        }
      });
    } else {
      this.snackBarSvc.openMessage('Fehlgeschlagen. Kein Warenkorb ausgewählt!');
    }
  }
}
