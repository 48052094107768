export class Tutorialstep {
  focus_on: string;
  text: string;
  icon: string;
  roles: string[];

  constructor(focus_on, text, icon = null, roles = []) {
    this.focus_on = focus_on;
    this.text = text;
    this.icon = icon;
    this.roles = roles;
  }
}
