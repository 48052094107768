<ng-container *ngIf="config.children && config.children.length && config.children.length > 0">
  <div class="sub-menu-button-box">
    <div class="sub-menu-button-box-header" *ngIf="config.label">
      <div class="icon">
        <svg-icon [size]="40">{{config.icon}}</svg-icon>
      </div>
      {{config.label}}
    </div>

    <ng-container *ngFor="let childConfig of config.children">
      <div class="childbox">
        <menu-button
          [name]="childConfig.id"
          [config]="childConfig"
          [class]="childConfig.class_fkt ? childConfig.class_fkt(cfg) : childConfig.class"
        ></menu-button>
      </div>
    </ng-container>

  </div>
</ng-container>

<ng-container *ngIf="!config.children || config.children.length && config.children.length == 0">
  <ng-container *ngIf="isView |async">
    <ng-container *ngIf="config.id == 'view_none_found'">
      <div style="padding: 5px">
        <info-box type="shop_menu_no_views"></info-box>
      </div>
    </ng-container>
    <ng-container *ngIf="config.id != 'view_none_found'">
      <menu-button-elem [id]="config.id" [icon_size]="small ? 30 : 40"
                        [view]="config.view" [class]="config.class"
      ></menu-button-elem>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!(isView|async)">
    <menu-button-elem [id]="config.id" [icon_size]="small ? 30 : 40" [routerLink]="config.route" class="{{class}}"
                      [icon]="config.icon" [label]="config.label"
                      [description]="config.description"
                      [href]="config.href"
                      [target]="config.target"
                      [roles]="config.roles"
                      [features]="config.features"
    ></menu-button-elem>
  </ng-container>
</ng-container>
