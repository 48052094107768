import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppPageloaderService} from './app.pageloader.service';
import {AppEventService} from './app.event.service';
import {AppLogService} from './app.log.service';
import {ViewResult} from '../models/view-result';
import {View} from '../models/view';
import {ViewFilter} from '../models/view-filter';
import {Artikel} from '../models/artikel';
import {ArtikelVerfuegbarkeit} from '../models/artikel-verfuegbarkeit';
import {Nachfolgeartikel} from '../models/nachfolgeartikel';
import {Verfuegbarkeitsinformation} from '../models/verfuegbarkeitsinformation';
import {AntwortText} from '../models/antwort-text';
import {map, takeUntil} from 'rxjs/operators';
import {ArtikelAktion} from '../models/artikel-aktion';
import {AppSettingsService} from './app.app_settings.service';
import {AppUserService} from "./app.user.service";
import {DomainVertreterConfig} from "../models/domain-vertreter-config";

@Injectable({providedIn: 'root'})
export class AppViewService {
  menu_views = new BehaviorSubject<View[]>(null);
  menu_views$: Observable<View[]> = this.menu_views.asObservable();
  menu_views_loaded = new BehaviorSubject<boolean>(false);
  vertreterconfig: BehaviorSubject<DomainVertreterConfig>;
  $vertreterconfig: Observable<DomainVertreterConfig>;
  cfg_gui_table_columns = new BehaviorSubject<string[][]>(null);
  cfg_gui_box_columns = new BehaviorSubject<string[][]>(null);
  loaded = new BehaviorSubject<boolean>(false);
  private views = new BehaviorSubject<View[]>(null);
  views$: Observable<View[]> = this.views.asObservable();

  loadingViews = false;

  loadingViews1done = false;
  loadingViews2done = false;

  initLoaded = false;

  constructor(private http: HttpClient, private cfg: AppConfigService, private loader: AppPageloaderService,
              private esv: AppEventService, private logsvc: AppLogService, private sett: AppSettingsService, private usvc: AppUserService) {
    this.usvc.user$
      .subscribe(u => {
        if (u && u.id) {
          this.load();
        }
      });

    this.vertreterconfig = this.cfg.vertreterconfig;
    this.$vertreterconfig = this.cfg.$vertreterconfig;

    this.esv.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\LieferkundeEngine\\LieferkundeChangedEvent' ||
        e.name == 'App\\Events\\ShopViewSettingsEngine\\ShopViewSettingsChangedEvent') {
        if (this.initLoaded) {
          this.load();
        }

      } else if (e.name == 'App\\Events\\SettingsEngine\\SettingsChangedEvent' &&
        e.data.id == 'App\\Model\\Settings\\Definition\\User\\GUI_ShopViewTableColumns') {
        this.loadGUI_ShopViewTableColumns();

      } else if (e.name == 'App\\Events\\SettingsEngine\\SettingsChangedEvent' &&
        e.data.id == 'App\\Model\\Settings\\Definition\\User\\GUI_ShopViewBoxColumns') {
        this.loadGUI_ShopViewBoxColumns();

      } else if (e.name == 'gui.impersonate_start' || e.name == 'gui.depersonate_start') {
        this.loadGUI_ShopViewTableColumns();
        this.loadGUI_ShopViewBoxColumns();
        this.load();

      } else if (e.name == 'gui.lieferKundeSelected') {
        this.load();
      }
    });

    this.loadGUI_ShopViewTableColumns();
    this.loadGUI_ShopViewBoxColumns();
  }

  reloadAdminViews(): Observable<boolean> {
    return this.http.get<boolean>(
      '/admin/shop/views/reload'
    );
  }

  loadGUI_ShopViewTableColumns() {
    this.sett.getUserSettingValue('GUI_ShopViewTableColumns').subscribe(s => {
      this.cfg_gui_table_columns.next(s);
    });
  }

  loadGUI_ShopViewBoxColumns() {
    this.sett.getUserSettingValue('GUI_ShopViewBoxColumns').subscribe(s => {
      this.cfg_gui_box_columns.next(s);
    });
  }

  getViewBySlug(slug: string): View {
    let retv: any = false;

    if (this.views.getValue()) {
      this.views.getValue().forEach(v => {
        if (v.slug == slug) {
          retv = v;
        }
      });
    }
    if (!retv) {
      return new View();
    } else {
      return retv;
    }
  }

  reload() {
    let l = this.loader.start();
    this.refresh().subscribe(s => {
      this.log('Refreshing Views');
      this.load();
      l.stop();
    })
  }


  _checkLoadingViews() {
    if (this.loadingViews1done == true && this.loadingViews2done == true) {
      this.loadingViews = false;
      this.loadingViews1done = false;
      this.loadingViews2done = false;
      this.initLoaded = true;
    }
  }

  load() {
    if (!this.loadingViews) {
      this.loadingViews = true;
      this.log('Loading Views');
      this.loader.start('ViewService_1');
      this.getAll().subscribe(vs => {
        this.views.next(vs);
        this.loader.stop('ViewService_1');
        this.loaded.next(true);
        this.loadingViews1done = true;
        this._checkLoadingViews();
      });

      this.loader.start('ViewService_3');
      this.getAllMenu().subscribe(vs => {
        this.menu_views.next(vs);
        this.menu_views_loaded.next(true);
        this.loader.stop('ViewService_3');
        this.loadingViews2done = true;
        this._checkLoadingViews();
      });
    }
  }

  getMenuViewBySlug(slug: string): View | boolean {
    let view: View | boolean = false;
    if (this.menu_views && this.menu_views.value) {
      this.menu_views.value.forEach(v => {
        if (v.slug == slug) {
          view = v;
        }
      });
    }
    return view;
  }

  getBySlug(slug: string): Observable<View> {
    return this.http.get<View>(
      '/shop/views/' + slug
    ).pipe(map(v => {
      this.hydrateView(v);
      return v;
    }));
  }

  getAktionenSlug(slug: string, artikel: Artikel): Observable<ArtikelAktion[]> {
    return this.http.get<ArtikelAktion[]>(
      '/shop/views/' + slug + '/aktionen/' + artikel.ArtikelNummer
    ).pipe(map(v => {
      if (v) {
        v.forEach(a => {
          Object.setPrototypeOf(a, ArtikelAktion.prototype);

          if (a.Draufgabeartikel) {
            Object.setPrototypeOf(a.Draufgabeartikel, Artikel.prototype);
          }
        });
      }
      return v;
    }));
  }

  getOneById(slug: string, artikel_id: string): Observable<Artikel> {
    return this.http.get<Artikel>(
      '/shop/views/' + slug + '/article/' + artikel_id,
    ).pipe(map(res => {
      Object.setPrototypeOf(res, Artikel.prototype);

      if (res.zubehoer) {
        res.zubehoer.forEach(z => {
          Object.setPrototypeOf(z.ZubehoerArtikel, Artikel.prototype);
        })
      }

      return res;
    }));
  }

  queryBySlug(slug: string, page_number: number, page_size: number, filters: string[], search: string): Observable<ViewResult> {
    return this.http.post<ViewResult>(
      '/shop/views/' + slug + '/query',
      {
        page_number: page_number,
        page_size: page_size,
        filters: filters,
        search: search,
      }
    ).pipe(map(res => {
      this.prototypeViewResult(res);

      return res;
    }));
  }

  multiVerfuegbarkeitBySlug(request: {
    artikelnummer: number,
    artikelmenge: number,
    artikelstatuscode: string,
    view_slug: string
  }[]): Observable<ArtikelVerfuegbarkeit[]> {
    return this.http.post<ArtikelVerfuegbarkeit[]>(
      '/shop/views/verfuegbarkeitMulti',
      {
        request: request
      }
    ).pipe(map(vs => {
      if (vs) {
        vs.forEach(v => {
          if (v) {
            Object.setPrototypeOf(v, ArtikelVerfuegbarkeit.prototype);
            if (v.Nachfolgeartikel) {
              Object.setPrototypeOf(v.Nachfolgeartikel, Nachfolgeartikel.prototype);
            }
            if (v.Verfuegbarkeitsinformation) {
              v.Verfuegbarkeitsinformation.forEach(vi => {
                Object.setPrototypeOf(vi, Verfuegbarkeitsinformation.prototype);

                if (vi.Zusatzinformation) {
                  vi.Zusatzinformation.forEach(zi => {
                    Object.setPrototypeOf(zi, AntwortText);
                  });
                }
              });
            }
          }
        });
      }
      return vs;
    }));
  }

  verfuegbarkeitBySlug(slug: string, artikel: Artikel, artikelmenge: number): Observable<ArtikelVerfuegbarkeit> {
    return this.http.post<ArtikelVerfuegbarkeit>(
      '/shop/views/' + slug + '/verfuegbarkeit',
      {
        artikelnummer: artikel.ArtikelNummer,
        artikelmenge: artikelmenge,
        artikelstatuscode: artikel.VerfuegbarkeitsstatusCode,
      }
    ).pipe(map(v => {
      if (v) {
        Object.setPrototypeOf(v, ArtikelVerfuegbarkeit.prototype);
        if (v.Nachfolgeartikel) {
          Object.setPrototypeOf(v.Nachfolgeartikel, Nachfolgeartikel.prototype);
        }
        if (v.Verfuegbarkeitsinformation) {
          v.Verfuegbarkeitsinformation.forEach(vi => {
            Object.setPrototypeOf(vi, Verfuegbarkeitsinformation.prototype);

            if (vi.Zusatzinformation) {
              vi.Zusatzinformation.forEach(zi => {
                Object.setPrototypeOf(zi, AntwortText);
              });
            }
          });
        }
      }
      return v;
    }));
  }

  private prototypeViewResult(res: ViewResult) {
    Object.setPrototypeOf(res, ViewResult.prototype);
    if (res.data) {
      res.data.forEach(a => {
        Object.setPrototypeOf(a, Artikel.prototype);
      });
    }
  }

  private log(message, ...optionalParams: any[]) {
    this.logsvc.consoleLog(this, message, ...optionalParams);
  }

  private hydrateViewFilter(filter: ViewFilter) {
    Object.setPrototypeOf(filter, ViewFilter.prototype);

    if (filter.children && filter.children.length > 0) {
      filter.children.forEach(c => {
        this.hydrateViewFilter(c);
      });
    }
  }

  private hydrateView(view: View) {
    Object.setPrototypeOf(view, View.prototype);
    if (view.filters) {
      view.filters.forEach(f => {
        this.hydrateViewFilter(f);
      });
    }
  }

  private refresh(): Observable<boolean> {
    return this.http.get<boolean>(
      '/shop/views/refresh'
    );
  }

  private getAll(): Observable<View[]> {
    return this.http.get<View[]>(
      '/shop/views/getAll'
    ).pipe(map(vs => {
      vs.forEach(v => {
        this.hydrateView(v);
      });
      return vs;
    }));
  }

  private getAllAdmin(): Observable<View[]> {
    return this.http.get<View[]>(
      '/admin/shop/views/getAll'
    ).pipe(map(vs => {
      vs.forEach(v => {
        this.hydrateView(v);
      });
      return vs;
    }));
  }

  private getAllMenu(): Observable<View[]> {
    return this.http.get<View[]>(
      '/shop/views/getMenu'
    ).pipe(map(vs => {
      vs.forEach(v => {
        this.hydrateView(v);
      });
      return vs;
    }));
  }
}
