import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {AppEventService} from '../services/app.event.service';
import {Router} from '@angular/router';
import {AppLogService} from '../services/app.log.service';
import {AppDialogsService} from '../services/app.dialogs.service';
import * as Sentry from '@sentry/angular-ivy';
import {SentryErrorHandler} from '@sentry/angular-ivy';
import {AppConfigService} from '../services/app.config.service';

declare let window: any;

@Injectable()
export class AppHelpersErrorHandler implements ErrorHandler {
  dsvc: AppDialogsService;

  sentryErrorHandler: SentryErrorHandler | any;

  constructor(private esvc: AppEventService, private injector: Injector, private logsvc: AppLogService,
              private cfg: AppConfigService) {
    this.dsvc = this.injector.get(AppDialogsService);
    this.sentryErrorHandler = Sentry.createErrorHandler({
      showDialog: false,
      logErrors: false,
    });
  }

  handleError(error: any) {
    const router = this.injector.get(Router);
    // Do whatever you like with the error (send it to the server?)
    // And log it to the console


    if (error.message && error.message.match(/.*index_app\.html.*/) ||
      (error.message == 'Cannot read property \'match\' of null ' && error.stack.match('/.*android_asset.*/'))
    ) {
      router.navigateByUrl('/');

    } else {
      if (error.error && error.error.code) {
        if (error.error.code == 10001) {
          if (router.url != '/login') {
            router.navigateByUrl('/logout');
          }
        }
      } else {
        console.error(error);

        if (error.message.includes('ChunkLoadError')) {
          window.location.reload();
        }

        //this.logsvc.logError(error.message, window.location.href, error.stack, error);

        if (error.message.includes('Cannot match any routes')) {
          router.navigateByUrl('/');
        }
      }
    }

    if (!this.cfg.isDebug()) {
      if (!(error.error && error.error.code) && error instanceof Error) {
        let skipSentry = false;
        if (error && error.message && error.message.includes('Cannot match any routes')) {
          skipSentry = true;
        }

        if (!skipSentry) {
          this.sentryErrorHandler.handleError(error);
        }
      }
    }
  }
}
