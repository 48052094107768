import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'nl2br'})
export class SharedPipeNl2brPipePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(/\n/g, '<br/>');
    } else {
      return value;
    }
  }
}
