import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

declare let navigator: any;

@Injectable({providedIn: "root"})
export class AppScannerService {
  scanCommand = new BehaviorSubject(null);

  hasCam = new BehaviorSubject(false);

  startScan(returnFunction) {
    this.scanCommand.next(returnFunction);
  }

  boot() {
    navigator.mediaDevices.enumerateDevices().then(tt => {
      let hasVideo = false;
      tt.forEach(t => {
        if (t.kind == 'videoinput') {
          hasVideo = true;
        }
      })
      if (hasVideo) {
        this.hasCam.next(true);
      }
    });
  }
}
