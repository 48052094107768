import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Artikel} from '../../../../models/artikel';

@Component({
  selector: 'artikel-status-icon',
  templateUrl: 'shop.artikel-status-icon.component.html',
  styleUrls: ['shop.artikel-status-icon.component.scss']
})
export class ShopArtikelStatusIconComponent implements OnChanges, OnInit {
  @Input() artikel: Artikel;

  @Input() showTooltip = true;

  color = '';

  fixState() {
    if (this.artikel) {


      if (this.artikel.Artikelstamm != 'DE' || (this.artikel.Artikelstamm == 'DE' &&
        // @ts-ignore
        (this.artikel.ArtikelNummer != null && (this.artikel.PZN != '' || this.artikel.PZN != '0000000')))) {
        if (this.artikel.VerfuegbarkeitsstatusCode == '0G') {
          this.color = 'green';

        } else if (this.artikel.VerfuegbarkeitsstatusCode == 'NL') {
          this.color = 'orange';
        } else {
          this.color = 'red';
        }
      } else {
        this.color = 'blue';

      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fixState();
  }

  ngOnInit(): void {
    this.fixState();
  }


}
