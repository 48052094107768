import {User} from './user';
import {Usersessionwindow} from './usersessionwindow';

export class Usersession {
  id: string;
  ip: string;
  user: User;
  window_count: number;
  appversion: string;
  useragent: string;
  inpersonate_user: User;
  windows: Usersessionwindow[];
}
