<div class="search-box">
  <mat-form-field appearance="outline" [class]="formFieldClass">
    <mat-label>Suche</mat-label>
    <input #searchinput (keydown.enter)="do_search($event)" (keydown)="keyDown($event)" [(ngModel)]="value" ngDefaultControl (keyup)="change()" autofocus matInput>
  </mat-form-field>
  <button (click)="reset()" color="primary" class="reset-button" mat-icon-button matTooltip="Suchen" *ngIf="has_search">
    <svg-icon>error-clean</svg-icon>
  </button>
  <button (click)="startScan()" mat-flat-button class="middlebutton" matTooltip="Scanner starten" *ngIf="scannerEnabled && (hasScan|async)">
    <svg-icon>datamatrix</svg-icon>
  </button>
  <button (click)="do_search()" [class]="buttonClass" mat-flat-button matTooltip="Suchen"
      [disabled]="!searchReady">
    <svg-icon>suche</svg-icon>
  </button>

</div>
<div class="search-info-box" [class.newline]="infoTextInNewline" *ngIf="infoText">
  <svg-icon>info</svg-icon> {{infoText}}
</div>
