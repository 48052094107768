import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {BarcodeDecoderService} from "../../../../services/app.barcode-decoder.service";
import { BarcodeFormat } from '@zxing/library';
import {BehaviorSubject, Subject} from "rxjs";
import {AppScannerService} from "../../../../services/app.scanner.service";
import {takeUntil} from "rxjs/operators";
import {AppMessageService} from "../../../../services/app.message.service";
import {AppAsyncTimeout} from "../../../../helpers/app.asyncTimeout";

@Component({
  selector: 'scanner',
  templateUrl: 'shared.scanner.component.html',
  styleUrls: ['shared.scanner.component.scss']
})
export class SharedScannerComponent implements OnInit, OnDestroy {
  showScanner = new BehaviorSubject(false);
  bcFound = new BehaviorSubject(false);

  formats = [
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.CODE_128
  ];

  videoConstraints: MediaTrackConstraints = {};

  _onDestroy = new Subject();

  constructor(private dec: BarcodeDecoderService, private svc: AppScannerService, private msg: AppMessageService) {
  }

  noCam() {
    this.msg.error("Es wurde keine Kamera erkannt!");

    this.endScan();
  }

  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this.svc.boot();
    this.svc.scanCommand.pipe(takeUntil(this._onDestroy)).subscribe(cmd => {
      if (cmd) {
        this.startScan();
      }
    })
  }

  startScan() {
    this.showScanner.next(true);
  }

  endScan() {
    this.showScanner.next(false);
    this.bcFound.next(false);
  }

  triggerFound(data) {
    if (this.svc.scanCommand.value) {
      this.svc.scanCommand.value(data);
    }
    this.bcFound.next(true);
    AppAsyncTimeout.setTimeout(() => {
      this.endScan();
    }, 500);
  }

  scanComplete(result) {
    if (result) {
      if (result.format == BarcodeFormat.DATA_MATRIX) {
        if (result.text) {

          let tt = this.dec.decode(result.text);
          this.triggerFound(tt);
        }
      } else {
        this.triggerFound(result.text);
      }
    }
  }

}
