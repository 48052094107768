import {Datamatrix} from "./datamatrix";

export class IFADataMatrix implements Datamatrix {
    barcodes: any[] = [];
    LOT: any;
    EXP: any;
    SN: any;

    constructor(string: string) {

        string = string.replace("\x1E\x04","");
        let match = string.split("\x1D");

        if (match.length > 1) {
            match.splice(0, 1);

            match.forEach(e => {
                this.processPart(e);
            });
        }
    }

    processPart(string: string){
        if (string.match(/^9N(.*)/)) {
            let match = string.match(/9N(.*)/);
            this.barcodes.push(match[1]);
        }
        if (string.match(/^8P(.*)/)) {
            let match = string.match(/^8P(.*)/);
            this.barcodes.push(match[1]);
        }
        if (string.match(/^1T(.*)/)) {
            let match = string.match(/^1T(.*)/);
            this.LOT = match[1];
        }
        if (string.match(/^D(.*)/)) {
            let match = string.match(/^D(.*)/);
            let date = match[1].match(/^([0-9]{2})([0-9]{2})([0-9]{2})$/);
            this.EXP = date[3] + '.' + date[2] + '.' + '20' + date[1];
        }
        if (string.match(/^S(.*)/)) {
            let match = string.match(/S(.*)/);
            this.SN = match[1];
        }
    }

    getType() {
        return "IFA";
    }

    getBarcodes(): string[] {
        return this.barcodes;
    }

    getExp(): string {
        return this.EXP;
    }

    getSN(): string {
        return this.SN;
    }

    getLOT(): string {
        return this.LOT;
    }
}
