import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AppConfigService} from "../../../../services/app.config.service";


@Component({
  selector: 'feature-block',
  templateUrl: 'shared.feature-block.component.html'
})
export class SharedFeatureBlockComponent implements OnInit, OnDestroy {
  @Input() features: string[] = [];

  usub: Subscription;
  visible = new BehaviorSubject<boolean>(false);

  constructor(private cfg: AppConfigService) {
  }

  ngOnDestroy(): void {
    if (this.usub instanceof Subscription) {
      this.usub.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.features.length > 0) {
      this.visible.next(this.cfg.featuresEnabled(this.features));
    } else {
      this.visible.next(true);
    }
  }
}
