import {Component, Input, ViewChild} from '@angular/core';
import {AppTutorialsComponent} from '../tutorials/app.tutorials.component';
import {AppWalkthroughComponent} from '../walkthrough/app.walkthrough.component';

@Component({
  selector: 'app-tutorial-step',
  templateUrl: 'app.tutorial-step.component.html'
})
export class AppTutorialStepComponent {
  static id = 'AppTutorialStepComponent';
  @Input() tutorial: AppTutorialsComponent;
  @Input() text: string;
  @Input() focus_on: string;
  @Input() icon: string;

  @ViewChild('walkthrough') walkthrough: AppWalkthroughComponent;

  visible = true;

  setSeen() {
    this.tutorial.setSeen();
  }
}
