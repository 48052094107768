import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable({providedIn: 'root'})
export class AppMainMenuButtonService {
  lock_clicked = false;
  private currentMenuPath = '/menu';
  private active = new BehaviorSubject(false);
  active$ = this.active.asObservable();

  constructor(private router: Router, private location: Location) {
  }

  setMenuPath(path: string) {
    this.currentMenuPath = path;
  }

  setActive() {
    this.active.next(true);
  }

  setInactive() {
    this.active.next(false);
  }

  click() {
    if (this.active.getValue() == true) {
      this.location.back();
    } else {
      if (this.currentMenuPath) {
        this.router.navigateByUrl(this.currentMenuPath);
      }
    }
  }
}
