<div class="shop-warenkorb-entry-editor" *ngIf="editEntry">
  <permission-block [oneOfRoles]="['Vertreter']">
    <div style="display: inline-block" class="tut_wkpos_vertr"
         *ngIf="(
         (vertreterconfig|async)?.DomainVertreterShowPositionsart ||
         (vertreterconfig|async)?.DomainVertreterShowFreimenge ||
         (vertreterconfig|async)?.DomainVertreterShowAktionsrabattmenge ||
         (vertreterconfig|async)?.DomainVertreterShowAktionsrabattprozent ||
         (vertreterconfig|async)?.DomainVertreterShowBasisverrechnungspreis
         )
         && (disableDialog || !((vertreterconfig|async)?.DomainVertreterUseDialog))"
    >
      <mat-form-field class="positionsartinput" appearance="outline"
                      *ngIf="(vertreterconfig|async)?.DomainVertreterShowPositionsart && (disableDialog || !((vertreterconfig|async)?.DomainVertreterUseDialog))">
        <mat-label>PA</mat-label>
        <mat-select [(value)]="editEntry.positionsart" matTooltip="Positionsart" (change)="change()"
                    [disabled]="disabled">
          <mat-option [value]="'Standard'">Standard</mat-option>
          <mat-option [value]="'OhneVerrechnung'">Ohne Verrechnung</mat-option>
          <mat-option [value]="'NurVerrechnung'">Nur Verrechnung</mat-option>
        </mat-select>
      </mat-form-field>

      <nice-number-input
        #inputFreimenge
        *ngIf="(vertreterconfig|async)?.DomainVertreterShowFreimenge && (disableDialog || !((vertreterconfig|async)?.DomainVertreterUseDialog))"
        label="FM" tooltip="Freimenge (0-x)" [disabled]="disabled"
        [step]="1" [min]="0" [max]="null"
        class="freimengeinput" [(value)]="editEntry.freimenge"
        (change)="change()"></nice-number-input>

      <nice-number-input
        #inputAktionsrabattmenge
        *ngIf="(vertreterconfig|async)?.DomainVertreterShowAktionsrabattmenge && (disableDialog || !((vertreterconfig|async)?.DomainVertreterUseDialog))"
        label="AM" tooltip="Aktionsrabatt Menge (0-x)" [disabled]="disabled"
        [step]="1" [min]="0" [max]="null"
        class="aktionsrabattprozentinput" [(value)]="editEntry.aktionsrabattmenge"
        (change)="change()"></nice-number-input>

      <nice-number-input
        #inputNaturalrabattmenge
        *ngIf="(vertreterconfig|async)?.DomainVertreterShowNaturalrabattmenge && (disableDialog || !((vertreterconfig|async)?.DomainVertreterUseDialog))"
        label="NM" tooltip="Naturalrabatt Menge (0-x)" [disabled]="disabled"
        [step]="1" [min]="0" [max]="null"
        class="naturalsrabattmengeinput" [(value)]="editEntry.naturalrabattmenge"
        (change)="change()"></nice-number-input>

      <nice-number-input
        #inputAktionsrabattprozent
        *ngIf="(vertreterconfig|async)?.DomainVertreterShowAktionsrabattprozent && (disableDialog || !((vertreterconfig|async)?.DomainVertreterUseDialog))"
        label="AP" tooltip="Aktionsrabatt Prozent (0-100)" [disabled]="disabled"
        [step]="1" [min]="0" [max]="100"
        class="aktionsrabattprozentinput" [(value)]="editEntry.aktionsrabattprozent"
        (change)="change()"></nice-number-input>

      <nice-number-input
        #inputBasisverrechnungspreisprozent
        *ngIf="(vertreterconfig|async)?.DomainVertreterShowBasisverrechnungspreisRabattProzent && (disableDialog || !((vertreterconfig|async)?.DomainVertreterUseDialog))"
        label="Rabatt" tooltip="Rabatt Prozent (0-100)" [disabled]="disabled"
        [step]="1" [min]="0" [max]="100"
        class="basisverrechnungspreisprozentinput" [(value)]="editEntry.basisverrechnungspreisrabattprozent"
        (change)="change()"></nice-number-input>

      <nice-number-input
        #inputBasisverrechnungspreis
        *ngIf="(vertreterconfig|async)?.DomainVertreterShowBasisverrechnungspreis && (disableDialog || !((vertreterconfig|async)?.DomainVertreterUseDialog))"
        label="BVP" tooltip="Basisverrechnungspreis (0-x)" [disabled]="disabled"
        [step]="0.01" [min]="0" [max]="null"
        class="basisverrechnungspreisinput" [(value)]="editEntry.basisverrechnungspreis"
        (change)="change()"></nice-number-input>

    </div>


  </permission-block>

  <nice-number-input label="Menge" tooltip="Menge (0-x)"
                     #inputMenge
                     [step]="1" [min]="0" [max]="null" [disabled]="disabled"
                     class="mengeinput tut_wkpos_amount" [(value)]="editEntry.menge"
                     (change)="change()" (mengeKeydownEnter)="mengeEnter()"></nice-number-input>


  <ng-content></ng-content>
</div>
