import {Component, Input} from '@angular/core';

@Component({
  selector: 'info-box',
  templateUrl: 'shared.info-box.component.html'
})
export class SharedInfoBoxComponent {
  @Input() type: string;

}
