import {Artikel} from './artikel';

export class ArtikelAktion {
  AbMenge: number;
  AktionsKundeneinkaufspreis: number;
  AktionsrabattProzentsatz: number;
  Draufgabeartikel: Artikel;
  Draufgabemenge: number;
  GueltigBis: string;
  GueltigVon: string;
}
