<div class="surveybox" [class.mobile]="msvc.isMobile()|async">
  <h1 mat-dialog-title>
    {{survey.title}}
  </h1>
  <div [innerHTML]="survey.message|raw" mat-dialog-content
       style="border-top: 1px solid lightgrey; border-bottom: 1px solid lightgrey; padding: 10px; min-width: 200px; min-height: 100px"></div>

  <div *ngIf="survey.type == 'rating'" style="text-align: center">
    <div *ngFor="let r of ratings" class="starbox" (click)="setRating(r)" (mouseenter)="hoverStartStar(r)"
         (mouseleave)="hoverEndStar()" matTooltip="{{r}} / {{survey.max_rating}}">
      <svg-icon *ngIf="(!hovering && current_rating < r) || (hovering && current_hover_rating < r)">favorit</svg-icon>
      <svg-icon *ngIf="(!hovering && current_rating >= r) || (hovering && current_hover_rating >= r)">favorit-voll</svg-icon>
    </div>
    <div style="text-align: center">
      Ihre Wertung: {{current_rating}} / {{survey.max_rating}}
    </div>
  </div>

  <div *ngIf="survey.type == 'question'">
    <div *ngFor="let a of survey.answers; let idx = index">
      <mat-checkbox [(ngModel)]="answers[idx]" (change)="change_answer(idx)">{{a}}</mat-checkbox>
    </div>
  </div>

  <div *ngIf="survey.comment_allowed">
    <hr>
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Kommentar</mat-label>
      <textarea matInput [(ngModel)]="answer.comment"></textarea>
      <mat-hint>Hier können Sie uns noch eine Nachricht hinterlassen.</mat-hint>
    </mat-form-field>
  </div>
  <div align="center" mat-dialog-actions>
    <button mat-raised-button (click)="send()" [disabled]="!valid">
      <svg-icon>senden</svg-icon> Senden
    </button>
  </div>
</div>
