<div style="display: inline-block; position: relative">
  <div [class.hidden]="!(menu_visible|async)" [class.mobile]="mobsvc.isMobile()|async" class="notificationmenu mat-elevation-z1"
       (mouseover)="showMenu($event)" (mouseout)="hideMenu($event)">
    <div [class.hidden]="(visible_notifications|async)?.length <= 0" class="notification splitbutton splitbutton_top">
      <div style="text-align: center">
        <table style="width: 100%">
          <tr>
            <td class="title" style="text-align: center; width: 50%">
              <button (click)="shownSeen($event)" mat-button>
                Angezeigte gesehen
              </button>

            </td>

            <td class="title" style="text-align: center">
              <button (click)="allSeen($event)" mat-button>
                Alle gesehen
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div [class.borders]="(notifications|async)?.length > 0" class="innerdiv">
      <div *ngFor="let not of visible_notifications|async; let i = index" class="notification clickable">
        <div *ngIf="i > 0" class="spacersmall"></div>
        <app-notification-entry [notification]="not" [parent]="this"></app-notification-entry>
      </div>
    </div>
    <div *ngIf="(notifications|async) == null || (notifications|async)?.length == 0" class="notification">
      <div style="padding: 5px">
        <table class="notentry">
          <tr>
            <td class="icon" rowspan="2">
              <svg-icon>benachrichtigungen</svg-icon>
            </td>
            <td class="title" style="white-space: nowrap">
              Derzeit keine Benachrichtigungen
            </td>
            <td class="action" rowspan="2">
            </td>
          </tr>
          <tr>

          </tr>
        </table>
      </div>
    </div>
    <div class="notification splitbutton splitbutton_bottom">
      <div style="text-align: center">
        <table style="width: 100%">
          <tr>
            <td class="title" style="text-align: center; width: 50%">
              <button (click)="more($event)" *ngIf="!((notifications|async) == null || (notifications|async)?.length == 0)"
                      [class.disabled]="(notifications|async)?.length == (visible_notifications|async)?.length"
                      mat-button>
                Mehr anzeigen
              </button>
            </td>
            <td class="title" style="text-align: center">
              <button [class.disabled]="(notifications|async)?.length == (visible_notifications|async)?.length" mat-button
                      routerLink="/notifications">
                Alle anzeigen
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>


  <button (click)="toggle($event)" [class.active]="menu_visible|async" class="tut_navbar_notification" mat-button
          (mouseover)="showMenu($event)" (mouseout)="hideMenu($event)"
          matTooltip="Ihre Benachrichtigungen">
    <svg-icon
      matBadge="{{(notificationcounter|async) > 0 ? (notificationcounter|async) : null}}"
      [class.badgeDanger]="(notificationcounter|async) > 10"
      [class.badgeOk]="(notificationcounter|async) <= 3"
      [class.badgeWarn]="(notificationcounter|async) > 3 && (notificationcounter|async) <= 10"
    >benachrichtigungen</svg-icon>
  </button>
</div>

