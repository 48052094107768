import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {AppUserService} from "../../../../services/app.user.service";
import {AppEventService} from "../../../../services/app.event.service";
import {AppPageTitleService} from "../../../../services/app.page-title.service";
import {AppMainMenuButtonService} from "../../../../services/app.main-menu-button.service";
import {AppConfigService} from "../../../../services/app.config.service";
import {AppMenuButtonService} from "../../../../services/app.menu-button.service";
import {MainMenuButtonConfig} from "../../../../models/main-menu-button-config";
import {NavigationEnd, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {SectionTitleButton} from "../../../shared/models/section-title-button";
import {AppViewService} from "../../../../services/app.view.service";
import {AppMobileService} from "../../../../services/app.mobile.service";

@Component({
  selector: 'app-main-menu',
  templateUrl: 'main-menu.component.html',
  styleUrls: ['main-menu.component.scss']
})

export class MainMenuComponent implements OnInit, OnDestroy {
  _destroy = new Subject();
  esub: Subscription;

  buttons = new BehaviorSubject<MainMenuButtonConfig[]>([]);

  activeUrl: string;

  section_title_buttons_right: SectionTitleButton[] = [
    {
      icon: 'neustart',
      label: null,
      title: 'Neu Laden',
      class: null,
      onclick: () => {
        this.reload();
      }
    }
  ];

  isMobile = new BehaviorSubject<boolean>(false);

  constructor(private router: Router, public usvc: AppUserService, private esvc: AppEventService, private tsvc: AppPageTitleService,
              private mainmenubuttonsvc: AppMainMenuButtonService, public cfg: AppConfigService, private vsvc: AppViewService,
              private mmsvc: AppMenuButtonService, private mobsvc: AppMobileService) {
  }

  reload() {
    this.vsvc.reload()
  }

  ngOnInit() {
    this.mobsvc.isMobile().pipe(takeUntil(this._destroy)).subscribe(m => this.isMobile.next(m));
    this.activeUrl = this.router.url;
    this.mmsvc.mainMenuConfig.subscribe(cfg => {
      this.buttons.next(cfg);
    })

    this.router.events
      .pipe(takeUntil(this._destroy))
      .subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.activeUrl = this.router.url;
      }
    });
  }

  ngOnDestroy() {
    this._destroy.next(true);
    this._destroy.complete();
  }
}
