import {AppPageloaderService} from '../services/app.pageloader.service';

export class PageloaderObject {
  svc: AppPageloaderService;
  name: string;

  constructor(svc, name) {
    this.svc = svc;
    this.name = name;
  }


  stop() {
    this.svc.stop(this.name);
  }
}
