import {Artikel} from './artikel';
import {Nachfolgeartikel} from "./nachfolgeartikel";

export class WarenkorbEntry {
  id: number;
  artikel: Artikel|Nachfolgeartikel;
  menge: number;
  note: string[];
  print_note_lines: any[];
  positionsart = 'Standard';
  freimenge = 0;
  aktionsrabattmenge = 0;
  naturalrabattmenge = 0;
  aktionsrabattprozent = 0;
  basisverrechnungspreis: number;
  basisverrechnungspreisrabattprozent: number = 0;
  view_slug: string;

  dirty = false;
  valid = true;

  defect: boolean;

  getSumme() {
    if (this.basisverrechnungspreis !== null && (this.basisverrechnungspreis > 0 || this.basisverrechnungspreis < 0)) {
      return this.basisverrechnungspreis * this.menge
    } else {
      return this.artikel.Kundeneinkaufspreis * this.menge;
    }
  }
}
