import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';
import {Warenkorb} from '../../../../models/warenkorb';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {WarenkorbKategorie} from '../../../../models/warenkorb-kategorie';
import {SectionTitleButton} from '../../../shared/models/section-title-button';
import {takeUntil} from "rxjs/operators";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dashboard-widgets-current-warenkorb',
  templateUrl: 'dashboard.widgets.current-warenkorb.component.html',
  styleUrls: ['dashboard.widgets.current-warenkorb.component.scss'],
})
export class DashboardWidgetsCurrentWarenkorbComponent implements OnInit, OnDestroy {
  static id = 'DashboardWidgetsCurrentWarenkorbComponent';
  static cols = 12;
  static rows = 10;
  static label = 'Aktiver Warenkorb';
  static description = 'Hier wird ihnen Ihr aktiver Warenkorb angezeigt.'

  wkid: number;
  warenkorb = new BehaviorSubject<Warenkorb>(null);
  positions = new BehaviorSubject<WarenkorbEntry[]>([]);
  kategorien = new BehaviorSubject<WarenkorbKategorie[]>([]);

  loading = new BehaviorSubject<boolean>(true);

  label_map = {};

  wksub: Subscription;

  update_timeout: any;
  note_autosave_timeout: any;
  poscount: number;

  wkkref: any;

  loadTimeout: any;

  buttons_right: SectionTitleButton[] = [];

  _onDestroy = new Subject();

  constructor(public svc: AppWarenkorbService) {
  }

  load(silent = false) {
    if (!silent) {
      this.loading.next(true);
    }
    this.svc.getWarenkorbById(this.wkid).subscribe(wk => {
      this.warenkorb.next(wk);
      if (!silent) {
        this.loading.next(false);
      }
    }, (e) => {
      if (e.error && e.error.code == 10005) {
        this.svc.load();
      }
      this.loading.next(false);
    });
  }


  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  testBoot() {
    if (this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }

    if (this.wkid && this.svc.booted.value) {
      this.loadTimeout = setTimeout(() => {
        this.load();
      }, 50);
    }
  }

  ngOnInit(): void {
    this.svc.booted.pipe(takeUntil(this._onDestroy)).subscribe(b => {
      this.testBoot();
    })
    this.svc.primary_warenkorb$.pipe(takeUntil(this._onDestroy)).subscribe(w => {
      if (w) {
        this.wkid = w.id;

      } else {
        this.wkid = undefined;
      }
      this.testBoot();
    });
  }

}
