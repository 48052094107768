<dashboard-widget>
  <div icon>
    <svg-icon>warenkorb</svg-icon>
  </div>
  <div header1 matTooltip="Aktiver Warenkorb {{(warenkorb|async)?.label}}">
    <a routerLink="/shop/warenkorb/{{(warenkorb|async)?.id}}">Aktiver Warenkorb {{(warenkorb|async)?.label}}</a>
  </div>
  <div header3>
    <section-title-buttons [buttons]="buttons_right"></section-title-buttons>
  </div>
  <div content>
    <div *ngIf="loading|async" style="text-align: center">
      <div style="display: inline-block">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
      <br>
      Lade...
    </div>
    <div *ngIf="!(loading|async) ">
      <warenkorb-box [widget_mode]="true" [warenkorb]="warenkorb|async" [send_position_from_bottom]="'15px'" [(buttons_right)]="buttons_right"
      (reload)="load(true)"></warenkorb-box>
    </div>
  </div>
</dashboard-widget>
