import {GridsterItem} from 'angular-gridster2';
import {GridsterItemComponentInterface} from 'angular-gridster2/lib/gridsterItem.interface';

export class DashboardGridConfigItem implements GridsterItem {
  widget: string;
  x: number;
  y: number;
  rows: number;
  cols: number;
  layerIndex?: number;
  initCallback?: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => void;
  dragEnabled?: boolean;
  resizeEnabled?: boolean;
  compactEnabled?: boolean;
  maxItemRows?: number;
  minItemRows?: number;
  maxItemCols?: number;
  minItemCols?: number;
  minItemArea?: number;
  maxItemArea?: number;
}
