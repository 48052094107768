import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FavMenuButtonService} from '../../services/fav-menu-button.service';
import {Subscription} from 'rxjs';
import {View} from '../../../../models/view';
import {AppMessageService} from '../../../../services/app.message.service';

@Component({
  selector: 'menu-button-elem',
  templateUrl: 'main-menu.menu-button-element.component.html',
  styleUrls: ['main-menu.menu-button-element.component.scss']
})
export class MainMenuMenuButtonElementComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() roles: string[] = [];
  @Input() features: string[] = [];
  @Input() routerLink: string;
  @Input() href: string;
  @Input() target = '_self';
  @Input() class: string;
  @Input() icon: string;
  @Input() icon_color = 'currentColor';
  @Input() icon_size = 40;
  @Input() label: string;
  @Input() description: string;

  @Input() view: View;

  isFav = false;

  sub: Subscription;

  constructor(private svc: FavMenuButtonService, private msg: AppMessageService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.sub = this.svc.favIds.subscribe(ids => {
      if (this.id && ids.includes(this.id)) {
        this.isFav = true;
      } else {
        this.isFav = false;
      }
    });
  }

  toggleFav(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (this.id) {
      if (this.isFav) {
        this.svc.rem(this.id);
        this.msg.info('Aus Favoriten entfernt');
      } else {
        this.svc.add(this.id);
        this.msg.info('Zu Favoriten hinzugefügt');
      }
    }
  }
}
