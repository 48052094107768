import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import {SectionTitleButton} from '../../models/section-title-button';
import {AppBackService} from '../../../../services/app.back.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'section-title-buttons',
  templateUrl: 'shared.section-title-buttons.component.html',
  styleUrls: ['shared.section-title-buttons.component.scss']
})
export class SharedSectionTitleButtonsComponent {
  @Input() buttons: SectionTitleButton[];


  constructor(private backsvc: AppBackService) {
  }

}
