<dashboard-widget>
  <div icon>
    <svg-icon>favorit-voll</svg-icon>
  </div>
  <div header1 matTooltip="Schnellzugriff">
    Schnellzugriff
  </div>
  <div header3>
    <button mat-icon-button (click)="reset()" matTooltip="Auf Standard zurücksetzen">
      <svg-icon>zuruecksetzen</svg-icon>
    </button>
  </div>
  <div content>
    <div class="menuboxouter tut_shopmenu_views">
      <div class="menubox">
        <div *ngFor="let cfg of buttons|async" class="menuboxEntry">
          <menu-button [name]="cfg.id" [config]="cfg" [small]="true"></menu-button>
        </div>
      </div>
    </div>
  </div>
</dashboard-widget>
