import { HttpClient } from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {AppPageloaderService} from './app.pageloader.service';
import {Injectable} from '@angular/core';
import {AppAsyncTimeout} from "../helpers/app.asyncTimeout";

declare let window: any;

@Injectable({providedIn: 'root'})
export class AppSystemService {
  maintenance_state = false;

  constructor(private http: HttpClient, private cfg: AppConfigService, private loader: AppPageloaderService) {
    this.checkMaintenanceMode();
  }

  checkMaintenanceMode() {
    if (this.cfg.booted) {
      this.http.get<any>(
        this.cfg.buildUrl('/system/maintenance')
      ).subscribe(s => {
        if (this.maintenance_state == false) {
          if (s.state == true) {
            this.maintenance_state = true;
            this.loader.startFullscreen(s.text, 'Bitte warten');

            AppAsyncTimeout.setTimeout(() => {
              this.checkMaintenanceMode();
            }, 500);
          }
        } else {
          if (s.state == true) {
            AppAsyncTimeout.setTimeout(() => {
              this.checkMaintenanceMode();
            }, 500);
          } else {
            this.loader.startFullscreen('Abgeschlossen! Wird neu geladen!', 'Bitte warten');

            AppAsyncTimeout.setTimeout(() => {
              window.location.reload(true);
            }, 1500);
          }
        }
      });
    } else {
      AppAsyncTimeout.setTimeout(() => {
        this.checkMaintenanceMode();
      }, 500);
    }
  }


}
