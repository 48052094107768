import {Swipe_Up} from './icons/Swipe_Up';
import {Swipe_Right} from './icons/Swipe_Right';
import {Swipe_Left} from './icons/Swipe_Left';
import {Swipe_Down} from './icons/Swipe_Down';
import {DoubleTap} from './icons/Double-Tap';
import {Single_Tap} from './icons/Single_Tap';
import {HotspotClose} from './icons/Hotspot-Close';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';


const ZINDEX_NOT_SET = '-99999';

@Component({
  selector: 'app-walkthrough',
  templateUrl: 'app.walkthrough.component.html',
  styleUrls: ['app.walkthrough.component.scss']
})
export class AppWalkthroughComponent implements AfterViewChecked {
  static id = 'AppWalkthroughComponent';
  // members (must come first - tslint)
  _focusElementZindexes: string[] = [];
  DOM_WALKTHROUGH_CLASS = 'walkthrough-background';
  DOM_WALKTHROUGH_TRANSPARENCY_TEXT_CLASS = '.walkthrough-text';
  DOM_WALKTHROUGH_TIP_TEXT_CLASS = '.walkthrough-tip-text-box';
  DOM_WALKTHROUGH_HOLE_CLASS = '.walkthrough-hole';
  DOM_WALKTHROUGH_TRANSPARENCY_ICON_CLASS = '.walkthrough-icon';
  DOM_WALKTHROUGH_TIP_ICON_CLASS = '.walkthrough-tip-icon-text-box';
  DOM_WALKTHROUGH_ARROW_CLASS = '.walkthrough-arrow';
  DOM_WALKTHROUGH_DONE_BUTTON_CLASS = 'walkthrough-done-button';
  DOM_TRANSCLUDE = 'walkthrough-transclude';
  BUTTON_CAPTION_DONE = 'Got it!';
  PADDING_HOLE = 5;
  PADDING_ARROW_START = 5;
  PADDING_ARROW_MARKER = 25;
  isVisible = false;
  icon: any;
  hasTransclude = false;
  walkthroughHoleElements: HTMLElement;
  walkthroughTextElement: HTMLElement;
  walkthroughIconElement: HTMLElement;
  walkthroughArrowElement: HTMLElement;
  closeIcon: string;
  walkthroughIcon: any;
  // the element have been separated as ionic pro cannot handle class with very large string
  single_tap: string = new Single_Tap().single_tap;
  double_tap: string = new DoubleTap().double_tap;
  swipe_down: string = new Swipe_Down().swipe_down;
  swipe_left: string = new Swipe_Left().swipe_left;

  // single_tap: string = require('../assets/Single_Tap.png');

  // double_tap: string = require('../assets/Double_Tap.png');

  // swipe_down: string = require('../assets/Swipe_Down.png');

  // swipe_left: string = require('../assets/Swipe_Left.png');

  // swipe_right: string = require('../assets/Swipe_Right.png');

  // swipe_up: string = require('../assets/Swipe_Up.png');
  swipe_right: string = new Swipe_Right().swipe_right;
  swipe_up: string = new Swipe_Up().swipe_up;
  @Input('walkthrough-type') walkthroughType: string;
  @Input('button-caption') buttonCaption: string;
  @Input('use-button') useButton = false;
  @Input('main-caption') mainCaption: string;
  @Input('icon') walkthroughIconWanted: string;
  @Input('walkthrough-hero-image') walkthroughHeroImage: any;
  @Input('has-glow') hasGlow = false;
  @Input('force-caption-location') forceCaptionLocation: string;
  @Input('has-backdrop') hasBackdrop: boolean;
  @Input('is-round') isRound = false;
  @Input('icon-padding-left') iconPaddingLeft: string;
  @Input('icon-padding-top') iconPaddingTop: string;
  @Input('tip-icon-location') tipIconLocation: string;
  @Input('tip-color') tipColor: string;
  @Input('focus-element-interactive') focusElementInteractive = false;
  @Output('on-walkthrough-show') onWalkthroughShowEvent = new EventEmitter<void>();
  @Output('on-walkthrough-hide') onWalkthroughHideEvent = new EventEmitter<void>();
  @Output('on-walkthrough-hidden') onWalkthroughHiddenEvent = new EventEmitter<void>();
  @Output('on-walkthrough-content-clicked') onWalkthroughContentClickedEvent = new EventEmitter<void>();
  @ViewChild('walkthroughcomponent', {static: true}) element: ElementRef;

  constructor() {
  }

  @Input('is-active')
  set isActive(isActive: boolean) {
    if (isActive) {
      this.setWalkthroughElements();
      this.isVisible = true;

      try {
        if (this.focusElementSelector) {
          this.setFocusOnElement();
        }
      } catch (e) {
        console.warn('failed to focus on element prior to timeout: ' + this.focusElementSelector);
      }

      // Must timeout to make sure we have final correct coordinates after screen totally load
      if (this.focusElementSelector) {
        setTimeout(() => {
          this.setFocusOnElement();
        }, 100);
      }

      this.onWalkthroughShowEvent.emit();

    } else {
      this.isVisible = false;
    }
  }

  private _focusElementSelector: string;

  get focusElementSelector(): string {
    return this._focusElementSelector;
  }

  @Input('focus-element-selector')
  set focusElementSelector(focusElementSelector: string) {
    if ((!this._focusElementSelector || focusElementSelector !== this._focusElementSelector) && this.isVisible) {
      this._focusElementSelector = focusElementSelector;
      this.setFocusOnElement();
    } else {
      this._focusElementSelector = focusElementSelector;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.isVisible) {
      this.resizeHandler();
    }
  }

  /**
   * resize handler method
   */
  resizeHandler() {
    if (this.focusElementSelector && this.isVisible) {
      this.setFocusOnElement();
    }
  }

  /**
   * init the element of the walkthrough
   */
  setWalkthroughElements() {
    const holeElements = this.element.nativeElement.querySelectorAll(this.DOM_WALKTHROUGH_HOLE_CLASS);
    this.walkthroughHoleElements = holeElements[0] as HTMLElement;

    const textClass: string = (this.walkthroughType === 'tip') ? this.DOM_WALKTHROUGH_TIP_TEXT_CLASS : this.DOM_WALKTHROUGH_TRANSPARENCY_TEXT_CLASS;
    this.walkthroughTextElement = this.element.nativeElement.querySelectorAll(textClass)[0] as HTMLElement;

    const iconClass: string = (this.walkthroughType === 'tip') ? this.DOM_WALKTHROUGH_TIP_ICON_CLASS : this.DOM_WALKTHROUGH_TRANSPARENCY_ICON_CLASS;
    this.walkthroughIconElement = this.element.nativeElement.querySelectorAll(iconClass)[0] as HTMLElement;

    this.walkthroughArrowElement = this.element.nativeElement.querySelectorAll(this.DOM_WALKTHROUGH_ARROW_CLASS)[0] as HTMLElement;
    setTimeout(() => {
      this.closeIcon = new HotspotClose().close_icon;
    }, 100);
    this.walkthroughIcon = this.getIcon(this.walkthroughIconWanted);
    this.buttonCaption = this.buttonCaption || this.BUTTON_CAPTION_DONE;
    if (this.hasBackdrop === undefined) {
      this.hasBackdrop = (this.walkthroughType !== 'tip');
    }

  }

  /**
   *
   */
  ngAfterViewChecked() {
    const translude = this.element.nativeElement.querySelectorAll('.' + this.DOM_TRANSCLUDE);
    if (translude.length > 0 && translude[0].children.length > 0) {
      this.hasTransclude = true;
    }
  }

  /**
   * Get the icon specify by the input
   * @param icon
   */
  getIcon(icon: string) {
    let retval = '';
    switch (icon) {
      case ('single_tap'):
        retval = this.single_tap;
        break;
      case ('double_tap'):
        retval = this.double_tap;
        break;
      case ('swipe_down'):
        retval = this.swipe_down;
        break;
      case ('swipe_left'):
        retval = this.swipe_left;
        break;
      case ('swipe_right'):
        retval = this.swipe_right;
        break;
      case ('swipe_up'):
        retval = this.swipe_up;
        break;
      case ('arrow'):
        retval = ''; // Return nothing, using other dom element for arrow
        break;
      case ('none'):
        return '';
        break;

      default:
        retval = this.single_tap;


    }
    if (retval === '' && icon && icon.length > 0) {
      retval = icon;
    } else {
      this.toDataURL(retval).then((dataUrl) => {
        retval = dataUrl;
      });
    }
    return retval;
  }

  /**
   * Convert url in blob
   * @param url
   */
  toDataURL(url: string): Promise<any> {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          };
        });
      });
  }

  /**
   * Set the text position accordint the hole and arrow position plus set the arrow
   * @param pointSubjectLeft
   * @param pointSubjectTop
   * @param pointSubjectWidth
   * @param pointSubjectHeight
   * @param paddingLeft
   */
  setArrowAndText(pointSubjectLeft: number, pointSubjectTop: number, pointSubjectWidth: number, pointSubjectHeight: number, paddingLeft: number) {
    const offsetCoordinates = this.getOffsetCoordinates(this.walkthroughTextElement);
    const startLeft = offsetCoordinates.left + offsetCoordinates.width / 2;
    let startTop = offsetCoordinates.top + offsetCoordinates.height + this.PADDING_ARROW_START;

    let endLeft = 0;
    let isLine = false;

    if (Math.abs(startLeft - (pointSubjectLeft + pointSubjectWidth / 2)) < 10) {
      console.warn('Hole element and text are inline line arrow will be used');
      endLeft = pointSubjectLeft + pointSubjectWidth / 2;
      isLine = true;
    } else if (startLeft > pointSubjectLeft) {// If hole left to text set arrow to point to middle right
      endLeft = pointSubjectLeft + paddingLeft + pointSubjectWidth;
    } else if (startLeft < pointSubjectLeft) {// If hole right to text set arrow to point to middle left
      endLeft = pointSubjectLeft - paddingLeft;
    }
    let endTop;

    if (isLine) {
      endTop = pointSubjectTop - this.PADDING_ARROW_MARKER;
    } else {
      endTop = pointSubjectTop + (pointSubjectHeight / 2);
    }

    let arrowLeft, arrowRight, arrowTop, arrowBottom;
    // Check if text overlaps icon or user explicitly wants text at bottom, if does, move it to bottom
    arrowLeft = (startLeft < endLeft) ? startLeft : endLeft;
    arrowRight = (startLeft < endLeft) ? endLeft : startLeft;
    arrowTop = (startTop < endTop) ? startTop : endTop;
    arrowBottom = (startTop < endTop) ? endTop : startTop;

    if (this.forceCaptionLocation === undefined && this.isItemOnText(arrowLeft, arrowTop, arrowRight, arrowBottom)) {
      this.forceCaptionLocation = 'BOTTOM';
    }

    if (this.forceCaptionLocation === 'BOTTOM') {
      if (isLine) {
        endTop = pointSubjectTop + pointSubjectHeight + this.PADDING_ARROW_MARKER;
      }
      startTop = offsetCoordinates.top - this.PADDING_ARROW_START;
    }

    let arrowSvgDom;

    if (isLine) {
      arrowSvgDom =
        '<svg width="100%" height="100%">' +
        '<defs>' +
        '<marker id="arrow" markerWidth="13" markerHeight="13" refx="2" refy="6" orient="auto">' +
        '<path d="M2,1 L2,10 L10,6 L2,2" style="fill:#fff;" />' +
        '</marker>' +
        '</defs>' +
        '<line x1=' + endLeft + ' y1=' + startTop + ' x2=' + endLeft + ' y2=' + endTop + ' ' +
        'style="stroke:#fff; stroke-width: 2px; fill: none;' +
        'marker-end: url(#arrow);"/>' +
        '/>' +
        '</svg>';
    } else {
      arrowSvgDom =
        '<svg width="100%" height="100%">' +
        '<defs>' +
        '<marker id="arrow" markerWidth="13" markerHeight="13" refx="2" refy="6" orient="auto">' +
        '<path d="M2,1 L2,10 L10,6 L2,2" style="fill:#fff;" />' +
        '</marker>' +
        '</defs>' +
        '<path d="M' + startLeft + ',' + startTop + ' Q' + startLeft + ',' + endTop + ' ' + endLeft + ',' + endTop + '"' +
        'style="stroke:#fff; stroke-width: 2px; fill: none;' +
        'marker-end: url(#arrow);"/>' +
        '/>' +
        '</svg>';
    }


    const arrowElement = this.element.nativeElement.querySelector(this.DOM_WALKTHROUGH_ARROW_CLASS);
    if (arrowElement.children.length > 0) {
      arrowElement.children[0].remove();
    }
    arrowElement.insertAdjacentHTML('afterbegin', arrowSvgDom);
  }

  /**
   * Check if given icon covers text or if the text cover the hole
   * @param iconLeft
   * @param iconTop
   * @param iconRight
   * @param iconBottom
   */
  isItemOnText(iconLeft: number, iconTop: number, iconRight: number, iconBottom: number) {
    const holeCoordinates = this.getOffsetCoordinates(this.walkthroughHoleElements);
    const offsetCoordinates = this.getOffsetCoordinates(this.walkthroughTextElement);

    const holeLeft = holeCoordinates.left;
    const holeRight = holeCoordinates.left + holeCoordinates.width;
    const holeTop = holeCoordinates.top;
    const holeBottom = holeCoordinates.top + holeCoordinates.height;

    const textLeft = document.body.clientWidth / 4; // needs to be calculated differently due to being a 'pre'. //offsetCoordinates.left;
    const textRight = document.body.clientWidth / 4 * 3; // offsetCoordinates.left + offsetCoordinates.width;
    const textTop = offsetCoordinates.top;
    const textBottom = offsetCoordinates.top + offsetCoordinates.height;

    if (!(holeRight < textLeft ||
      holeLeft > textRight ||
      holeBottom < textTop ||
      holeTop > textBottom)) {
      return true;
    }

    return !(iconRight < textLeft ||
      iconLeft > textRight ||
      iconBottom < textTop ||
      iconTop > textBottom);
  }

  /**
   *
   * @param focusElement
   */
  getOffsetCoordinates(focusElement: HTMLElement) {
    let width: number;
    let height: number;
    let left: number;
    let top: number;
    width = focusElement.offsetWidth;
    height = focusElement.offsetHeight;
    left = focusElement.getBoundingClientRect().left;
    top = focusElement.getBoundingClientRect().top;

    let sameAncestorForFocusElementAndWalkthrough = this.getSameAncestor(focusElement);
    while (sameAncestorForFocusElementAndWalkthrough) {
      left = left - sameAncestorForFocusElementAndWalkthrough.offsetLeft;
      top = top - sameAncestorForFocusElementAndWalkthrough.offsetTop;
      sameAncestorForFocusElementAndWalkthrough = sameAncestorForFocusElementAndWalkthrough.offsetParent as HTMLElement;
    }
    return {top: top, left: left, height: height, width: width};
  }

  // Check once
  getSameAncestor(focusElement: HTMLElement) {
    let retval = null;
    const walkthroughElementParent = this.element.nativeElement.offsetParent;
    const focusElementParent = focusElement.offsetParent as HTMLElement;
    let walkthroughAncestorIter = walkthroughElementParent as HTMLElement;
    let focusElementAncestorIter = focusElementParent as HTMLElement;

    while (walkthroughAncestorIter && !retval) {
      focusElementAncestorIter = focusElementParent; // reset
      while (focusElementAncestorIter && !retval) {
        if (focusElementAncestorIter === walkthroughAncestorIter) {
          retval = walkthroughAncestorIter;
        } else {
          focusElementAncestorIter = focusElementAncestorIter.offsetParent as HTMLElement;
        }
      }
      walkthroughAncestorIter = walkthroughAncestorIter.offsetParent as HTMLElement;
    }
    return retval;
  }

  /**
   * Sets the icon displayed according to directive argument
   * @param iconLeft
   * @param iconTop
   * @param paddingLeft
   * @param paddingTop
   */
  setIconAndText(iconLeft: number, iconTop: number, paddingLeft: number, paddingTop: number) {
    const offsetCoordinates = this.getOffsetCoordinates(this.walkthroughIconElement);
    const iconHeight = offsetCoordinates.height;
    const iconWidth = offsetCoordinates.width;
    const iconLeftWithPadding = iconLeft + paddingLeft - (iconWidth / 4);
    const iconTopWithPadding = iconTop + paddingTop - (iconHeight / 6);
    const iconRight = iconLeftWithPadding + iconWidth;
    const iconBottom = iconTopWithPadding + iconHeight;
    // Check if text overlaps icon or user explicitly wants text at bottom, if does, move it to bottom
    if (this.forceCaptionLocation === undefined && this.isItemOnText(iconLeftWithPadding, iconTopWithPadding, iconRight, iconBottom)) {
      this.forceCaptionLocation = 'BOTTOM';
    }

    const iconLocation =
      'position: absolute;' +
      'left:' + iconLeftWithPadding + 'px;' +
      'top:' + iconTopWithPadding + 'px;';
    this.walkthroughIconElement.setAttribute('style', iconLocation);
  }

  /**
   * Attempts to highlight the given element ID and set Icon to it if exists, if not use default - right under text
   */
  setElementLocations() {
    let selectorElements = (this.focusElementSelector) ? document.querySelectorAll(this.focusElementSelector) : null;
    if (selectorElements && selectorElements.length > 0) {
      if (selectorElements.length > 1) {
        console.warn('Multiple items fit selector, displaying first visible as focus item');
      }


    } else {
      console.error('No element found with selector: ' + this.focusElementSelector);
      selectorElements = null;
    }
    let htmlElement = null;
    if (selectorElements) {
      htmlElement = selectorElements[0] as HTMLElement;
    }
    if (htmlElement) {
      const offsetCoordinates = this.getOffsetCoordinates(htmlElement);
      const width = offsetCoordinates.width;
      const height = offsetCoordinates.height;
      const left = offsetCoordinates.left;
      const top = offsetCoordinates.top;

      this.setFocus(left, top, width, height);
      let paddingLeft = parseFloat(this.iconPaddingLeft);
      let paddingTop = parseFloat(this.iconPaddingTop);
      if (!paddingLeft) {
        paddingLeft = 0;
      }
      if (!paddingTop) {
        paddingTop = 0;
      }

      // If Gesture icon given bind it to hole as well
      if (this.walkthroughIconWanted && this.walkthroughIconWanted !== 'arrow' && this.walkthroughType === 'transparency') {
        setTimeout(() => {
          this.setIconAndText(left + width / 2, top + height / 2, paddingLeft, paddingTop);
        }, 200);
      }
      if (this.walkthroughIconWanted === 'arrow') {
        // Need to update text location according to conditional class added 'walkthrough-transparency-bottom'
        setTimeout(() => {
          this.setArrowAndText(left, top + paddingTop, width, height, paddingLeft);
        }, 200);
      }
      // if tip mode with icon that we want to set padding to, set it
      if (this.walkthroughType === 'tip' &&
        this.walkthroughIconWanted && this.walkthroughIconWanted.length > 0 &&
        (this.iconPaddingLeft || this.iconPaddingTop)) {
        this.setTipIconPadding(this.iconPaddingLeft, this.iconPaddingTop);
      }
    } else {
      if (this.focusElementSelector) {
        console.info('Unable to find element requested to be focused: ' + this.focusElementSelector);
      } else {
        // if tip mode with icon that we want to set padding to, set it
        if (this.walkthroughType === 'tip' &&
          this.walkthroughIconWanted && this.walkthroughIconWanted.length > 0 &&
          (this.iconPaddingLeft || this.iconPaddingTop)) {
          this.setTipIconPadding(this.iconPaddingLeft, this.iconPaddingTop);
        }
      }
    }

    if (this.focusElementInteractive && selectorElements) {
      for (let i = 0; i < selectorElements.length; ++i) {
        const selectorElement: HTMLElement = selectorElements.item(i) as HTMLElement;
        if (selectorElement.style.zIndex !== '99999') {
          this._focusElementZindexes[i] = (selectorElement.style.zIndex) ?
            selectorElement.style.zIndex :
            ZINDEX_NOT_SET;
          selectorElement.style.zIndex = '99999';
        }
      }
    }
  }


  /**
   * Sets the walkthrough focus hole on given params with padding
   * @param left
   * @param top
   * @param width
   * @param height
   */
  setFocus(left: number, top: number, width: number, height: number) {
    const holeDimensions =
      'left:' + (left - this.PADDING_HOLE) + 'px;' +
      'top:' + (top - this.PADDING_HOLE) + 'px;' +
      'width:' + (width + (2 * this.PADDING_HOLE)) + 'px;' +
      'height:' + (height + (2 * this.PADDING_HOLE)) + 'px;';
    if (this.walkthroughHoleElements) {
      this.walkthroughHoleElements.setAttribute('style', holeDimensions);
    }
  }

  /**
   * Set the focus on one element
   */
  setFocusOnElement() {
    this.setElementLocations();
  }

  /**
   * Send an output event
   */
  onWalkthroughContentClicked() {
    this.onWalkthroughContentClickedEvent.emit();
  }

  /**
   * Set the padding of the tip icon
   * @param iconPaddingLeft
   * @param iconPaddingTop
   */
  setTipIconPadding(iconPaddingLeft: string, iconPaddingTop: string) {
    let iconLocation = '';
    if (iconPaddingTop) {
      iconLocation += 'margin-top:' + iconPaddingTop + 'px;';
    }
    if (iconPaddingLeft) {
      iconLocation += 'right:' + iconPaddingLeft + '%;';
    }
    this.walkthroughIconElement.setAttribute('style', iconLocation);
  }

  /**
   * Close the walkthrough
   * @param event
   */
  onCloseClicked(event: any) {
    if ((!this.useButton) ||
      (event.currentTarget.className.indexOf(this.DOM_WALKTHROUGH_DONE_BUTTON_CLASS) > -1)) {
      this.closeWalkthrough();
    }

  }

  /**
   * close the walkthgrough and sen an output event
   */
  closeWalkthrough() {
    this.onWalkthroughHideEvent.emit();
    // to avoid disturbance with other SVG it is remove from the DOM
    const arrowElement = this.element.nativeElement.querySelector(this.DOM_WALKTHROUGH_ARROW_CLASS);
    if (arrowElement.children.length > 0) {
      arrowElement.children[0].remove();
    }
    this.isVisible = false;

    // reset z-index on selectedElement
    const selectedElements = (this.focusElementSelector) ? document.querySelectorAll(this.focusElementSelector) : null;
    if (selectedElements) {
      for (let i = 0; i < selectedElements.length; ++i) {
        const curElement: HTMLElement = selectedElements.item(i) as HTMLElement;
        if (this._focusElementZindexes[i] !== ZINDEX_NOT_SET) {
          curElement.style.zIndex = this._focusElementZindexes[i];
        } else {
          curElement.style.zIndex = 'auto';
        }
      }
      this._focusElementZindexes = [];
    }

    this.onWalkthroughHiddenEvent.emit();
  }

}
