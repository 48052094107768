import {User} from './user';
import {MerklistEntry} from './merklist-entry';

export class Merkliste {
  id: number;
  label: string;
  note: string;
  entries: MerklistEntry[];
  shared: boolean;
  artikel_count: number;
  owner: boolean;
  owner_user: User;
}
