import {Injectable} from "@angular/core";
import { HttpClient, HttpEvent, HttpProgressEvent } from "@angular/common/http";
import {FileBrowserEntry} from "../models/file-browser-entry";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {FileBrowserUploadData} from "../models/file-browser-upload-data";

@Injectable({providedIn: "root"})
export class AppFileBrowserService {
  constructor(private http: HttpClient) {
  }

  private prototypeEntry(e: FileBrowserEntry) {
    Object.setPrototypeOf(e, FileBrowserEntry.prototype);

    for (const child of e.children) {
      child.parent = e;
      this.prototypeEntry(child);
    }
  }

  uploadFiles(data: FileBrowserUploadData): Observable<any> {

    let fdata = new FormData();
    fdata.set('parent', JSON.stringify(data.parent));
    for (const i in data.files) {
      fdata.append('files_'+i, data.files[i], data.files[i].name);
    }

    return this.http.post(
      '/files/upload',
      fdata,
      {
        reportProgress: true,
        observe: 'events',
      }
    )
  }

  downloadFile(data: FileBrowserEntry): Observable<any> {
    return this.http.get(
      '/files/download/'+data.id+'/'+data.label,
      {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
      }
    )
  }

  getList(): Observable<FileBrowserEntry[]> {
    return this.http.get<FileBrowserEntry[]>(
      '/files'
    ).pipe(map(tree => {
      for(const entry of tree) {
        this.prototypeEntry(entry);
      }

      return tree;
    }));
  }

  saveEntry(entry: FileBrowserEntry): Observable<FileBrowserEntry> {
    let id = "new";
    if (entry.id) {
      id = entry.id.toString();
    }

    let copy = Object.assign({}, entry)

    copy.children = undefined;

    if (copy.parent) {
      copy.parent.children = undefined;
      copy.parent.parent = undefined;
    }

    return this.http.post<FileBrowserEntry>(
      '/files/save/' + id,
      {
        entry: copy
      }
    );
  }

  deleteEntry(entry: FileBrowserEntry): Observable<boolean> {
    return this.http.get<boolean>(
      '/files/delete/' + entry.id
    );
  }
}
