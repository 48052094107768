export class ViewFilter {
  knoten_id: number;
  parent_knoten_id: number;
  artikelstamm: string;
  parent: number;
  label: string;
  active: boolean;
  children: ViewFilter[];
  order: number;
  fixed: boolean;
  aktiv = false;
  active_children = 0; // ONLY FOR GUI

  getKnotenId() {
    return this.artikelstamm + '_' + this.knoten_id;
  }

  toggleKnotenId(id) {
    if (this.knoten_id == id) {
      this.aktiv != this.aktiv;
    } else {
      if (this.children && this.children.length > 0) {
        for (const c of this.children) {
          c.toggleKnotenId(id);
        }
      }
    }
  }

  checkActiveKnoten() {
    let isActive = 0;
    if (this.children && this.children.length > 0) {
      this.children.forEach(c => {
        isActive += c.checkActiveKnoten();
        if (c.active) {
          isActive++;
        }
      })
    }

    this.active_children = isActive;

    return isActive;
  }

  setActiveFilterKnotenIds(ids: string[]) {
    if (ids.includes(this.getKnotenId())) {
      this.active = true;
    } else {
      this.active = false;
    }

    if (this.children && this.children.length > 0) {
      for (const c of this.children) {
        c.setActiveFilterKnotenIds(ids);
      }
    }
  }

  getActiveFilterKnotenIds(): number[] {
    const ret = [];
    if (this.active) {
      ret.push(this.getKnotenId());
    }

    if (this.children && this.children.length > 0) {
      for (const c of this.children) {
        for (const c1 of c.getActiveFilterKnotenIds()) {
          ret.push(c1);
        }
      }
    }

    return ret;
  }
}
