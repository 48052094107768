import {Artikel} from './artikel';

export class BestellungEntry {
  id: number;
  artikel: Artikel;
  menge: number;
  note: string[];
  print_note_lines: any[];
  positionsart: string;
  freimenge: number;
  aktionsrabattmenge: number;
  naturalrabattmenge: number;
  aktionsrabattprozent: number;
  basisverrechnungspreis: number;
  basisverrechnungspreisrabattprozent: number;
  view_slug: string;

  resp_kndnr: string;
  resp_liefermenge: number;
  resp_nachfolgeartikel: Artikel;
  resp_texte: string[];
  resp_detail: {
    Menge: number;
    Lieferzusage: boolean;
    Lieferdatum: string;
    InfoCode: string;
    InfoText: string;
  }[];

  error: string;

  dirty = false;

  getSumme() {
    // Nur Vertreter hat einen BVP und keine Liefermenge!
    if (this.basisverrechnungspreis !== null && (this.basisverrechnungspreis > 0 || this.basisverrechnungspreis < 0)) {
      return this.basisverrechnungspreis * this.menge;
    } else {
      return this.artikel.Kundeneinkaufspreis * this.resp_liefermenge;
    }
  }
}
