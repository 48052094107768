import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import {AppUserService} from '../services/app.user.service';
import {AppMessageService} from '../services/app.message.service';
import {Observable, of} from 'rxjs';

@Injectable()
export class AppGuardsAuthguard  {
  constructor(private router: Router, private usvc: AppUserService, private msg: AppMessageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, rtsnap: RouterStateSnapshot): Observable<boolean> {
    if (route.data.allOfRoles) {
      return this.usvc.hasAllRoles(route.data.allOfRoles);

    } else if (route.data.oneOfRoles) {
      return this.usvc.hasRoles(route.data.oneOfRoles);

    } else {
      return of(true);
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (childRoute.data.allOfRoles) {
      return this.usvc.hasAllRoles(childRoute.data.allOfRoles);

    } else if (childRoute.data && childRoute.data.oneOfRoles) {
      return this.usvc.hasRoles(childRoute.data.oneOfRoles);

    } else {
      return of(true);
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (route.data.allOfRoles) {
      return this.usvc.hasAllRoles(route.data.allOfRoles);

    } else if (route.data && route.data.oneOfRoles) {
      return this.usvc.hasRoles(route.data.oneOfRoles);

    } else {
      return of(true);
    }
  }
}
