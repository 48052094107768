export class CustomerSpecialPrice {
  SonderpreisKundeId: number;
  ArtikelNummer: number;
  ArtikelName: string;
  PZN: string;
  GueltigVon: any;
  GueltigBis: any;
  PreisLtPreiscode: number;
  ESP: number;
  FAP: number;
  AEP: number;
  KPR: number;
  KPH: number;
  AVP: number;
  TEP: number;
  TVP: number;
  PreisText: string;
  PreisErstellungBenutzer: string;
  AnlageDatum: string;

  active: boolean;
  inFuture: boolean;
}
