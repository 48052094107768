import {ErrorStateMatcher} from '@angular/material/core';
import {UntypedFormControl, FormGroupDirective, NgForm} from '@angular/forms';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isError = false;
  error_text = '';

  loading = false;

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  setRequired() {
    this.setError('Wird benötigt!');
  }

  setError(text: string = null) {
    if (text) {
      this.isError = true;
      this.error_text = text;
    } else {
      this.isError = false;
      this.error_text = '';
    }
    this.stopLoading();
  }

  setOk() {
    this.setError();
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.isError;
  }
}
