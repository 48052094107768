import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {AppPageloaderService} from './app.pageloader.service';
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: 'root'})
export class AppBackService {

  backAvailable = new BehaviorSubject(false);

  constructor(private location: Location, private router: Router, private loader: AppPageloaderService) {
    if (window.history.length > 2) {
      this.backAvailable.next(true);
    }

    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (window.history.length > 2) {
          this.backAvailable.next(true);
        } else {
          this.backAvailable.next(false);
        }
      }
    })
  }

  back(backurl: any = false) {
    const l = this.loader.start('Back');
    if (backurl) {
      this.router.navigateByUrl(backurl);
      this.location.replaceState(backurl);
    } else {
      if (this.backAvailable.value) {
        const url = this.router.url;
        this.location.back();
        this.location.replaceState(url);
      } else {
        l.stop();
      }
    }
  }
}
