import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Artikel} from '../../../../models/artikel';
import {AppArticleNoteService} from '../../../../services/app.article-note.service';
import {BehaviorSubject} from 'rxjs';
import {ArtikelNote} from '../../../../models/artikel-note';
import {AppMessageService} from '../../../../services/app.message.service';
import {AppDialogsService} from '../../../../services/app.dialogs.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'artikel-note',
  templateUrl: 'shop.artikel-note.component.html',
  styleUrls: ['shop.artikel-note.component.scss']
})
export class ShopArtikelNoteComponent implements OnChanges, OnInit {
  @Input() artikel: Artikel;

  note: ArtikelNote;

  edit_mode = new BehaviorSubject<boolean>(false);

  constructor(public svc: AppArticleNoteService, private msg: AppMessageService, private dsvc: AppDialogsService) {
  }


  ngOnChanges(changes: SimpleChanges): void {

  }

  startedit($event) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    if (!this.note) {
      this.note = new ArtikelNote();
      this.note.artikelstamm = this.artikel.Artikelstamm;
      this.note.artnr = this.artikel.ArtikelNummer;
      this.note.note = '';
    }
    this.edit_mode.next(true);
  }

  cancel($event) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    this.edit_mode.next(false);
  }

  save($event) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    this.edit_mode.next(false);
    this.svc.setForArticle(this.artikel, this.note).subscribe(s => {
      if (s) {
        this.msg.info('Gespeichert!');
      } else {
        this.msg.error('Fehlgeschlagen!');
      }
      this.edit_mode.next(false);
    });
  }

  delete($event) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    this.dsvc.confirm(
      null,
      'Notiz löschen?',
      'Wollen Sie wirklich diese Notiz löschen?',
      () => {
        this.edit_mode.next(false);
        this.svc.deleteForArticle(this.artikel, this.note).subscribe(s => {
          if (s) {
            this.msg.info('Gelöscht!!');
            this.note = undefined;
          } else {
            this.msg.error('Fehlgeschlagen!');
          }
          this.edit_mode.next(false);
        });
      }
    );
  }

  ngOnInit(): void {
    this.note = this.artikel.note;
  }


}
