export class RegisterRequest {
  is_customer = false;
  company_name: string;
  company_uid: string;
  firstname: string;
  lastname: string;
  street: string;
  housenr: string;
  country = 'AT';
  plz: string;
  city: string;
  telnr: string;
  mail: string;
  password: string;

  interests: string[];

  agb = false;
  dsgvo = false;
}
