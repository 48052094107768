import {Injectable} from '@angular/core';
import {AppMemoryService} from './app.memory.service';
import {MatDialog} from '@angular/material/dialog';
import {PrivacySettings} from '../models/privacy-settings';
import {AppConfigService} from './app.config.service';
import {BehaviorSubject} from 'rxjs';
import {AppDialogsPrivacyComponent} from '../dialogs/privacy/app.dialogs.privacy.component';

@Injectable({providedIn: 'root'})
export class AppPrivacyService {
  hasSettings = new BehaviorSubject<boolean>(false);
  hasSettings$ = this.hasSettings.asObservable();

  settings: PrivacySettings;
  settings_changed_by_update = false;

  constructor(private msvc: AppMemoryService, private cfg: AppConfigService, private dialog: MatDialog) {
    this.load();
    this.init();
  }

  init() {
    if (!this.hasSettings.value || this.settings_changed_by_update) {
      this.settings_changed_by_update = false;
      this.openDialog();
    }
  }

  openDialog() {
    const dref = this.dialog.open(AppDialogsPrivacyComponent, {
      width: '450px',
      data: this.settings,
      disableClose: true
    });

    dref.afterClosed().subscribe(resp => {
      this.settings = resp;
      this.hasSettings.next(true);
      this.save();
    });
  }

  load() {
    if (this.msvc.has('AppPrivacyService_settings', 'BROWSER')) {
      const sett = new PrivacySettings();
      const cfgsett = this.msvc.get('AppPrivacyService_settings', 'BROWSER');

      Object.keys(sett).forEach(k => {
        if (cfgsett[k] === undefined) {
          this.settings_changed_by_update = true;
        } else {
          sett[k] = cfgsett[k];
        }
      });

      this.settings = sett;
      this.hasSettings.next(true);

    } else {
      this.settings = new PrivacySettings();
    }
  }

  save() {
    this.msvc.set('AppPrivacyService_settings', this.settings, false, 'BROWSER');
  }
}
