import {NewsEntryTag} from './news-entry-tag';
import {NewsEntryFile} from "./news-entry-file";

export class NewsEntry {
  id: number;
  label: string;
  text: string;
  teaser: string;
  has_picture: string;
  filter_users: string[];
  filter_sparten: string[];
  tags: NewsEntryTag[];
  ts_insert: string;
  has_more: boolean;
  insert_by: string;
  insert_by_id: string;
  is_new: boolean;
  ts_seen: string;
  files: NewsEntryFile[];
  ilike: boolean;
  likes: number;
}
