<div class="tut_dashboard_start" *ngIf="_show|async">
  <section-title icon="dashboard" label="Dashboard">
    <div style="text-align: right" rightbuttons>

      <div *ngIf="!(edit_mode|async) && !(isMobile|async)">
        <button mat-icon-button (click)="startEdit()" matTooltip="Bearbeiten" class="tut_edit_dashboard">
          <svg-icon>bearbeiten</svg-icon>
        </button>
      </div>

      <div *ngIf="(edit_mode|async) && !(isMobile|async)">
        <button mat-icon-button (click)="addWidget()" matTooltip="Widget hinzufügen"
                [disabled]="available_widgets.length <= 0" class="tut_edit_dashboard_add_widget">
          <svg-icon>plus</svg-icon>
        </button>

        <button mat-icon-button (click)="resetDashboard()" matTooltip="Auf Werkseinstellung zurücksetzen"
                class="tut_edit_dashboard_reset">
          <svg-icon>neustart</svg-icon>
        </button>

        <button mat-icon-button (click)="saveUser()" matTooltip="Speichern" class="tut_edit_dashboard_add_save">
          <svg-icon>speichern</svg-icon>
        </button>

        <permission-block [oneOfRoles]="['Admin']">
          <button mat-icon-button (click)="resetDashboardDomain()" matTooltip="Domain auf Werkseinstellung zurücksetzen"
                  style="color: red">
            <svg-icon>neustart</svg-icon>
          </button>

          <button mat-icon-button (click)="saveDomain()" matTooltip="Als Domain Standard speichern" style="color: red">
            <svg-icon>speichern</svg-icon>
          </button>
        </permission-block>

        <button mat-icon-button (click)="cancelEdit()" matTooltip="Abbrechen" class="tut_edit_dashboard_add_cancel">
          <svg-icon>abbrechen</svg-icon>
        </button>
      </div>
    </div>
  </section-title>

  <div class="dashboardgrid" style="height: calc(var(--app-content-height) - var(--app-section-title-height))"
       *ngIf="!(isMobile|async) && (options|async) && (dashboard|async)">
    <gridster [options]="options|async">
      <gridster-item [item]="item" *ngFor="let item of dashboard|async">
        <div class="item-buttons" *ngIf="(edit_mode|async) && !(isMobile|async)">
          <table style="width: 100%">
            <tr>
              <td>
                <button mat-icon-button class="drag-handler tut_dashboard_edit_drag">
                  <svg-icon>zuordnung</svg-icon>
                </button>
              </td>
              <td style="text-align: right">
                <button mat-icon-button class="remove-button tut_dashboard_edit_rm" (click)="removeItem($event, item)"
                        (touchstart)="removeItem($event, item)" matTooltip="Löschen">
                  <svg-icon>loeschen</svg-icon>
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div [ngSwitch]="item.widget" (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()"
             style="height: 100%; width: 100%;">
          <app-dashboard-widgets-news *ngSwitchCase="'DashboardWidgetsNewsComponent'"></app-dashboard-widgets-news>
          <app-dashboard-widgets-imp-warenkorb-ticker
            *ngSwitchCase="'DashboardWidgetsImpWarenkorbTickerComponent'"></app-dashboard-widgets-imp-warenkorb-ticker>
          <app-dashboard-widgets-merklisten
            *ngSwitchCase="'DashboardWidgetsMerklistenComponent'"></app-dashboard-widgets-merklisten>
          <app-dashboard-widgets-shop-views
            *ngSwitchCase="'DashboardWidgetsShopViewsComponent'"></app-dashboard-widgets-shop-views>
          <app-dashboard-widgets-warenkoerbe
            *ngSwitchCase="'DashboardWidgetsWarenkoerbeComponent'"></app-dashboard-widgets-warenkoerbe>
          <app-dashboard-widgets-current-warenkorb
            *ngSwitchCase="'DashboardWidgetsCurrentWarenkorbComponent'"></app-dashboard-widgets-current-warenkorb>
          <app-dashboard-widgets-favourite-menu-buttons
            *ngSwitchCase="'DashboardWidgetsFavouriteMenuButtonsComponent'"></app-dashboard-widgets-favourite-menu-buttons>
          <span *ngSwitchDefault>{{item.widget}}</span>
        </div>
      </gridster-item>
    </gridster>
  </div>
  <div class="dashboardgrid mobile" style="height: calc(var(--app-height) - var(--app-section-title-height)) + 20px"
       *ngIf="(isMobile|async) && (options|async) && (dashboardMobile|async)">
    <div *ngFor="let item of dashboardMobile|async" class="mobilebox">
      <div [ngSwitch]="item.widget" (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()"
           style="height: 100%; width: 100%;">
        <app-dashboard-widgets-news *ngSwitchCase="'DashboardWidgetsNewsComponent'"></app-dashboard-widgets-news>
        <app-dashboard-widgets-imp-warenkorb-ticker
          *ngSwitchCase="'DashboardWidgetsImpWarenkorbTickerComponent'"></app-dashboard-widgets-imp-warenkorb-ticker>
        <app-dashboard-widgets-merklisten
          *ngSwitchCase="'DashboardWidgetsMerklistenComponent'"></app-dashboard-widgets-merklisten>
        <app-dashboard-widgets-shop-views
          *ngSwitchCase="'DashboardWidgetsShopViewsComponent'"></app-dashboard-widgets-shop-views>
        <app-dashboard-widgets-warenkoerbe
          *ngSwitchCase="'DashboardWidgetsWarenkoerbeComponent'"></app-dashboard-widgets-warenkoerbe>
        <app-dashboard-widgets-current-warenkorb
          *ngSwitchCase="'DashboardWidgetsCurrentWarenkorbComponent'"></app-dashboard-widgets-current-warenkorb>
        <app-dashboard-widgets-favourite-menu-buttons
          *ngSwitchCase="'DashboardWidgetsFavouriteMenuButtonsComponent'"></app-dashboard-widgets-favourite-menu-buttons>
        <span *ngSwitchDefault>{{item.widget}}</span>
      </div>
    </div>
  </div>
</div>

