<h2 mat-dialog-title>
  <div style="width: 30px; display: inline-block">
    <svg-icon>frage</svg-icon>
  </div>
  <span *ngIf="!fdata.type">Wie können wir helfen?</span>
  <span *ngIf="fdata.type">{{fdata.type.label}}</span>
</h2>
<mat-dialog-content [class.mobile]="msvc.isMobile()|async">
  <div *ngIf="!fdata.type">
    <div style="height: auto">
      <ng-container *ngFor="let t of types">
        <div class="typebutton" (click)="selectType(t)" *ngIf="t.show">
          <div class="icondiv">
            <svg-icon *ngIf="t.icon">{{t.icon}}</svg-icon>
          </div>
          <div style="padding-left: 10px">
            {{t.label}}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="fdata.type">
    <mat-form-field style="width: 100%">
      <mat-label>Betreff</mat-label>
      <input matInput [(ngModel)]="fdata.label" placeholder="Bitte Betreff eingeben">
    </mat-form-field>

    <br>
    <mat-form-field style="width: 100%">
      <mat-label>Text</mat-label>
      <textarea matInput [(ngModel)]="fdata.text" rows="10" placeholder="Bitte stellen Sie Ihre Frage"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div *ngIf="fdata.type && fdata.type.id == 'technical'" style="text-align: center; width: 100%">
    <button mat-raised-button (click)="dlTeamviewer()">Fernwartung starten</button>
  </div>
  <table style="width: 100%">
    <tr>
      <td>
        <button mat-button mat-dialog-close>
          <svg-icon>abbrechen</svg-icon>
          Abbrechen
        </button>
      </td>

      <td style="text-align: right">
        <button [mat-dialog-close]="true" (click)="send()" mat-button *ngIf="fdata.type" [disabled]="fdata.label == '' || fdata.text == ''" >
          <svg-icon>senden</svg-icon>
          Senden
        </button>
      </td>
    </tr>
  </table>
</mat-dialog-actions>
