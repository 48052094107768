import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({providedIn:"root"})
export class AppDashboardService {
  constructor(private http: HttpClient) {
  }

  getConfig(): Observable<any> {
    return this.http.get<any>(
      '/dashboard/get_config'
    );
  }

  setConfig(config): Observable<any> {
    return this.http.post<any>(
      '/dashboard/set_config',
      {
        config: config
      }
    );
  }

  setDomainDefaultConfig(config): Observable<any> {
    return this.http.post<any>(
      '/admin/dashboard/set_domain_config',
      {
        config: config
      }
    );
  }
}
