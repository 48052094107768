import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppMainMenuButtonService} from '../../services/app.main-menu-button.service';
import {Subscription} from 'rxjs';
import {AppEventService} from '../../services/app.event.service';

@Component({
  selector: 'main-menu-button',
  templateUrl: 'main-menu-button.component.html',
  styleUrls: ['main-menu-button.component.scss']
})
export class MainMenuButtonComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  active = false;

  sub: Subscription;
  esub: Subscription;

  constructor(private svc: AppMainMenuButtonService, private esvc: AppEventService) {
  }

  ngOnDestroy(): void {
    if (this.sub instanceof Subscription) {
      this.sub.unsubscribe();
    }
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.sub = this.svc.active$.subscribe(a => this.active = a);
    this.esub = this.esvc.getQueue().subscribe(e => {
      if (e.name == 'gui.app_body_click') {
        if (this.active && !this.svc.lock_clicked) {
          // this.click();
        }
      }
    });
  }

  click() {
    this.svc.lock_clicked = true;
    this.svc.click();
    setTimeout(() => {
      this.svc.lock_clicked = false;
    }, 100);
  }
}
