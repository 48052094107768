<div *ngIf="data">
  <h1 mat-dialog-title style="border-bottom: 1px solid lightgrey">{{cfg.domainconfig.pagetitle}} Privatsphäre Einstellungen</h1>
  <div mat-dialog-content *ngIf="!details">
    <p>Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind notwendig, während andere
      uns helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden
      (z. B. IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung.
      Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer
      <a *ngIf="cfg.domainconfig.url_datenschutz" href="{{cfg.domainconfig.url_datenschutz}}">
        Datenschutzerklärung
      </a>. Sie können
      Ihre Auswahl jederzeit unter Privatsphäre(Rechts unten) widerrufen oder anpassen. Hier finden Sie eine Übersicht über alle
      verwendeten Cookies. Sie können Ihre Einwilligung zu ganzen Kategorien geben oder sich weitere Informationen
      anzeigen lassen und so nur bestimmte Cookies auswählen.
    </p>
  </div>
  <div mat-dialog-actions style="justify-content: center" *ngIf="!details">
    <button mat-button (click)="showDetails()">Zwecke anzeigen</button>
    <button mat-raised-button style="background-color: green; color: white" cdkFocusInitial (click)="acceptAll()">
      Akzeptieren
    </button>
  </div>


  <div mat-dialog-content *ngIf="details">
    <h6>Ihre Privatsphäre</h6>
    <p>
      Wir verarbeiten Ihre Daten, um Inhalte oder Anzeigen bereitzustellen, und analysieren die Bereitstellung solcher
      Inhalte oder Anzeigen, um Erkenntnisse über unsere Website zu gewinnen. Sie können Ihr Recht
      auf Einwilligung oder Widerspruch gegen ein berechtigtes Interesse ausüben, und zwar auf der Grundlage eines der
      folgenden bestimmten Zwecke.
    </p>
    <button mat-raised-button style="background-color: green; color: white" (click)="acceptAll()">Alle zulassen</button>


    <h6 style="margin-top: 50px">Einwilligungspräferenzen verwalten</h6>
    <table class="table table-striped table-bordered">
      <tr>
        <td style="cursor: help">
          Unbedingt erforderliche Cookies
          <span class="infospan" matTooltip="Es gibt Cookies, welche für die grundlegende Funktion der Anwendung zwingend nötig sind.">
            <svg-icon>info</svg-icon>
          </span>
        </td>
        <td style="width: 1%; white-space: nowrap; color: green">
          Immer aktiv
        </td>
      </tr>
      <tr *ngFor="let key of keys">
        <td style="cursor: help">
          {{data.getLabel(key)}}
          <span class="infospan" matTooltip="{{data.getInfo(key)}}">
            <svg-icon>info</svg-icon>
          </span>
        </td>
        <td style="text-align: center">
          <mat-slide-toggle [(ngModel)]="data[key]"></mat-slide-toggle>
        </td>
      </tr>
    </table>

  </div>
  <div mat-dialog-actions style="justify-content: right" *ngIf="details">
    <button mat-raised-button style="border: 1px solid green" cdkFocusInitial (click)="close()">Auswahl bestätigen
    </button>
  </div>
</div>
