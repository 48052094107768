import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomainSuggestion} from "../../models/domain-suggestion";
import {AppMobileService} from "../../services/app.mobile.service";

@Component({
  templateUrl: 'app.dialogs.domain-suggestions.component.html',
  styleUrls: ['app.dialogs.domain-suggestions.component.scss']
})
export class AppDialogsDomainSuggestionsComponent {

  constructor(public dialogRef: MatDialogRef<AppDialogsDomainSuggestionsComponent>,
              @Inject(MAT_DIALOG_DATA) public suggestions: DomainSuggestion[], public msvc: AppMobileService) {
  }
}
