<div class="filebrowser">
  <div class="treebox">
    <mat-tree *ngIf="entries" [treeControl]="treeControl" [dataSource]="dataSource" class="filebrowser-tree">
      <!-- This is the tree node template for leaf nodes -->
      <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="setActiveEntry(node)" [class.active]="node == activeEntry">
        <span *ngIf="node.type == 'dir'">
          <svg-icon>dateibrowser-ordner</svg-icon>
        </span>
        <span *ngIf="node.type != 'dir'">
          <img height="25" src="assets/fileicons/{{prettyFileIcons.getIcon(node.label, 'svg')}}"/>
        </span>
        <div class="labelbox">
          {{node.label}}
        </div>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node" matTreeNodeToggle  (click)="setActiveEntry(node)" [class.dirnode]="node.type == 'dir'" [class.active]="node == activeEntry">
          <svg-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'weniger' : 'mehr'}}
          </svg-icon>
          <span *ngIf="node.type == 'dir'">
            <svg-icon *ngIf="!treeControl.isExpanded(node)">dateibrowser-ordner</svg-icon>
            <svg-icon *ngIf="treeControl.isExpanded(node)">dateibrowser-ordner-offen</svg-icon>
          </span>
          <span *ngIf="node.type != 'dir'">
            <img height="25" src="assets/fileicons/{{prettyFileIcons.getIcon(node.label, 'svg')}}"/>
          </span>
          <div class="labelbox">
            {{node.label}}
          </div>
        </div>
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.filebrowser-tree-invisible]="!treeControl.isExpanded(node)"
             role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>

  <div class="actions">
    <div *ngIf="activeEntry">
      <div *ngIf="activeEntry.type == 'dir'">
        <button mat-raised-button (click)="editFolder()" *ngIf="edit.observed && !activeEntry.is_root" style="margin-bottom: 5px">
          <svg-icon>bearbeiten</svg-icon> Umbenennen
        </button>
        <button mat-raised-button (click)="deleteFolder()" *ngIf="delete.observed && !activeEntry.is_root" style="margin-bottom: 5px">
          <svg-icon>loeschen</svg-icon> Löschen
        </button>
        <button mat-raised-button (click)="newFolder()" *ngIf="new.observed" style="margin-bottom: 5px">
          <svg-icon>dateibrowser-ordner</svg-icon> Neuer Ordner
        </button>
        <div *ngIf="upload.observed">
          <button mat-raised-button (click)="fileupload.click()" style="margin-bottom: 5px">
            <svg-icon>upload</svg-icon> Hochladen
          </button>
          <input type="file" style="display: none"
                 id="file" multiple #fileupload
                 (change)="handleFileInput($event.target.files)">
        </div>
      </div>
      <div *ngIf="activeEntry.type == 'file'">
        <button mat-raised-button (click)="do_select()" *ngIf="select.observed" style="margin-bottom: 5px">
          Auswählen
        </button>
        <button mat-raised-button (click)="start_download()" *ngIf="download.observed" style="margin-bottom: 5px">
          <svg-icon>downloads</svg-icon> Herunterladen
        </button>
        <button mat-raised-button (click)="deleteFile()" *ngIf="delete.observed">
          <svg-icon>loeschen</svg-icon> Löschen
        </button>
      </div>
    </div>
  </div>
  <div style="clear: both"></div>
</div>
