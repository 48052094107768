<table class="table table-body-striped noborders" style="width: 100%" *ngIf="(msvc.isMobile()|async)">
  <tbody *ngFor="let aktion of (aktionen|async)?.aktionen">
  <tr>
    <td>-</td>
    <td class="th">
      Von
    </td>

    <td>
      <span *ngIf="!aktion.GueltigVon">-</span>
      <span *ngIf="aktion.GueltigVon">{{ aktion.GueltigVon }}</span>
    </td>
  </tr>
  <tr>
    <td></td>
    <td class="th">
      Bis
    </td>

    <td>
      <span *ngIf="!aktion.GueltigBis">-</span>
      <span *ngIf="aktion.GueltigBis">{{ aktion.GueltigBis }}</span>
    </td>
  </tr>
  <tr *ngIf="aktion.AbMenge">
    <td></td>
    <td class="th">
      Ab Menge
    </td>

    <td>
      <span>{{ aktion.AbMenge }}</span>
      <button mat-icon-button (click)="selectMenge(aktion)" matTooltip="Menge übernehmen">
        <svg-icon>eingang-rechnung</svg-icon>
      </button>
    </td>
  </tr>
  <tr *ngIf="aktion.Draufgabemenge">
    <td></td>
    <td class="th">
      Draufgabemenge
    </td>

    <td>
      <span>{{ aktion.Draufgabemenge }}</span>
      <span *ngIf="aktion.Draufgabeartikel"> - {{ aktion.Draufgabeartikel.ArtikelName }}</span>
    </td>
  </tr>
  <tr *ngIf="aktion.AktionsrabattProzentsatz">
    <td></td>
    <td class="th">
      Aktionsprozentsatz
    </td>

    <td>
      <span>{{ aktion.AktionsrabattProzentsatz }} %</span>
    </td>
  </tr>
  <tr class="noborderbottom" *ngIf="aktion.AktionsKundeneinkaufspreis">
    <td></td>
    <td class="th">
      Aktionspreis
    </td>
    <td>
      <span>{{ aktion.AktionsKundeneinkaufspreis }} &euro;</span>
      <feature-block [features]="['ShopAktionenDurchrechnen']">
        <span *ngIf="!aktion.AktionsKundeneinkaufspreis">
          <span *ngIf="aktion.Draufgabemenge">
          {{ (((aktionen|async)?.artikel.PreisLtPreiscodeMitPotentiellAufschlag * aktion.AbMenge) / (aktion.AbMenge + aktion.Draufgabemenge))|NiceNumber }}
            &euro;<br>(durchgerechnet)
        </span>
        <span *ngIf="aktion.AktionsrabattProzentsatz">
          {{ ((aktionen|async)?.artikel.PreisLtPreiscodeMitPotentiellAufschlag * (1 - (aktion.AktionsrabattProzentsatz / 100)))|NiceNumber }}
          &euro;<br>(durchgerechnet)
        </span>
        </span>
      </feature-block>
    </td>
  </tr>
  <tr class="nobordertop noborderbottom">
    <td colspan="3">&nbsp;</td>
  </tr>
  </tbody>
</table>


<table class="table table-striped" style="width: 100%" *ngIf="!(msvc.isMobile()|async)">
  <thead>
  <tr>
    <th class="text-center">
      Von
    </th>
    <th class="text-center">
      Bis
    </th>
    <th class="text-center">
      Ab Menge
    </th>
    <th class="text-center">
      Draufgabemenge
    </th>
    <th class="text-center">
      Aktionsprozentsatz
    </th>
    <th class="text-center">
      Aktionspreis
    </th>
  </tr>
  </thead>
  <tr *ngFor="let aktion of (aktionen|async)?.aktionen">
    <td class="text-center">
      <span *ngIf="!aktion.GueltigVon">-</span>
      <span *ngIf="aktion.GueltigVon">{{ aktion.GueltigVon }}</span>
    </td>
    <td class="text-center">
      <span *ngIf="!aktion.GueltigBis">-</span>
      <span *ngIf="aktion.GueltigBis">{{ aktion.GueltigBis }}</span>
    </td>
    <td class="text-center">
      <span *ngIf="aktion.AbMenge">{{ aktion.AbMenge }}</span>
      <button mat-icon-button (click)="selectMenge(aktion)" matTooltip="Menge übernehmen">
        <svg-icon>eingang-rechnung</svg-icon>
      </button>
    </td>
    <td class="text-center">
      <span *ngIf="aktion.Draufgabemenge">{{ aktion.Draufgabemenge }}</span>
      <span *ngIf="aktion.Draufgabeartikel"> - {{ aktion.Draufgabeartikel.ArtikelName }}</span>
    </td>
    <td class="text-center">
      <span *ngIf="aktion.AktionsrabattProzentsatz">{{ aktion.AktionsrabattProzentsatz }} %</span>
    </td>
    <td class="text-center">
      <span *ngIf="aktion.AktionsKundeneinkaufspreis">{{ aktion.AktionsKundeneinkaufspreis }} &euro;</span>
      <feature-block [features]="['ShopAktionenDurchrechnen']">
        <span *ngIf="!aktion.AktionsKundeneinkaufspreis">
          <span *ngIf="aktion.Draufgabemenge">
          {{ (((aktionen|async)?.artikel.PreisLtPreiscodeMitPotentiellAufschlag * aktion.AbMenge) / (aktion.AbMenge + aktion.Draufgabemenge))|NiceNumber }}
            &euro;<br>(durchgerechnet)
        </span>
        <span *ngIf="aktion.AktionsrabattProzentsatz">
          {{ ((aktionen|async)?.artikel.PreisLtPreiscodeMitPotentiellAufschlag * (1 - (aktion.AktionsrabattProzentsatz / 100)))|NiceNumber }}
          &euro;<br>(durchgerechnet)
        </span>
        </span>
      </feature-block>
    </td>
  </tr>
</table>
