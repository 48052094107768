<div class="wkmenu">
  <div *ngIf="!add_mode">
    <div class="title">Warenkorb auswählen</div>
    <div (click)="addToWarenkorb(wk, data.entry);" *ngFor="let wk of wksvc.warenkoerbe$|async"
         class="menuitem">
      <svg-icon *ngIf="(wksvc.primary_warenkorb$|async) && (wksvc.primary_warenkorb$|async)?.id == wk.id"
                style="font-size: 12px; height: 12px">radiobutton-gefuellt
      </svg-icon>
      <svg-icon *ngIf="(wksvc.primary_warenkorb$|async) && (wksvc.primary_warenkorb$|async)?.id != wk.id"
                style="font-size: 12px; height: 12px">radiobutton-leer
      </svg-icon>
      {{wk.label}}
    </div>
    <div class="spacer"></div>
    <div (click)="add()" class="menuitem">
      <svg-icon>warenkorb-hinzufuegen</svg-icon>
      Neuer Warenkorb
    </div>
  </div>
  <div *ngIf="add_mode">
    <div class="title">Neuer Warenkorb</div>
    <div class="paddedcontent">
      <mat-form-field appearance="outline">
        <input [(ngModel)]="newwk.label" matInput
               placeholder="Bezeichnung">
      </mat-form-field>
      <br>
      <mat-checkbox [(ngModel)]="newwk.shared">Geteilt</mat-checkbox>

      <hr>

      <button (click)="save()" mat-button>
        <svg-icon>speichern</svg-icon>
        Speichern & Hinzufügen
      </button>

      <button (click)="cancel()" mat-button>
        <svg-icon>abbrechen</svg-icon>
        Abbrechen
      </button>
    </div>
  </div>
</div>
