<div class="scannerbox" *ngIf="showScanner|async" [class.bcFound]="bcFound|async">
  <h1>Scan</h1>
  <p>Scannen Sie den Barcode oder den Datamatrix des Produkts.</p>
  <div class="scannerboxinner">
    <div class="scannermarker1"></div>
    <zxing-scanner [formats]="formats"
                   (camerasNotFound)="noCam()"
                   (scanComplete)="scanComplete($event)"
                   [enable]="true"
                   [autofocusEnabled]="true">
    </zxing-scanner>
  </div>

  <div style="text-align: center">
    <button mat-raised-button (click)="endScan()">
      <svg-icon>abbrechen</svg-icon>
      Abbrechen
    </button>
  </div>
</div>
