<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <mat-select
    (selectionChange)="_change()"
    [(value)]="fields"
    [multiple]="true"
    placeholder="{{placeholder}}"
  >
    <ng-container *ngFor="let i of my_fields_order">
      <mat-option value="{{i}}" *ngIf="(!hidden_fields || !hidden_fields.includes(i)) && !disallowedFields.includes(i)"
        [disabled]="i == 'bezeichnung'">
        <view-field-label-render [fieldname]="i" mode="table" [customTooltip]="(i == 'bezeichnung') ? 'Pflichtfeld' : null"></view-field-label-render>
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
