<ng-container [ngSwitch]="type">
  <span *ngSwitchCase="'shop_menu_no_views'">
    Es sind für Sie derzeit leider keine Shops verfügbar / freigeschalten!
  </span>

  <span *ngSwitchCase="'app-lieferkunde-selector-no-lieferkunden'">
    Es sind für Sie derzeit leider keine Lieferanschriften verfügbar / freigeschalten!
  </span>

  <span *ngSwitchCase="'app-lieferkunde-selector-no-lieferkunden-filtered'">
    Keine Lieferanschrift entspricht Ihrer Suche!
  </span>

  <span *ngSwitchCase="'app-lieferkunde-selector-no-lieferkunden-filtered-vertreter'">
    Bitte suchen Sie nach einem Kunden!
  </span>

  <span *ngSwitchDefault>Unbekannter Fehler</span>
</ng-container>
