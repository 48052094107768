<section-title [hasBack]="true" [label]="'Passwort ändern'" icon="passwort-aendern"></section-title>


<div>
  <div>
    <div class="loginbox">
      <div class="bar">Passwort Ändern</div>

      <div *ngIf="frmgrp">
        <form [formGroup]="frmgrp">
          <div *ngIf="!(message|async)">
            <p>Bitte geben Sie Ihr neues Passwort ein</p>
            <mat-form-field style="width: 300px" appearance="outline">
              <mat-label>Passwort</mat-label>
              <input matInput formControlName="password" type="password">
            </mat-form-field>

            <div style="text-align: left">
              <label
                class="col"
                [ngClass]="frmgrp.controls['password'].hasError('required') || frmgrp.controls['password'].hasError('minlength') || frmgrp.controls['password'].hasError('maxlength')  ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp.controls['password'].hasError('required') ||
                frmgrp.controls['password'].hasError('minlength') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Zwischen 8 und 30 Zeichen!
                </div>
              </label>
              <br>

              <label
                class="col"
                [ngClass]="frmgrp.controls['password'].hasError('required') || frmgrp.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp.controls['password'].hasError('required') ||
                frmgrp.controls['password'].hasError('hasNumber') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Mindestens 1 Nummer!
                </div>
              </label>
              <br>

              <label
                class="col"
                [ngClass]="frmgrp.controls['password'].hasError('required') || frmgrp.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp.controls['password'].hasError('required') ||
                frmgrp.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Mindestens 1 Großbuchstaben!
                </div>
              </label>
              <br>

              <label
                class="col"
                [ngClass]="frmgrp.controls['password'].hasError('required') || frmgrp.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp.controls['password'].hasError('required') ||
                frmgrp.controls['password'].hasError('hasSmallCase') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Mindestens 1 Kleinbuchstaben!
                </div>
              </label>
              <br>

              <label
                class="col"
                [ngClass]="frmgrp.controls['password'].hasError('required') || frmgrp.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp.controls['password'].hasError('required') ||
                frmgrp.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Mindestens 1 Sonderzeichen!
                </div>
              </label>
            </div>

            <br>
            <mat-form-field style="width: 300px" appearance="outline">
              <mat-label>Passwort wiederholen</mat-label>
              <input matInput formControlName="confirmPassword" type="password">
            </mat-form-field>

            <div style="width: 280px; text-align: left" *ngIf="!frmgrp.controls['password'].hasError('required')">
              <label
                class="col"
                [ngClass]="frmgrp.controls['confirmPassword'].hasError('NoPasswordMatch') ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp.controls['confirmPassword'].hasError('NoPasswordMatch') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Passwörter stimmen nicht überein!
                </div>
              </label>
            </div>

            <div style="height: 50px; font-size: 16px; color: red">
              {{error|async}}
            </div>

            <div class="form-group">
              <button [disabled]="(loading|async) || !frmgrp.controls['password'].valid || !frmgrp.controls['confirmPassword'].valid" class="btn btn-primary" mat-flat-button (click)="send()">
                <svg-icon>senden</svg-icon>
                Speichern
              </button>
            </div>
          </div>
        </form>

        <div *ngIf="message|async">
          <h3>{{message|async}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
