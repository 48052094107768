import {Component, Input} from '@angular/core';
import {AppMobileService} from "../../../../services/app.mobile.service";

@Component({
  selector: 'dashboard-widget',
  templateUrl: 'dashboard.widget.component.html',
  styleUrls: ['dashboard.widget.component.scss']
})
export class DashboardWidgetComponent {
  @Input() footer_enabled = false;
  @Input() isMobile = false;

  constructor(public msvc: AppMobileService) {
  }
}
