import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {ShopViewComponent} from '../view/shop.view.component';

@Component({
  templateUrl: 'shop.view-filter-bottomsheet.component.html',
  styleUrls: ['shop.view-filter-bottomsheet.component.scss']
})
export class ShopViewFilterBottomsheetComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: ShopViewComponent,
              private _bottomSheetRef: MatBottomSheetRef<ShopViewFilterBottomsheetComponent>) {
  }

  ngOnInit(): void {
  }


}
