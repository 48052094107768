import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AppCrontaskService {
  constructor(private http: HttpClient, private cfg: AppConfigService) {
  }

  getnextTimeWKImportAutosendTask(): Observable<string> {
    return this.http.get<string>(
      this.cfg.buildUrl('/tasks/nexttime/WKImportAutosend')
    );
  }
}
