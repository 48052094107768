import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {View} from '../../../../models/view';
import {BehaviorSubject} from 'rxjs';
import {ShopViewComponent} from '../view/shop.view.component';
import {ArtikelWithAktionen} from '../../../../models/artikel-with-aktionen';
import {Artikel} from "../../../../models/artikel";
import {AppMobileService} from "../../../../services/app.mobile.service";
import {AppEventService} from "../../../../services/app.event.service";
import {ArtikelAktion} from "../../../../models/artikel-aktion";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'artikel-aktionen-details',
  templateUrl: 'shop.artikel-aktionen-details.component.html',
  styleUrls: ['shop.artikel-aktionen-details.component.scss']
})
export class ShopArtikelAktionenDetailsComponent implements OnChanges, OnInit {
  @Input() aktionen: BehaviorSubject<ArtikelWithAktionen>;
  @Input() view: View;
  @Input() viewcomp: ShopViewComponent;

  constructor(private cd: ChangeDetectorRef, public msvc: AppMobileService, private esv: AppEventService) {
  }

  selectMenge(aktion: ArtikelAktion) {
    this.esv.addGuiEvent('setWkEntryArtikelMenge', {artnr: this.aktionen.value.artikel.ArtikelNummer, menge: aktion.AbMenge});
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {

  }
}
