import {BonuspunktprogrammStufe} from './bonuspunktprogramm-stufe';

export class Bonuspunktprogramm {
  BonuspunktprogrammUuid: string;
  Matchcode: string;
  Bezeichnung: string;
  WeitereProgrammeErlaubt: boolean;
  BonuspunktprogrammStufen: BonuspunktprogrammStufe[];

  // Für GUi
  level: number;
}
