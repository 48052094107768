<div class="sectiontitle" [class.mobile]="(msvc.isMobile()|async)" #portal_section_title>
  <div class="sectiontitle_left">
    <div class="sectiontitle_inner_left" [style.width]="(left_full_width||(msvc.isMobile())) ? '100%' : '33.3%'">
      <table>
        <tr>
          <td *ngIf="(buttons_left && buttons_left.length > 0) || ((hasBack || backUrl) && !(msvc.isMobile()|async))">
            <button (click)="back()" *ngIf="(((hasBack && (backAvailable|async)) || backUrl) && !(msvc.isMobile()|async))" mat-icon-button title="Zurück" class="tut_back_button">
              <svg-icon>zurueck</svg-icon>
            </button>
            <ng-container *ngIf="buttons_left">
              <ng-container *ngFor="let b of buttons_left">
                <ng-container *ngIf="b.label">
                  <button (click)="b.onclick()" class="mat-button {{b.class}}" mat-button matTooltip="{{b.title}}">
                    <svg-icon *ngIf="b.icon">{{b.icon}}</svg-icon>
                    {{b.label}}
                  </button>
                </ng-container>
                <ng-container *ngIf="!b.label">
                  <button (click)="b.onclick()" class="mat-icon-button {{b.class}}" mat-icon-button
                          matTooltip="{{b.title}}">
                    <svg-icon>{{b.icon}}</svg-icon>
                  </button>
                </ng-container>
              </ng-container>
            </ng-container>
          </td>
          <td *ngIf="icon" class="icon">
            <svg-icon>{{icon}}</svg-icon>
          </td>
          <td class="label">
            {{label}}
          </td>
          <td *ngIf="(msvc.isMobile()|async) && (showMore|async)" [class.openbutton]="!(hidden|async)">
            <button mat-icon-button (click)="toggle()">
              <mat-icon *ngIf="(hidden|async)">expand_more</mat-icon>
              <mat-icon *ngIf="!(hidden|async)">expand_less</mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="sectiontitle_middle" [class.hidden]="(hidden|async) && (msvc.isMobile()|async)">
    <div class="sectiontitle_inner_middle" #contentMiddle>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="sectiontitle_right" [class.hidden]="(hidden|async) && (msvc.isMobile()|async)" #contentRight>
    <ng-content select="[rightbuttons]"></ng-content>
    <div *ngIf="buttons_right && buttons_right.length > 0" class="sectiontitle_inner_right">
      <section-title-buttons [buttons]="buttons_right"></section-title-buttons>
    </div>
  </div>
</div>
