<ng-container  *ngIf="(entries|async) && (entries|async)?.length > 0">
  <div class="infoline">
    <div class="infobox" *ngIf="infoText">
      {{infoText}}
    </div>
    <div class="infobox warning" *ngIf="(viewcomp && viewcomp.show_warning|async)">
      <svg-icon>warning</svg-icon>
      <div class="text">{{view.settings.warning_above_amount_text}}</div>
    </div>

    <div class="addfieldbox">
      <view-field-selector label="Anzuzeigende Felder" class="tut_shopview_fields" [(fields)]="active_fields"
                           [hidden_fields]="view.settings.hidden_fields" (change)="updateView()"></view-field-selector>
    </div>
    <div style="clear: both"></div>
  </div>
  <div class="artikelboxholder" [class.mobile]="msvc.isMobile()|async">
    <div *ngFor="let artikel of entries|async; trackBy: trackByArtNr" class="artikelbox mat-elevation-z1"
         data-track-content
         [attr.data-content-name]="'Artikel '+(artikel.ArtikelName ? artikel.ArtikelName : artikel.ArtikelBezeichnung)+' ('+artikel.ArtikelNummer+')'">
      <div class="titlebox">
        <div class="underlined">
          <a routerLink="/shop/{{view.slug}}/{{artikel.ArtikelNummer}}">
            <strong>
              <view-field-value-render [artikel]="artikel" [fieldname]="'ArtikelName'"
                                       mode="box" [nowrap]="false"></view-field-value-render>
            </strong>
          </a>
        </div>
      </div>
      <div class="innerbox">
        <table style="width: 100%">
          <tr>
            <td>
              <table style="width: 100%">
                <tbody *ngFor="let fieldname of view_fields$|async; trackBy: trackByValue" [attr.data-content-piece]="fieldname">
                <tr *ngIf="fieldname != 'ArtikelName'">
                  <td style="vertical-align: top">
                    <b>
                      <view-field-label-render [fieldname]="fieldname" mode="box"></view-field-label-render>
                    </b>
                    <div style="position: relative; margin-top: -10px; display: inline-block">

                    </div>
                  </td>
                  <td style="vertical-align: top">
                    <view-field-value-render
                      [artikel]="artikel"
                      [fieldname]="fieldname"
                      [view]="view"
                      [viewcomp]="viewcomp"
                      [nowrap]="false"
                      mode="box"
                    ></view-field-value-render>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
            <td style="width: 100px; text-align: center; vertical-align: top">
              <shop-view-action [viewcomp]="viewcomp" [artikel]="artikel" [view]="view"
                                [row_mode]="true"></shop-view-action>
            </td>
          </tr>
        </table>


        <div
          *ngIf="(visible_artikel_aktionen|async) && (visible_artikel_aktionen|async)?.artikel.ArtikelNummer == artikel.ArtikelNummer"
          style="background-color: white">
          <artikel-aktionen-details [aktionen]="visible_artikel_aktionen"></artikel-aktionen-details>
        </div>
      </div>
    </div>
  </div>
</ng-container>
