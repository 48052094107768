import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Warenkorb} from '../../../../models/warenkorb';
import {ActivatedRoute, Router} from '@angular/router';
import {AppEventService} from '../../../../services/app.event.service';
import {AppPageloaderService} from '../../../../services/app.pageloader.service';
import {AppViewService} from '../../../../services/app.view.service';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {AppDialogsService} from '../../../../services/app.dialogs.service';
import {ShopWarenkorbActionInfoComponent} from '../warenkorb-action-info/shop.warenkorb-action-info.component';
import {WarenkorbActionInfo} from '../../../../models/warenkorb-action-info';
import {SectionTitleButton} from '../../../shared/models/section-title-button';
import {AppBackService} from '../../../../services/app.back.service';
import {WarenkorbKategorie} from '../../../../models/warenkorb-kategorie';
import {AppMessageService} from '../../../../services/app.message.service';
import {AppPageTitleService} from '../../../../services/app.page-title.service';
import {AppCrontaskService} from '../../../../services/app.crontask.service';
import {AppSettingsService} from '../../../../services/app.app_settings.service';
import {ShopWarenkorbTableInterface} from '../../interfaces/shop.warenkorb-table-interface';
import {AppConfigService} from '../../../../services/app.config.service';
import {ViewSettings} from "../../../../models/view-settings";
import {AppLieferkundeService} from "../../../../services/app.lieferkunde.service";
import {AppUserService} from "../../../../services/app.user.service";
import {SimplebarAngularComponent} from "simplebar-angular";
import {AppKeyValueStorageService} from "../../../../services/app.key-value-storage.service";
import {AppSnackbarService} from "../../../../services/app.snackbar.service";
import * as moment from "moment";
import {AppAsyncTimeout} from "../../../../helpers/app.asyncTimeout";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'warenkorb-box',
  templateUrl: 'shop.warenkorb-box.component.html',
  styleUrls: ['shop.warenkorb-box.component.scss']
})
export class ShopWarenkorbBoxComponent implements OnInit, OnDestroy, OnChanges, ShopWarenkorbTableInterface {
  @Input() primary_warenkorb: Warenkorb;
  @Input() warenkorb: Warenkorb;
  @Input() send_position_from_bottom = false;
  @Input() with_top_right_actions = true;
  @Input() send_button_pos_fixed = true;

  @Input() buttons_right: SectionTitleButton[] = [];
  @Output() buttons_rightChange = new EventEmitter<SectionTitleButton[]>();

  @Output() reload = new EventEmitter();

  @Input() widget_mode = false;
  @Input() ajax_mode = false;

  @ViewChild('wkscroll') wkscroll: SimplebarAngularComponent;

  active_fields = [];

  rsub: Subscription;

  group = new BehaviorSubject<boolean>(true);
  group_val = true;


  pwksub: Subscription;

  positions = new BehaviorSubject<WarenkorbEntry[]>([]);
  positions$ = this.positions.asObservable();

  note_autosave_timeout: any;

  scrollTop: number;
  lockScrollTop = false;

  poscount = 0;

  esub: Subscription;
  vcfgsub: Subscription;

  label_map = {};

  update_timeout: any = false;
  on_update_timeout: any;
  on_update_timeout_done: any;

  valuta_edited = false
  liefernAb_edited = false;
  note_edited = false;
  reTage_edited = false;
  valid = new BehaviorSubject(false);

  buttons_right_add = [
    {
      label: '',
      title: 'Warenkorb leeren',
      icon: 'leeren',
      onclick: () => {
        this.empty();
      },
      class: 'mat-icon-button color_orange tut_warenkorb_clear',
    },
    {
      label: '',
      title: 'Warenkorb umbenennen',
      icon: 'texteingabe',
      onclick: () => {
        this.rename();
      },
      class: 'mat-icon-button color_blue tut_warenkorb_rename',
    },
    {
      label: '',
      title: 'Warenkorb löschen',
      icon: 'loeschen',
      onclick: () => {
        this.delete();
      },
      class: 'mat-icon-button color_red tut_warenkorb_delete',
    }
  ];

  view_settings: ViewSettings[] | any;

  kategorien = new BehaviorSubject<WarenkorbKategorie[]>([]);

  next_cron_import_run = new BehaviorSubject<string>(null);
  cron_import_enabled = new BehaviorSubject<boolean>(false);

  vsub: Subscription;

  posUpdatePending = false;

  valutaDate: any;
  liefernAbDate: any;
  reTage: any;

  constructor(private route: ActivatedRoute, private svc: AppWarenkorbService, private esvc: AppEventService,
              private loader: AppPageloaderService, private snackBarSvc: AppSnackbarService, public vsvc: AppViewService,
              private dsvc: AppDialogsService, private backsvc: AppBackService, private msg: AppMessageService,
              private tsvc: AppPageTitleService, private router: Router, private ctsvc: AppCrontaskService,
              private settsvc: AppSettingsService, private cd: ChangeDetectorRef, public cfg: AppConfigService,
              private usvc: AppUserService, private lksvc: AppLieferkundeService,
              private kvstorage: AppKeyValueStorageService) {
    this.kvstorage.load4User('Warenkorb_group').subscribe(group => {
      if (group == 1) {
        this.group.next(true);
        this.group_val = true;
      } else {
        this.group.next(false);
        this.group_val = false;
      }
    });
  }

  valutaSelected(event) {
    this.valuta_edited = true;
    if (event && event.value) {
      this.warenkorb.valutaDate = event && event.value.format('DD.MM.YYYY');
    } else {
      this.warenkorb.valutaDate = null;
    }
  }

  liefernAbSelected(event) {
    this.liefernAb_edited = true;
    if (event && event.value) {
      this.warenkorb.liefernAb = event && event.value.format('DD.MM.YYYY');
    } else {
      this.warenkorb.liefernAb = null;
    }
  }

  reTageSelected() {
    this.reTage_edited = true;
  }

  validChange(event: any) {
    this.valid.next(event);
  }

  rename() {
    this.dsvc.form()
      .setTitle('Warenkorb umbenennen')
      .addField('name', 'Bezeichnung')
      .addButton('ok', 'Umbenennen', 'primary')
      .addButton('cancel', 'Abbrechen')
      .show((res) => {
        if (res && res.clicked_id == 'ok') {
          this.warenkorb.label = res.data.name;

          const l = this.loader.start();
          this.svc.updateWarenkorb(this.warenkorb).subscribe(m => {
            this.msg.info('Gespeichert!');
            l.stop();
          });
        }
      });
  }

  updateFields() {
    this.detectChanges();
    this.saveFields();
  }

  loadFields() {
    this.settsvc.getUserSettingValue('GUI_ShopWarenkorbColumns').subscribe(f => {
      this.active_fields = f;
      this.detectChanges();
    });
  }

  saveFields() {
    this.settsvc.setUserSettingValue('GUI_ShopWarenkorbColumns', this.active_fields).subscribe();
  }

  loadSettings() {
    this.settsvc.getCustomerSettingValue('ShopImportAutosend').subscribe(s => {
      this.cron_import_enabled.next(s);
    });
  }

  loadImpCronTime() {
    this.ctsvc.getnextTimeWKImportAutosendTask().subscribe(t => {
      this.next_cron_import_run.next(t);
    });
  }

  saveWarenkorb() {
    this.note_edited = false;
    this.reTage_edited = false;
    this.valuta_edited = false;
    this.liefernAb_edited = false;
    const l = this.loader.start();

    this.svc.updateWarenkorb(this.warenkorb).subscribe(m => {
      this.msg.info('Gespeichert!');
      l.stop();
    });
  }

  saveAutoImp() {
    const l = this.loader.start('ShopWarenkorbComponent_3');

    this.svc.updateWarenkorb(this.warenkorb).subscribe(m => {
      this.msg.info('Gespeichert!');
      l.stop();
    });
  }

  change() {
    this.group.next(this.group_val);
    this.kvstorage.save4User('Warenkorb_group', this.group.value ? 1 : 0).subscribe();
  }

  getViewLabelBySlug(slug: string): string {
    if (!this.label_map[slug]) {
      const view = this.vsvc.getViewBySlug(slug);
      if (view.settings && view.settings.label) {
        this.label_map[slug] = view.settings.label;
      } else {
        this.label_map[slug] = view.label;
      }
    }

    return this.label_map[slug];
  }

  checkPrimaryWk() {
    if (this.warenkorb && this.primary_warenkorb && this.warenkorb.id != this.primary_warenkorb.id) {
      const b = new SectionTitleButton();
      b.icon = 'radiobutton-gefuellt';
      b.title = 'Als Haupt-Warenkorb verwenden';
      b.class = 'section_title_button_highlight_green tut_warenkorb_setprimary';
      b.onclick = () => {
        this.svc.primary_warenkorb.next(this.warenkorb);
      };
      this.buttons_right = [
        b
      ];
    } else {
      this.buttons_right = [];
    }

    this.buttons_right_add.forEach(b => {
      this.buttons_right.push(b);
    });

    this.buttons_rightChange.emit(this.buttons_right);
    this.detectChanges();
  }

  load(silent = false) {
    if (this.warenkorb) {
      this.lockScrollTop = true;
      if (!this.ajax_mode && !this.widget_mode) {
        this.tsvc.setTitle('Warenkorb ' + this.warenkorb.label);
      }

      const kats: WarenkorbKategorie[] = [];
      this.warenkorb.entries.forEach(e => {
        const slug = e.view_slug;
        let found = false;
        kats.forEach(k => {
          if (k.view_slug == slug) {
            k.positionen.push(e);
            found = true;
          }
        });

        if (!found) {
          const k = new WarenkorbKategorie();
          k.positionen = [e];
          k.view_slug = slug;
          k.label = this.getViewLabelBySlug(k.view_slug);
          kats.push(k);
        }
      });

      this.positions.next(this.warenkorb.entries);
      this.poscount = this.warenkorb.entries.length;
      this.kategorien.next(kats);

      AppAsyncTimeout.setTimeout(() => {
        this.loadScroll();
      }, 1);

      this.checkPrimaryWk();
    }
  }

  ngOnDestroy(): void {
    if (this.update_timeout) {
      clearTimeout(this.update_timeout);
      this.on_update_timeout();
    }
    if (!this.ajax_mode && !this.widget_mode) {
      this.tsvc.resetTitle();
    }
    if (this.pwksub instanceof Subscription) {
      this.pwksub.unsubscribe();
    }
    if (this.vcfgsub instanceof Subscription) {
      this.vcfgsub.unsubscribe();
    }
    if (this.vsub instanceof Subscription) {
      this.vsub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const keys = Object.keys(changes);
    if (keys.length == 1 && keys[0] == 'buttons_right') {

    } else {
      this.load();
    }
  }

  ngOnInit(): void {
    if (this.warenkorb && this.warenkorb.valutaDate) {
      AppAsyncTimeout.setTimeout(() => {
        this.valutaDate = moment(this.warenkorb.valutaDate, 'DD.MM.YYYY');
      });
    }

    if (this.warenkorb && this.warenkorb.liefernAb) {
      AppAsyncTimeout.setTimeout(() => {
        this.liefernAbDate = moment(this.warenkorb.liefernAb, 'DD.MM.YYYY');
      });
    }
    this.initScrollBox();
    this.loadFields();
    this.vsub = this.vsvc.menu_views$.subscribe((vs) => {
      this.view_settings = {};
      if (vs) {
        vs.forEach(v => {
          this.view_settings[v.slug] = v.settings;
        });
      }
    });
    this.load();
    this.pwksub = this.svc.primary_warenkorb$.subscribe(wk => {
      this.primary_warenkorb = wk;
      this.checkPrimaryWk();
    });
  }

  addNoteLine(entry: WarenkorbEntry) {
    entry.print_note_lines[entry.note.length] = false;
    entry.note.push('');
  }

  deletePosition(entry: WarenkorbEntry) {
    this.dsvc.confirm(
      null,
      'Position löschen?',
      'Wollen Sie ' + entry.artikel.ArtikelName + ' wirklich aus dem Warenkorb löschen?',
      () => {
        this.loader.start('ShopWarenkorbComponent_2');
        this.svc.deleteFromWarenkorbById(this.warenkorb.id, entry.id).subscribe(m => {
          this.reload.emit();
          const i = new WarenkorbActionInfo();
          i.action = 'delete';
          i.info = m;

          this.snackBarSvc.open(ShopWarenkorbActionInfoComponent, i, 2500);

          this.loader.stop('ShopWarenkorbComponent_2');
          this.load(true);
        });
      }, () => {

      }
    );
  }

  getSumme(): number {
    let summ = 0;
    this.positions.getValue().forEach(e => {
      if (e.basisverrechnungspreis !== null) {
        summ += e.menge * e.basisverrechnungspreis;

      } else {
        summ += e.menge * e.artikel.Kundeneinkaufspreis;
      }
    });

    return summ;
  }

  getKatSumme(kat: WarenkorbKategorie) {
    let summ = 0;
    this.positions.getValue().forEach(e => {
      if (e.view_slug == kat.view_slug) {
        if (e.basisverrechnungspreis !== null) {
          summ += e.menge * e.basisverrechnungspreis;

        } else {
          summ += e.menge * e.artikel.Kundeneinkaufspreis;
        }
      }
    });

    return summ;
  }

  menge_plus(entry: WarenkorbEntry) {
    entry.menge++;
    this.updatePosition(entry);
  }

  menge_minus(entry: WarenkorbEntry) {
    entry.menge--;
    this.updatePosition(entry);
  }

  sendWk($event) {
    $event.stopImmediatePropagation();
    $event.preventDefault();

    if (this.update_timeout) {
      clearTimeout(this.update_timeout);

      this.on_update_timeout_done = () => {
        this.router.navigateByUrl('/shop/warenkorb/' + this.warenkorb.id + '/checkout');
      };

      this.on_update_timeout();

    } else {
      this.router.navigateByUrl('/shop/warenkorb/' + this.warenkorb.id + '/checkout');
    }
  }

  updatePosition(entry: WarenkorbEntry) {
    if (entry.valid) {
      entry.dirty = true;

      const print_lines = [];
      const pn = [];
      entry.note.forEach((v, i) => {
        pn[i] = false;
        if (entry.print_note_lines && entry.print_note_lines[i] === true) {
          pn[i] = true;
        }
      });

      entry.print_note_lines = pn;

      this.svc.updateForWarenkorbById(this.warenkorb.id, entry).subscribe(m => {
        entry.dirty = false;
        if (this.on_update_timeout_done) {
          this.on_update_timeout_done();
          this.on_update_timeout_done = undefined;
        }
        this.reload.emit();
      });
    } else {
      entry.dirty = false;
    }
  }

  trackByFn(index, item) {
    return index;
  }

  clear() {
    this.dsvc.confirm(
      null,
      'Warenkorb leeren?',
      'Wollen Sie den Warenkorb wirklich leeren?',
      () => {
        const l = this.loader.start();
        this.svc.emptyWarenkorb(this.warenkorb).subscribe(s => {
          if (s) {
            this.msg.info('Geleert!');
          } else {
            this.msg.info('Fehlgeschlagen!');
          }
          l.stop();
        }, () => {
          l.stop();
        });

      }, () => {

      }
    );
  }

  noteAutoSave(entry: WarenkorbEntry) {
    entry.dirty = true;

    const print_lines = [];
    const pn = [];
    entry.note.forEach((v, i) => {
      pn[i] = false;
      if (entry.print_note_lines && entry.print_note_lines[i] === true) {
        pn[i] = true;
      }
    });

    entry.print_note_lines = pn;

    if (this.note_autosave_timeout) {
      clearTimeout(this.note_autosave_timeout);
    }
    this.positions.getValue().forEach(v => {
      if (v.dirty) {
        this.updatePosition(v);
      }
    });
  }

  deleteNoteLine(entry: WarenkorbEntry, idx: number) {
    if (entry.note[idx] != '') {
      this.dsvc.confirm(
        null,
        'Textzeile löschen?',
        'Wollen Sie "' + entry.note[idx] + '" wirklich löschen?',
        () => {
          entry.note.splice(idx, 1);
          this.updatePosition(entry);
        }, () => {

        }
      );
    } else {
      entry.note.splice(idx, 1);
      this.updatePosition(entry);
    }
  }

  duplicatePosition(entry: WarenkorbEntry) {
    this.svc.duplicateFromWarenkorbById(this.warenkorb.id, entry.id).subscribe(m => {
      this.load(true);
      const i = new WarenkorbActionInfo();
      i.action = 'add';
      i.info = m;

      this.snackBarSvc.open(ShopWarenkorbActionInfoComponent, i, 2500);
    });
  }

  delete() {
    this.dsvc.confirm(
      null,
      'Warenkorb löschen?',
      'Wollen Sie den Warenkorb wirklich löschen?',
      () => {
        this.svc.deleteWarenkorb(this.warenkorb).subscribe(m => {
          const i = new WarenkorbActionInfo();
          i.action = 'wkdelete';
          i.info = m;

          this.snackBarSvc.open(ShopWarenkorbActionInfoComponent, i, 2500);
        });
        this.backsvc.back();
      }, () => {

      }
    );
  }

  empty() {
    this.dsvc.confirm(
      null,
      'Warenkorb leeren?',
      'Wollen Sie den Warenkorb wirklich leeren?',
      () => {
        this.svc.emptyWarenkorb(this.warenkorb).subscribe(m => {
          const i = new WarenkorbActionInfo();
          i.action = 'wkempty';
          i.info = m;

          this.snackBarSvc.open(ShopWarenkorbActionInfoComponent, i, 2500);
        });
      }, () => {

      }
    );
  }

  private initScrollBox() {
    if (this.wkscroll && this.wkscroll.SimpleBar) {
      this.wkscroll.SimpleBar.getScrollElement().addEventListener('scroll', (e) => {
        if (!this.lockScrollTop) {
          this.scrollTop = this.wkscroll.SimpleBar.getScrollElement().scrollTop;
        }
      });
    } else {
      AppAsyncTimeout.setTimeout(() => {
        this.initScrollBox();
      }, 200);
    }
  }

  private loadScroll() {
    if (this.wkscroll && this.wkscroll.SimpleBar) {
      this.lockScrollTop = false;
      if (this.scrollTop) {
        this.wkscroll.SimpleBar.getScrollElement().scrollTop = this.scrollTop;
      }
    } else {
      AppAsyncTimeout.setTimeout(() => {
        this.loadScroll();
      }, 200);
    }
  }

  private detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
}
