import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppSettingsService} from '../../services/app.app_settings.service';
import {AppNavbarService} from '../../services/app.navbar.service';
import {Setting} from '../../models/setting';
import {AppMessageService} from '../../services/app.message.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {AppPageTitleService} from '../../services/app.page-title.service';
import {Observable} from "rxjs";
import {SettingsBoxInterface} from "../../interfaces/settings-box-interface";

@Component({
  templateUrl: 'app.settings.component.html',
  styleUrls: ['app.settings.component.scss']
})
export class AppSettingsComponent implements OnInit, OnDestroy, SettingsBoxInterface {
  static id = 'AppSettingsComponent';
  settings: Setting[];

  value: any;

  loadReady = false;

  saveReady = false;

  constructor(private svc: AppSettingsService, private nbsvc: AppNavbarService, private msg: AppMessageService,
              private loader: AppPageloaderService, private tsvc: AppPageTitleService) {
  }

  ngOnDestroy(): void {
    this.nbsvc.clear();
    this.tsvc.resetTitle();
  }

  ngOnInit(): void {
    this.nbsvc.setLabel('Einstellungen');
    this.tsvc.setTitle('Einstellungen');
    this.load();
  }

  onClick(id) {
    this.svc.clickUserSetting(id).subscribe(s => {
      if (s) {
        this.msg.info(s);
      } else {
        this.msg.error(s);
      }
    });
  }

  onTest(id, val): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.svc.testUserSettings(id, val).subscribe(s => {
        obs.next(s);
        obs.complete();
      });
    });
  }

  load() {
    this.svc.getUserSettings().subscribe(s => {
      this.settings = s;
    });
  }

  save() {
    this.saveReady = false;

    let l = this.loader.start();

    this.svc.setUserSettings(this.value).subscribe({
      next: (s) => {
        if (s) {
          this.msg.info('Gespeichert!');
        } else {
          this.msg.info('Fehler!');
        }
        l.stop();

      }, error: () => {
        this.saveReady = true;
        l.stop();
      }
    });
  }
}
