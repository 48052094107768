import {Component, OnDestroy, OnInit} from "@angular/core";
import {AppLieferkundeService} from "../../../../services/app.lieferkunde.service";
import {AppConfigService} from "../../../../services/app.config.service";
import {Router} from "@angular/router";
import {AppDialogsService} from "../../../../services/app.dialogs.service";
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'differential-lk-banner',
  templateUrl: 'app.differential-lk-banner.component.html',
  styleUrls: ['app.differential-lk-banner.component.scss']
})
export class AppDifferentialLkBannerComponent implements OnInit, OnDestroy {

  name = new BehaviorSubject('');

  difflksub: Subscription;

  constructor(public lksvc: AppLieferkundeService, public cfg: AppConfigService, private router: Router,
              private dsvc: AppDialogsService) {
  }

  ngOnDestroy(): void {
    if (this.difflksub instanceof Subscription) {
      this.difflksub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.difflksub = this.lksvc.current_differential_lieferkunde$.subscribe(difflk => {
      let name = "";

      if (difflk) {
        if (this.cfg.domainconfig && this.cfg.domainconfig.show_customer_numbers) {
          if (this.cfg.domainconfig.showA2KndNrOverwrite == 'A2KndNrDefault') {
            name += difflk.A2KndNrDefault + ' - ';
          } else if (this.cfg.domainconfig.showA2KndNrOverwrite == 'A2KndNrOrganisationseinheit') {
            name += difflk.A2KndNr + ' - ';
          } else {
            name += difflk.LieferkundeNr + ' - ';
          }
        }

        if (difflk.Vorname != "" && difflk.Vorname != "**") {
          name += difflk.Vorname;
        }

        if (difflk.Nachname != "" && difflk.Nachname != "**") {
          if (name != "") {
            name += ' ';
          }
          name += difflk.Nachname;
        }

        if (difflk.Ort != "") {
          if (name != "") {
            name += ', ';
          }
          name += difflk.Ort;
        }
      }

      if (this.name.value != name) {
        this.name.next(name);
      }
    });
  }



  reset() {
    this.dsvc.confirm('frage', '"Lieferung für" verlassen?', 'Wollen Sie den Modus "Lieferung für" verlassen?', () => {
      this.lksvc.resetDifferentialLieferkunde().subscribe(() => {
        this.router.navigateByUrl('/shop');
      })
    });
  }
}
