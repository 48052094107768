import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {Verfuegbarkeitsanfrage} from '../../models/verfuegbarkeitsanfrage';
import {ShopArtikelVerfuegbarkeitInfoComponent} from '../artikel-verfuegbarkeit-info/shop.artikel-verfuegbarkeit-info.component';
import {View} from '../../../../models/view';
import {Artikel} from '../../../../models/artikel';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AppViewService} from '../../../../services/app.view.service';
import {AppEventService} from '../../../../services/app.event.service';
import {AppMessageService} from '../../../../services/app.message.service';
import {map} from 'rxjs/operators';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {AppSnackbarService} from "../../../../services/app.snackbar.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'verfuegbarkeits-info-button',
  templateUrl: 'shop.verfuegbarkeits-info-button.component.html',
  styleUrls: ['shop.verfuegbarkeits-info-button.component.scss']
})
export class ShopVerfuegbarkeitsInfoButtonComponent implements OnInit, OnDestroy {
  @Input() view: View;
  @Input() view_slug: string;
  @Input() artikel: Artikel;
  @Input() disabled = false;
  @Input() entry: WarenkorbEntry;

  not_available_de_no_pzn = false;

  esub: Subscription;

  vfcheckloading = new BehaviorSubject<boolean>(false);
  vfcheckloading$ = this.vfcheckloading.asObservable();
  vfchecknotloading$ = this.vfcheckloading.asObservable().pipe(map(s => {
    return !s;
  }));

  constructor(private svc: AppViewService, private snackBarSvc: AppSnackbarService, private esvc: AppEventService,
              private msg: AppMessageService, private cd: ChangeDetectorRef) {
  }

  private detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  getViewSlug() {
    if (this.view) {
      return this.view.slug;
    } else if (this.view_slug) {
      return this.view_slug;
    } else if (this.entry) {
      return this.entry.view_slug;
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.not_available_de_no_pzn = !this.artikel.isVFStatusPruefbar();

    this.esub = this.esvc.getQueue().subscribe(e => {
     if (e.name == 'gui.view_article_changed' && e.data == this.artikel.ArtikelNummer) {
        this.detectChanges();
      }
    });
    this.detectChanges();
  }

  checkVerfuerbarkeit() {
    this.vfcheckloading.next(true);
    this.detectChanges();
    this.svc.verfuegbarkeitBySlug(this.getViewSlug(), this.artikel, this.entry.menge).subscribe(s => {
      if (s) {
        const d = new Verfuegbarkeitsanfrage();
        d.artikel = this.artikel;
        d.status = s;
        this.vfcheckloading.next(false);
        this.detectChanges();
        this.snackBarSvc.open(ShopArtikelVerfuegbarkeitInfoComponent, d, 20000);

      } else {
        this.msg.error('Leider ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut!');
        this.vfcheckloading.next(false);
      }
    });
  }
}
