import {DomSanitizer} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';
import {AppConfigService} from '../../../services/app.config.service';

@Pipe({name: 'raw'})
export class SharedPipeRawhtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private cfg: AppConfigService) {
  }

  transform(value: string, args: string[] = []): any {
    if (value) {
      value = this.fixUrls(value);
      return this.sanitizer.bypassSecurityTrustHtml(value);
    } else {
      return '';
    }
    // return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }

  fixUrls(value: string) {
    value = this.replaceAll(value, 'src="/', 'src="' + this.cfg.buildUrl('/'));
    value = this.replaceAll(value, 'src=/', 'src=' + this.cfg.buildUrl('/'));

    value = this.replaceAll(value, 'alt=/', 'alt=' + this.cfg.buildUrl('/'));
    value = this.replaceAll(value, 'alt="/', 'alt="' + this.cfg.buildUrl('/'));

    return value;
  }

  private replaceAll(target, search, replacement) {
    if (target !== null && target !== undefined && target.replace) {
      return target.replace(new RegExp(search, 'g'), replacement);
    } else {
      return target;
    }
  }
}
