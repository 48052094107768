<ng-container  *ngIf="(entries|async) && (entries|async)?.length > 0">
  <div class="infoline">
    <div class="infobox" *ngIf="infoText">
      {{infoText}}
    </div>
    <div class="infobox warning" *ngIf="(viewcomp && viewcomp.show_warning|async)">
      <svg-icon>warning</svg-icon>
      <div class="text">{{view.settings.warning_above_amount_text}}</div>
    </div>

    <div class="addfieldbox">
      <view-field-selector label="Anzuzeigende Felder" class="tut_shopview_fields" [(fields)]="active_fields" [hidden_fields]="view.settings.hidden_fields" (change)="updateView()"></view-field-selector>
    </div>
    <div style="clear: both"></div>
  </div>
  <div style="clear: both"></div>
  <div class="tableholder" [class.mobile]="msvc.isMobile()|async">
    <table class="table tablebody-striped" style="width: auto" [class.autofit]="has_bez_field|async">
      <thead>
      <tr>
        <th *ngFor="let fieldname of view_fields$|async; trackBy: trackByValue" class="header header_{{fieldname}}">
          <view-field-label-render [fieldname]="fieldname" mode="table"></view-field-label-render>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody *ngFor="let artikel of entries|async; trackBy: trackByArtNr" data-track-content
             [attr.data-content-name]="'Artikel '+(artikel.ArtikelName ? artikel.ArtikelName : artikel.ArtikelBezeichnung)+' ('+artikel.ArtikelNummer+')'">
      <tr>
        <td *ngFor="let fieldname of view_fields$|async; trackBy: trackByValue" class="field field_{{fieldname}}" style="white-space: nowrap" [attr.data-content-piece]="fieldname">
          <a
            *ngIf="fieldname == 'bezeichnung'" routerLink="/shop/{{view.slug}}/{{artikel.ArtikelNummer}}">
            <view-field-value-render
              [artikel]="artikel"
              [fieldname]="fieldname"
              mode="table"
            ></view-field-value-render>
          </a>

          <view-field-value-render
            *ngIf="fieldname == 'bezeichnung'"
            [artikel]="artikel"
            [fieldname]="'note'"
            [view]="view"
            [viewcomp]="viewcomp"
            mode="table"
          >
          </view-field-value-render>

          <view-field-value-render
            *ngIf="fieldname != 'bezeichnung'"
            [artikel]="artikel"
            [fieldname]="fieldname"
            [view]="view"
            [viewcomp]="viewcomp"
            mode="table"
          ></view-field-value-render>
        </td>
        <td style="width: 1%; white-space: nowrap">
          <shop-view-action
            [viewcomp]="viewcomp"
            [artikel]="artikel"
            [view]="view"
          ></shop-view-action>
        </td>
      </tr>
      <tr
        *ngIf="(visible_artikel_aktionen|async) && (visible_artikel_aktionen|async)?.artikel.ArtikelNummer == artikel.ArtikelNummer"
        style="background-color: white">
        <td></td>
        <td [attr.colspan]="(view_fields$|async)?.length - 1" style="padding: 5px">
          <artikel-aktionen-details [aktionen]="visible_artikel_aktionen"></artikel-aktionen-details>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>
