import {Formchoice} from './formchoice';

export class Formfield {
  id: string;
  label: string;
  is_textarea = false;
  choices: Formchoice[];
  placeholder = '';
  value = '';
  keydown_enter_button_press_id: string;
}
