import {DomSanitizer} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';
import {AppConfigService} from '../../../services/app.config.service';

@Pipe({name: 'NiceAuftragsstatus', pure: false})
export class SharedPipeNiceAuftragsstatus implements PipeTransform {
  map = {
    Rueckgestellt: 'Wartet auf Nachbearbeitung',
    ImZeitabschluss: 'In Vorbereitung',
    InKommissionierung: 'In Kommissionierung',
    InZustellung: 'In Zustellung',
    Zugestellt: 'Zugestellt',
    Storniert: 'Storniert',
  };
  constructor(private sanitizer: DomSanitizer, private cfg: AppConfigService) {
  }

  transform(value: any, no_comma_null: boolean = false, commaMax = 2): any {
    if (typeof value === 'string') {
       value = value.trim();

       if (this.map[value]) {
         value = this.map[value];
       }
    }

    return value;

  }
}
