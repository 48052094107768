import {WarenkorbEntry} from './warenkorb-entry';
import {User} from './user';
import {Lieferkunde} from "./lieferkunde";

export class Warenkorb {
  id: number;
  label: string;

  addNoteToOrderConfirmation: boolean;
  note: string;
  entries: WarenkorbEntry[];
  shared: boolean;
  locked: boolean;
  agbaccepted: boolean;
  artikel_count: number;
  wert_summe: number;
  owner: boolean;
  owner_user: User;
  autosend_imp: boolean;
  lieferkunde_nr: string;
  lieferkunde: Lieferkunde;
  defect: boolean;
  reTage: number;
  valutaDate: string;
  liefernAb: string;
}
