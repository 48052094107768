import {Injectable} from '@angular/core';
import {AppViewService} from './app.view.service';
import {BehaviorSubject} from 'rxjs';
import {MainMenuButtonConfig} from '../models/main-menu-button-config';
import {AppConfigService} from './app.config.service';
import {AppUserService} from './app.user.service';
import {AppLieferkundeService} from "./app.lieferkunde.service";
import {MainMenuConfig} from "../modules/main-menu/menu-configs/main.menu.config";
import {User} from "../models/user";

@Injectable({providedIn: 'root'})
export class AppMenuButtonService {
  shop_menu_view_buttons = [];

  mainMenuConfig = new BehaviorSubject<MainMenuButtonConfig[] | any[]>(null);

  renderTimeout: any;

  constructor(private vsvc: AppViewService, private cfg: AppConfigService, private usvc: AppUserService,
              private lksvc: AppLieferkundeService) {
    usvc.user$.subscribe(u => {
      this.renderShopViewButtons();
    })
    vsvc.views$.subscribe(vs => {
      this.renderShopViewButtons();
    });
    vsvc.menu_views$.subscribe(vs => {
      this.renderShopViewButtons();
    });
    lksvc.current_differential_lieferkunde$.subscribe(lk => {
      this.renderShopViewButtons();
    })
  }

  private searchMainMenuEntriesSub(search, menuEntries: MainMenuButtonConfig[]) {
    let matches = [];
    if (menuEntries) {
      menuEntries.forEach(e => {
        if (e) {
          if (e.label && e.label.match(new RegExp(search, "i"))) {
            matches.push(e);
          }

          if (e.children && e.children.length > 0) {
            let subMatches = this.searchMainMenuEntriesSub(search, e.children);
            subMatches.forEach(sm => {
              matches.push(sm);
            });
          }
        }
      });
    }
    return matches;
  }

  private escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  searchMainMenuEntries(search): MainMenuButtonConfig[] {
    search = this.escapeRegExp(search);
    search = search.replaceAll(' ', '.*');
    search = search.replaceAll('\*', '.*');
    return this.searchMainMenuEntriesSub(search, this.mainMenuConfig.value);
  }

  renderButton(cfg: MainMenuButtonConfig | any): any[] | any {
    if (typeof cfg === 'object') {
      cfg = Object.assign({}, cfg);
    }
    let allowed = true;
    if (cfg.features && cfg.features.length > 0 && !this.cfg.featuresEnabled(cfg.features)) {
      allowed = false;
    }
    if (allowed) {
      if (cfg.roles && cfg.roles.length > 0 && (!(this.usvc.user.value instanceof User)|| !this.usvc.user.value.hasRoles(cfg.roles))) {
        allowed = false;
      }
    }

    if (allowed) {
      if (cfg.children) {
        let children = [];
        cfg.children.forEach(c => {
          let rendered = this.renderButton(c);
          // @ts-ignore
          if (rendered && Array.isArray(rendered)) {
            rendered.forEach(ss => {
              children.push(ss);
            });
          } else if (rendered) {
            children.push(rendered);
          }
        })
        cfg.children = children;
      }
      if (typeof cfg === 'function') {
        return cfg(this);
      }

      return cfg;

    } else {
      return null;
    }
  }

  renderMenuSub(sub: MainMenuButtonConfig[] | any[]) {
    let es = [];
    sub.forEach(s => {
      let rendered = this.renderButton(s);
      // @ts-ignore
      if (rendered && Array.isArray(rendered)) {
        rendered.forEach(ss => {
          es.push(ss);
        });
      } else if (rendered) {
        es.push(rendered);
      }
    });
    return es;
  }

  renderMenu() {
    let cfg = Object.assign([], MainMenuConfig);
    this.mainMenuConfig.next(this.renderMenuSub(cfg));
  }

  renderShopViewButtons() {
    if (this.renderTimeout) {
      clearTimeout(this.renderTimeout);
    }

    this.renderTimeout = setTimeout(() => {
      if (this.vsvc.menu_views.value) {
        let is_difflk = this.lksvc.current_differential_lieferkunde.value;

        let buttons: MainMenuButtonConfig[] = [];
        this.vsvc.menu_views.value.forEach(v => {
          let cssclass = undefined;

          if (is_difflk && !v.settings.different_lieferkunde_mode) {
            cssclass = 'disabled';
          }

          let d = {
            id: 'view_' + v.slug,
            label: v.label,
            class: cssclass,
            class_fkt: undefined,
            roles: ['Auftrag'],
            features: [],
            icon: undefined,
            icon_color: undefined,
            route: undefined,
            href: undefined,
            description: undefined,
            target: undefined,
            menu_name: 'Shop',
            view: v,
            children: undefined,
            active: false,
          };
          if (v.settings.label) {
            d.label = v.settings.label;
          }
          buttons.push(
            d
          );
        });

        if (buttons.length == 0) {
          buttons.push({
            id: 'view_none_found',
            label: undefined,
            class: undefined,
            class_fkt: undefined,
            roles: ['Auftrag'],
            features: [],
            icon: undefined,
            icon_color: undefined,
            route: undefined,
            href: undefined,
            description: undefined,
            target: undefined,
            menu_name: 'Shop',
            view: undefined,
            children: undefined,
            active: false,
          });
        }

        this.shop_menu_view_buttons = buttons;
        this.renderMenu();
      }
    }, 50);
  }

  all(): MainMenuButtonConfig[] {

    let elements: MainMenuButtonConfig[] = [];

    if (this.mainMenuConfig.value) {
      this.mainMenuConfig.value.forEach(e => {
        if (e.children) {
          e.children.forEach(c => {
            elements.push(c);
          });
        } else {
          elements.push(e);
        }
      });
    }

    return elements;
  }
}
