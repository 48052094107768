import {Bonuspunktprogramm} from './bonuspunktprogramm';

export class KundeBonuspunkt {
  Kundennummer: number;
  Kundenart: string;
  Bonuspunkteprogramm: Bonuspunktprogramm;
  BonuspunkteprogrammAktiv: boolean;
  Bonuspunkte: number;

  // GUI
  prozent: string;
  level: number;
  ptonextlevel: number;
}
