export class DownloadedFile {
  contentType: string;
  filename: string;
  data: any;

  constructor(filename: string, contentType: string, data: any) {
    this.filename = filename;
    this.contentType = contentType;
    this.data = data;
  }
}
