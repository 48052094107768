import {Injectable} from '@angular/core';
import {AppUserService} from './app.user.service';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";


@Injectable({providedIn: 'root'})
export class AppKeyValueStorageService {

  private SINGLE_USE_PREFIX = 'smsu_';
  private PREFIX = 'AppStorageService_';

  constructor(private usvc: AppUserService, private http: HttpClient) {
  }

  /*
  save(key: string, value: any) {
    localStorage.setItem(this.PREFIX + key, JSON.stringify(value));
  }

  load(key: string): any {
    const val = localStorage.getItem(this.PREFIX + key);
    if (val) {
      return JSON.parse(val);
    } else {
      return false;
    }
  }*/

  save4User(key: string, value: any): Observable<boolean> {
    return this.http.post<boolean>(
      '/user-key-value-storage/' + key,
      {
        value: JSON.stringify(value)
      }
    );
  }

  load4User(key: string): Observable<any> {
    let val = localStorage.getItem(this.PREFIX + this.usvc.user.value.id + '_' + key);
    if (val) {
      val = JSON.parse(val);
    } else {
      val = null;
    }
    if (val) {
      return this.save4User(key, val).pipe(map(v => {
        localStorage.removeItem(this.PREFIX + this.usvc.user.value.id + '_' + key);
        return val;
      }));
    } else {
      return this.http.get<any>(
        '/user-key-value-storage/' + key,
      ).pipe(map(val => {
        if (val) {
          return JSON.parse(val);
        } else {
          return false;
        }
      }));
    }
  }

  hasSingleUse(id: string) {
    const key = this.getSingleUseKey(id);
    if (localStorage.getItem(key)) {
      return true;
    } else {
      return false;
    }
  }

  saveSingleUse(id: string, data: any): void {
    const key = this.getSingleUseKey(id);
    localStorage.setItem(key, JSON.stringify(data));
  }

  loadSingleUse(id: string): any {
    const key = this.getSingleUseKey(id);
    const data = localStorage.getItem(key);
    localStorage.removeItem(key);
    return JSON.parse(data);
  }

  private getSingleUseKey(id: string) {
    return this.SINGLE_USE_PREFIX + id;
  }


}
