import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';
import {AppLieferkundeService} from '../../../services/app.lieferkunde.service';
import {Subscription, Observable} from "rxjs";
import {AppAsyncTimeout} from "../../../helpers/app.asyncTimeout";

@Pipe({
  name: 'LKndNrToLkLabel',
  pure: true
})
export class SharedLkndnrToLieferkundeLabelPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private svc: AppLieferkundeService) {
    this.svc.initMap();
  }

  transform(value: any, inline: boolean = false): Observable<string> {
    return new Observable((obs) => {
      let msub = this.svc.lieferkundenMap.subscribe(map => {
       if (map) {
         AppAsyncTimeout.setTimeout(() => {
           if (msub instanceof Subscription) {
             msub.unsubscribe();
           }
         });

         if (map[value]) {
           if (inline) {
             let val = value +' - '+map[value];
             val = val.replace(new RegExp('<br>', 'g'), ', ');

             obs.next(val);
           } else {
             obs.next(value + ' - ' + map[value]);
           }
         } else {
           obs.next('Unkown ' + value);
         }
         obs.complete();
       }
      });
    });
  }
}
